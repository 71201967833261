import {getValue} from '@components/Form/Fields/ReactSelect';
import {makeRequest} from '@utils/api';
import {getBase64,apiDateFormat} from '@utils';

const makeAddress = ({state_id,...address}) => ({
  state_id: Number(state_id),
  ...address
});
const getValueStr = (val) => typeof val === "object" ? getValue(val) : val
const getSelectValue = (val) => Array.isArray(val) ? val.map(getValueStr) : [getValueStr(val)]

const makeProject = async ({addresses,value,company_id,project_type_id,logo,project_stage_id,sqft,company_attributes,...data}) => {
  return new Promise(async (resolve, reject) => {
    const obj = {};
    if(!logo){
      obj.logo = null;
    } else if(typeof logo !== "string"){
      obj.logo = await getBase64(logo[0])
    }
    if(company_id === "new"){
      obj.company_attributes = company_attributes;
    } else {
      obj.company_id = Number(company_id)
    }
    resolve({
      ...data,
      project_type_id: project_type_id ? Number(project_type_id) : undefined,
      project_stage_id: project_stage_id ? Number(project_stage_id) : undefined,
      expected_start_date: apiDateFormat(data.expected_start_date),
      expected_end_date: apiDateFormat(data.expected_end_date),
      sqft: sqft ? Number(sqft) : undefined,
      value: value ? parseFloat(Number(value.replace(/[^0-9.-]+/g,""))) : undefined,
      addresses_attributes: addresses.map(makeAddress),
      ...obj
    })
  });
};

export const createProject = async (data, url) => makeRequest("POST",url,{
  project: await makeProject(data)
})

export const updateProject = async (data, url) => makeRequest("PATCH",url,{
  project: await makeProject(data)
})
