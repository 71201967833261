import ProjectFormPage from '@components/Project/Form';
import {withRequestState} from '@containers/RequestContext';
import DeleteButton from '@containers/DeleteButton';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {updateProject} from '@utils/api/project';
import {makeBirthdate} from '@utils';

const makeDefaultValues = ({logo_url,expected_start_date,expected_end_date,value,...response}) => ({
  ...response,
  logo: logo_url,
  expected_start_date: makeBirthdate(expected_start_date),
  expected_end_date: makeBirthdate(expected_end_date),
  value: Number(value).toFixed(2)
})

const ProjectUpdatePage = withParams(withRequestState(({params:{projectId},request:{state:{data}}}) => {
  const url = `/projects/${projectId}.json`;
  return(
    <ProjectFormPage
      form={{
        url,
        defaultValues: makeDefaultValues(data),
        onSubmit: updateProject,
        toast: "Project updated"
      }}
      page={{
        title: "Update Project",
        back: "Back To Project",
        submit: "Update Project",
        right: <DeleteButton url={url} />
      }}
      hasCompanyId={data.company_id}
      isUpdateForm
    />
  )
}))

export default withPageTitle(ProjectUpdatePage, "Update Project");
