import FormPage from '@components/Form/Page';
import Input from '@components/Form/Fields/Input';
import PhoneNumberInput from '@components/Form/Fields/PhoneNumberInput';
import AutocapitalizeInput from '@components/Form/Fields/AutocapitalizeInput';
import RequestSelect from '@containers/RequestSelect';
import AppInfoSelect from '@containers/AppInfoSelect';
import {contactSchema} from '@utils/validation/contact';
import {sortTitles} from '@utils';

const ContactFormPage = ({form,hasCompanyId,...props}) => (
  <FormPage
    {...props}
    form={{validationSchema: contactSchema, ...form}}
    title="Contact Information"
    left={<>
      {hasCompanyId ? (
        <Input name="company_id" type="hidden" className="hidden" />
      ) : (
        <RequestSelect name="company_id" label="Company" url="/companies.json" resource="companies" placeholder="Select Company..." />
      )}
      <AppInfoSelect name="title_id" resource={(response) => response?.titles ? response.titles.sort(sortTitles) : []} labelAccessor="title" label="Title" placeholder="Select Title..." />
      <AutocapitalizeInput name="first_name" label="First Name" placeholder="Enter First Name" />
      <AutocapitalizeInput name="last_name" label="Last Name" placeholder="Enter Last Name" />
    </>}
    right={<>
      <Input type="email" name="email" label="Email" placeholder="Enter Email" />
      <PhoneNumberInput name="mobile_phone" label="Phone" placeholder="Enter Phone" />
      <PhoneNumberInput name="office_phone" label="Office Phone" placeholder="Enter Office Phone" />
    </>}
  />
)

export default ContactFormPage;
