import {RequestSection} from '@components/Section';
import ProjectAssetSectionComponent from '@components/ProjectAsset/Slider';
import ProjectAssetList from '@components/ProjectAsset/List';
import ViewMoreLink from '@components/ViewMoreLink';
import EmptyResourcePlaceholder from '@components/EmptyResourcePlaceholder';
import styles from './index.module.css';

const makeToRoot = (to,tagIds) => tagIds ? `${to}/${tagIds}` : to
export const ProjectAssetListSection = ({
  projectId,
  hideButton,
  tagIds,
  title = "Files",
  to = "directories",
  onDelete,
  backState = "Back To Project",
  placeholder = "This project has no files",
  collapsible = true,
  pageSize = 10,
  isDefault,
  ...props
}) => {
  const toRoot = makeToRoot(to,tagIds)
  return (
    <RequestSection title={title} url={`/projects/${projectId}/project_assets.json`} pageSize={pageSize} initialQuery={{tag_ids: tagIds}} collapsible={collapsible} {...props}>
      {({project_assets},makeRequest,updateData) => (
        project_assets?.length > 0 ? (
          <div className={styles.container}>
            <ProjectAssetList isDefault={isDefault} projectAssets={project_assets} updateData={updateData} makeRequest={onDelete ? onDelete : makeRequest} projectId={projectId} />
            <ViewMoreLink to={toRoot} hideButton={hideButton} back={backState} />
          </div>
        ) : (
          <EmptyResourcePlaceholder to={`${toRoot}/new`} text={placeholder} back={backState} hideButton={hideButton} />
        )
      )}
    </RequestSection>
  )
}

const ProjectAssetSection = ({
  title = "Files",
  collapsible = true,
  to = "directories",
  ...props
}) => (
  <ProjectAssetSectionComponent title={title} collapsible={collapsible} to={makeToRoot(to,props.tagIds)} {...props} />
)

export default ProjectAssetSection;
