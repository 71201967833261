import {makeRequest} from '@utils/api';

export const createPermissionSet = ({resource_type,...permission_set}, url) => makeRequest("POST",url,{
  permission_set
})

export const updatePermissionSet = ({resource_type,permissions_attributes,...formValues}, url,defaultValues) => new Promise(async (resolve, reject) => {
  const permission_set = {...formValues},
        updatedPermissions = permissions_attributes.filter(permission => {
          const idx = defaultValues.permissions_attributes.findIndex(p => p.resource === permission.resource);
          if(idx !== -1){
            const isEqual = permission.access === defaultValues.permissions_attributes[idx].access && permission.scopes === defaultValues.permissions_attributes[idx].scopes
            return !isEqual
          }
          return true
        })
  if(updatedPermissions.length > 0){
    permission_set.permissions_attributes = updatedPermissions
  }
  await makeRequest("PATCH",url,{
    permission_set
  })
  resolve()
})
