import useAccordion from '@containers/useAccordion';
import useHandleClickOutside from '@containers/useHandleClickOutside';
import styles from './index.module.css';
import {classNames} from '@utils';

const Dropdown = ({children, button, className,headerLink,wrapperClassName,buttonClassName,contentClassName,title}) => {
  const dropdown = useAccordion(),
        wrapperRef = useHandleClickOutside(dropdown.close);
  return(
    <div ref={wrapperRef} className={classNames(styles.container, wrapperClassName)}>
      <div onClick={dropdown.toggle} className={classNames("selectable", buttonClassName)} title={title}>
        {button}
      </div>
      <div className={classNames(styles.dropdown, headerLink && styles.headerLink, className)} style={{maxHeight:dropdown.state.maxHeight}} ref={dropdown.ref}>
        <div className={contentClassName}>
          {typeof children === "function" ? children(dropdown) : children}
        </div>
      </div>
    </div>
  )
}

export default Dropdown;
