import {string, object, array,mixed} from 'yup';
import {addressSchema} from '@utils/validation/address';
import {dateOnly} from '@utils';

export const projectSchema = object().shape({
  company_id: mixed().required('Customer is required'),
  "company_attributes[name]": string().test('has company_id',"Customer name is required",function(){
    return this.parent.company_id === "new" ? this.parent.company_attributes?.name : true;
  }),
  name: string().required('Name is required'),
  expected_start_date: string().required('Start date is required'),
  expected_end_date: string().test('is not before start_date',"Must be after start date",function(end_date){
    const s = dateOnly(this.parent.expected_start_date),
          e = dateOnly(this.parent.expected_end_date);
    return (s || e) ? e >= s : true;
  }).required('End date is required'),
  project_stage_id: mixed().required('Project status is required'),
  project_type_id: mixed().required('Project type is required'),
  addresses: array().of(addressSchema).required('Requires at least address')
});
