import Form from '@components/Form';
import {Table,Header,Row} from '@components/Table';
import Page from '@components/Layouts/Page';
import Switch from '@components/Form/Fields/Switch';
import SplitRow from '@components/SplitRow';
import LabelledInput,{ControlledInput,Input} from '@components/Form/Fields/Input';
import {StatefulSelectComponent as Select} from '@components/Form/Fields/Select';
import Content from '@components/Layouts/Content';
import {permissionSetSchema} from '@utils/validation/permission_set';
import styles from './index.module.css';

const NAME = "permissions_attributes";
const permissionsArray = ["Companies","Projects","Contacts","Invite","Tools","Project Assets","Project Events","Project Forms"]
const accessOptions = [{value: "none", label: "None"}, {value: "read", label: "Read"}, {value: "write", label: "Write"}]
const scopeOptions = [{value: "all", label: "All"}, {value: "associated", label: "Associated"}]
const PermissionSetFields = () => (
  <Content
    title="Permission Set Information"
  >
    <SplitRow>
      <LabelledInput name="name" label="Name" placeholder="Enter Name" />
      <Switch name="is_default" label="Default" />
    </SplitRow>
    <Table>
      <Header>
        <div>Resource</div>
        <div>Access</div>
        <div>Scope</div>
      </Header>
      {permissionsArray.map((permission,i) => {
        const name = `${NAME}[${i}].`
        return(
          <Row key={permission}>
            <div>
              {permission}
              <ControlledInput name={`${name}resource`} defaultValue={permission} className="hidden" />
            </div>
            <div>
              <Select name={`${name}access`} defaultValue="none" options={accessOptions} />
            </div>
            <div>
              <Select name={`${name}scopes`} defaultValue="all" options={scopeOptions} />
            </div>
          </Row>
        )
      })}
    </Table>
  </Content>
)

const PermissionSetFormPage = ({form,page}) => (
  <Form
    {...form}
    validationSchema={permissionSetSchema}
  >
    <Page {...page}>
      <PermissionSetFields />
    </Page>
  </Form>
);

export default PermissionSetFormPage;
