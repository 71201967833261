import {PublicForm} from '@components/Layouts/Public';
import Input from '@components/Form/Fields/Input';
import {useAuthDispatch,loginAction} from '@containers/Auth';
import {loginSchema} from '@utils/validation/auth';
import {login} from '@utils/api/auth';
import {TokenError} from '@utils/api/error';

const LoginPage = () => {
  const dispatch = useAuthDispatch(),
        handleSuccess = ({response}) => dispatch(loginAction(response)),
        handleError = (e,errorModalDispatch) => {
          if(e instanceof TokenError){
            errorModalDispatch("Invalid email or password")
          } else {
            errorModalDispatch("An unexpected error has occurred")
          }
        }
  return(
    <PublicForm buttonText="Login" onError={handleError} link={{to: "/password/forgot", text: "Forgot Password?"}} onSubmit={login} onSuccess={handleSuccess} to={false} validationSchema={loginSchema}>
      <Input type="email" name="email" label="Email" secondary />
      <Input type="password" name="password" label="Password" secondary />
    </PublicForm>
  );
}

export default LoginPage;
