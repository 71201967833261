import {makeRequest} from '@utils/api';
import {apiDateFormat} from '@utils';

const makeChangeOrder = (order) => {
  const obj = {...order};
  if(obj.due_date) obj.due_date = apiDateFormat(obj.due_date)
  if(obj.signed_date) obj.signed_date = apiDateFormat(obj.signed_date)
  return obj
}

export const createProjectChangeOrder = (project_change_order, url) => makeRequest("POST",url,{
  project_change_order: makeChangeOrder(project_change_order)
})

export const updateProjectChangeOrder = (project_change_order, url) => makeRequest("PATCH",url,{
  project_change_order: makeChangeOrder(project_change_order)
})
