import ProjectPhotoItem from '@components/ProjectAsset/Item';
import {getIconFile} from '@components/ProjectAsset/Card';
import FileMenu from '@components/ProjectAsset/Directory/File/Menu';
import ImageComponent from '@components/Image';
import {getFileNameFromUrl} from '@utils';
import styles from './index.module.css';
import downloadIcon from '@assets/images/file_download-24px.svg';

const File = ({file = {},projectId,companyId,tagId,onDelete,onClick,isDefault}) => (
  <ProjectPhotoItem className={styles.file}>
    <div className={styles.left} onClick={onClick}>
      <img src={getIconFile(file.asset_url,true)} alt="Project Asset" className={styles.image} />
      <span className={styles.fileName}>{getFileNameFromUrl(file.asset_url)}</span>
    </div>
    <div className={styles.right}>
      <a href={file.asset_url} download><img src={downloadIcon} /></a>
      <FileMenu file={file} tagId={tagId} companyId={companyId} onDelete={onDelete} isDefault={isDefault} />
    </div>
  </ProjectPhotoItem>
)

export default File;
