import { Link } from 'react-router-dom';
import ProjectAssetItem from '@components/ProjectAsset/Item';
import folderIcon from '@assets/images/folder_open-24px.svg';
import styles from './index.module.css';

const FolderItem = ({folder, as = Link, ...props}) => (
  <ProjectAssetItem className="highlight" as={as} to={`${folder.id}`} {...props}>
    <img src={folderIcon} className={styles.folderIcon} />
    {folder.name}
  </ProjectAssetItem>
)

export default FolderItem;
