import {Link} from 'react-router-dom';
import Page from '@components/Layouts/Page';
import Button from '@components/Button';
import TagList from '@components/Tool/Tag/List';
import {useAppInfoState} from '@containers/AppInfo';
import withPageTitle from '@containers/withPageTitle';
import {sortTags} from '@utils';

export const TagListPageComponent = ({url,...props}) => {
  const info = useAppInfoState();
  return(
    <Page
      {...props}
      right={<Button as={Link} to="new">Add Directory</Button>}
    >
      <TagList tags={info?.tags?.sort(sortTags)} />
    </Page>
  )
}

const TagListPage = () => (
  <TagListPageComponent
    url="/tags.json"
    title="Directories"
  />
)

export default withPageTitle(TagListPage, "Directories");
