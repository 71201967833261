import {getValue} from '@components/Form/Fields/ReactSelect';
import {makeRequest} from '@utils/api';
import {getBase64} from '@utils';

const getValueStr = (val) => typeof val === "object" ? getValue(val) : val
const getSelectValue = (val) => Array.isArray(val) ? val.map(getValueStr) : [getValueStr(val)]
const makeProjectAsset = async ({asset,album_name,sub_tag_ids,tag_ids,...data}) => {
  return new Promise(async (resolve, reject) => {
    const obj = {};
    if(sub_tag_ids){
      const sub_tag_ids_value = getSelectValue(sub_tag_ids)
      if(sub_tag_ids_value.find((val) => val === "new") && album_name){
        obj.asset_sub_tags_attributes = [{sub_tag_attributes: {name: album_name}}];
      } else {
        obj.sub_tag_ids = sub_tag_ids_value
      }
    }
    if(tag_ids){
      obj.tag_ids = getSelectValue(tag_ids)
    }
    if(!asset){
      obj.asset = null;
    } else if(typeof asset !== "string"){
      obj.asset = await getBase64(asset)
      obj.filename = asset.name
    }
    resolve({
      ...data,
      ...obj
    })
  });
};
export const createProjectAsset = async ({asset,...project_asset},url) => new Promise(async (resolve,reject) => {
  try {
    const response = await makeRequest("POST",url,{
      project_asset: await makeProjectAsset({
        ...project_asset,
        asset: asset[0]
      })
    })
    if(asset.length > 0){
      for (var i = 1; i < asset.length; i++) {
        const file = {
          ...project_asset,
          asset: asset[i]
        }
        if(response.sub_tags?.length > 0){
          file.sub_tag_ids = response.sub_tags.map(({id}) => id)
        }
        await makeRequest("POST",url,{
          project_asset: await makeProjectAsset(file)
        })
      }
    }
    resolve(response)
  } catch (e) {
    reject(e)
  }
})

export const updateProjectAsset = async (project_asset,url) => makeRequest("PATCH",url,{
  project_asset: await makeProjectAsset(project_asset)
})



export const updateProjectPhoto = async (project_asset,url) => makeRequest("PATCH",url,{
  project_asset: await makeProjectAsset(project_asset)
})

const rotatePhotoURL = async (asset_url) => new Promise(async (resolve,reject) => {
  try {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext("2d");
        var image = new Image();
        image.src = reader.result;
        image.onload = function() {
          var w = image.width;
          var h = image.height;
          var rads = 90 * Math.PI/180;
          var c = Math.cos(rads);
          var s = Math.sin(rads);
          if (s < 0) { s = -s; }
          if (c < 0) { c = -c; }
          canvas.width = h * s + w * c;
          canvas.height = h * c + w * s;
          ctx.translate(canvas.width/2, canvas.height/2);
          ctx.rotate(90 * Math.PI / 180);
          ctx.drawImage(image, -image.width/2, -image.height/2);
          resolve(canvas.toDataURL());
        };
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', asset_url);
    xhr.responseType = 'blob';
    xhr.send();
  } catch (e) {
    reject(e)
  }

})

export const rotatePhoto = async (projectId,projectAssetId,asset_url) => makeRequest("PATCH",`/projects/${projectId}/project_assets/${projectAssetId}.json`,{
  project_asset: {
    asset: await rotatePhotoURL(asset_url)
  }
})
