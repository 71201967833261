import {createContext,useContext} from 'react';
import {useRequest} from '@containers/Request';

const AppInfoStateContext = createContext();
const AppInfoRequestContext = createContext();
function AppInfoProvider({children}) {
  const {state,makeRequest} = useRequest("/api_info");
  return (
    <AppInfoRequestContext.Provider value={makeRequest}>
      <AppInfoStateContext.Provider value={state.data}>
        {children}
      </AppInfoStateContext.Provider>
    </AppInfoRequestContext.Provider>
  );
};

function useAppInfoState() {
  const context = useContext(AppInfoStateContext);
  if (context === undefined) {
    throw new Error('useAppInfoState must be used within a AppInfoProvider');
  }
  return context;
}

function useAppInfoRequest() {
  const context = useContext(AppInfoRequestContext);
  if (context === undefined) {
    throw new Error('useAppInfoRequest must be used within a AppInfoProvider');
  }
  return context;
};

export {AppInfoProvider, useAppInfoState,useAppInfoRequest};
