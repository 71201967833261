import ContactFormPage from '@components/ProjectContact/Form';
import Request from '@containers/Request';
import DeleteButton from '@containers/DeleteButton';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {updateProjectContact} from '@utils/api/project_contact';

const makeProjectContactDefaultValues = ({contact,title,...rest},projectId) => title?.id ? ({
  title_id: title.id,
  contact_attributes: contact,
  project_id: projectId,
  ...rest
}) : ({
  contact_attributes: contact,
  project_id: projectId,
  ...rest
})

export const ProjectContactUpdatePage = ({id,projectId,onSuccess = () => {}}) => {
  const url = `/projects/${projectId}/contact_projects/${id}.json`;
  return(
    <Request url={url}>
      {(response) => (
        <ContactFormPage
          form={{
            url,
            defaultValues: makeProjectContactDefaultValues(response,projectId),
            onSubmit: updateProjectContact,
            onSuccess,
            toast: "Contact updated"
          }}
          page={{
            title: "Update Contact",
            back: "Back To Project Contact",
            submit: "Update Contact",
            right: (<DeleteButton url={`/contacts/${id}.json`} />)
          }}
        />
      )}
    </Request>
  );
}

const ProjectContactUpdatePageContainer = withParams(({params:{id,projectId}}) => (
  <ProjectContactUpdatePage projectId={projectId} id={id} />
))

export default withPageTitle(ProjectContactUpdatePageContainer, "Update Project Contact");
