import {forwardRef} from 'react';
import Select from 'react-select';
import {Controller} from 'react-hook-form';
import withField from '@components/Form/Fields/withField';
import './index.css'

export const getValue = ({value}) => value
const makeValue = (value,options,isMulti) => {
  if(!value) return value
  if(isMulti){
    if(value[0] && typeof value[0] === 'object'){
      return value
    }
    return value.map(v => options.find(option => option.value == v))
  } else {
    if(typeof value === 'object'){
      return value
    }
    return options.find(option => option.value == value)
  }
}
export const SelectComponent = forwardRef(({value,...props},ref) => (
  <Select {...props} value={makeValue(value,props.options,props.isMulti)} classNamePrefix="react-select" className="react-select-container" />
))

const SelectContainer = withField(props => (
  <Controller {...props} as={SelectComponent} />
))

export default SelectContainer;
