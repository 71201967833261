import moment from 'moment';

export function classNames(){
  return [...arguments].filter(s => typeof s === 'string').join(' ');
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export const uniq = (a) => a.filter(onlyUnique);

export const makeDateString = (date) => {
  if(!date) return date;
  const [y,m,d] = date.split('-');
  return `${m[0] === '0' ? m[1] : m}/${d[0] === '0' ? d[1] : d}/${y.substring(2)}`;
}
/*
export const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData;
}
*/

function buildFormData(formData, data, parentKey) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;
    formData.append(parentKey, value);
  }
}

export function getFormData(data) {
  const formData = new FormData();
  buildFormData(formData, data);
  return formData;
}

export const capitalize = (s) => s ? s.split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ') : ""


export function clean(obj){
   let stringfiedObj = JSON.stringify(obj, (key, value) => {
     return ['', null].includes(value) || (typeof value === 'object' &&(value.length === 0 || Object.keys(value).length === 0)) ? undefined : value;
   });
   let resObj = JSON.parse(stringfiedObj);
   let isEmptyPropsPresent = ['{}', '[]', '""', 'null'].some((key) => stringfiedObj.includes(key))
   if(isEmptyPropsPresent) {
     return clean(resObj);
   }
   return resObj;
}

export const getFormattedDate = (date) => {
  if(!date) return date;
  var d = new Date(date)
  var year = d.getFullYear();

  var month = (1 + d.getMonth()).toString();

  var day = d.getDate().toString();

  return month + '/' + day + '/' + year;
}

export const phoneFormat = (value) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;
  if (cvLength < 4) return currentValue;
  if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export const makeFileReqObj = async (file,name = "asset") => {
  const obj = {};
  if(!file){
    obj[name] = null;
  } else if(typeof file !== "string"){
    obj[name] = await getBase64(file[0])
  }
  return obj
}

export const sortTags = (a, b) => {
  if(a.tag > b.tag) return 1
  if(a.tag < b.tag) return -1
  return 0
}

export const sortTitles = (a, b) => {
  if(a.title > b.title) return 1
  if(a.title < b.title) return -1
  return 0
}

const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
export const cashFormat = (v) => v && formatter.format(v.toLocaleString('fullwide', {useGrouping:false}))

export const renderFieldType = (type) => {
  switch (type) {
    case "DateField":
      return "Date"
    case "IntegerField":
      return "Integer"
    case "BooleanField":
      return "Yes/No"
    default:
      return "Text"
  }
}

export function dateOnly(dt){
  const d = new Date(dt);
  d.setHours(0,0,0,0);
  return d.getTime();
}

export function getFileNameFromUrl(url){
  if(!url) return
  const splitUrl = url.split("/");
  return decodeURIComponent(splitUrl[splitUrl.length - 1])
}

export function isFileImage(fileName){
  if(!fileName) return
  return fileName.match(/.(jpg|jpeg|png|gif|svg)$/i)
}

export function isURLImage(url){
  if(!url) return
  return isFileImage(getFileNameFromUrl(url))
}
export const getFileType = (url) => {
  const split = url.split(".");
  return split[split.length - 1]
}
export const isPDF = (url) => getFileType(url) === "pdf"
export const convertTime12to24 = (time12h) => {
  const [time, modifier] = time12h.split(' ');

  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
}

export const convertTime24to12 = (time) => {
  let [hours, minutes] = time.split(':'),
      ampm = "AM"

  if (hours > '12') {
    hours = 24 - 12;
    ampm = "PM"
  }
  if(hours[0] === "0"){
    hours = hours[1]
  }
  return `${hours}:${minutes} ${ampm}`;
}

export const apiDateFormat = (date) => moment(date).format('YYYY-MM-DD')

export const statusFormat = (status) => status && status.split("_").map(word => (word.charAt(0).toUpperCase() + word.slice(1))).join(" ")

export const getTime = (date) => {
  if(!date) return date;
  const d = new Date(date),
        minute = d.getMinutes();
  var hour = d.getHours(),
      ampm = "AM";

  if(hour > 12){
    hour -= 12;
    ampm = "PM"
  }
  return `${hour}:${minute < 10 ? `0${minute}` : minute} ${ampm}`
}

export const stringifyQuery = (obj) => {
  if(!obj) return ""
  var qs = ""
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      if(Array.isArray(obj[key])){
        for (var i = 0; i < obj[key].length; i++) {
          qs += `&${key}=${obj[key][i]}`
        }
      } else if(obj[key] === null) {
        qs += `&${key}=`
      } else if(obj[key] !== undefined) {
        qs += `&${key}=${obj[key]}`
      }

    }
  }
  return qs
}

export const makeStatusLabel = (status) => status.split("_").map(word => word[0].toUpperCase() + word.substring(1)).join(" ");

export const makeStatusOptionsArray = (response) => response?.map(status => ({name: makeStatusLabel(status), id: status}))

export const makeStatusOptions = (attr) => (response) => makeStatusOptionsArray(response?.[attr])

export const makeBirthdate = (date) => {
  if(!date) return date;
  const [y,m,d] = date.split("-")
  return new Date(Number(y),Number(m) - 1,Number(d))
}

export const getBirthdate = (date) => {
  if(!date) return date;
  const [y,m,d] = date.split("-")
  return Number(m) + '/' + Number(d) + '/' + Number(y);
}
