import {ProjectContactUpdatePage} from '@components/ProjectContact/Update';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {withRequestState} from '@containers/RequestContext';

const DefaultProjectContactUpdatePage = withParams(withRequestState(({params:{id},request:{state:{data},makeRequest}}) => (
  <ProjectContactUpdatePage id={id} projectId={data.id} onSuccess={() => {makeRequest()}} />
)))

export default withPageTitle(DefaultProjectContactUpdatePage, "Update Project Contact");
