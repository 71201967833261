import ProjectPrimeContractFormPage from '@components/ProjectPrimeContract/Form';
import Request from '@containers/Request';
import DeleteButton from '@containers/DeleteButton';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {updateProjectPrimeContract} from '@utils/api/project_prime_contract';
import {convertTime12to24} from '@utils';

const ProjectPrimeContractUpdatePage = withParams(({params:{projectId,projectPrimeContractId}}) => {
  const url = `/projects/${projectId}/project_prime_contracts/${projectPrimeContractId}.json`;
  return(
    <Request url={url}>
      {(response) => (
        <ProjectPrimeContractFormPage
          form={{
            url,
            defaultValues: {...response,start_date: new Date(response.start_date),estimated_completion_date: new Date(response.estimated_completion_date),actual_completion_date: new Date(response.actual_completion_date),signed_contract_received_date: new Date(response.signed_contract_received_date),contract_terminated_date: new Date(response.contract_terminated_date)},
            onSubmit: updateProjectPrimeContract,
            toast: "Project prime contract updated"
          }}
          page={{
            title: "Update Project Prime Contract",
            back: "Back To Project Prime Contract",
            submit: "Update Project Prime Contract",
            right: <DeleteButton url={url} />
          }}
          projectId={projectId}
        />
      )}
    </Request>
  )
})

export default withPageTitle(ProjectPrimeContractUpdatePage, "Update Project Prime Contract");
