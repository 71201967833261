import {PublicForm} from '@components/Layouts/Public';
import Input from '@components/Form/Fields/Input';
import {forgotPasswordSchema} from '@utils/validation/auth';
import {forgotPassword} from '@utils/api/auth';

const LoginPage = () => (
  <PublicForm link={{to: "/login", text: "Back"}} to="/login" toast="You should receive a password reset email soon" buttonText="Recover Password" onSubmit={forgotPassword} validationSchema={forgotPasswordSchema}>
    <Input type="email" name="email" label="Email" secondary />
  </PublicForm>
)

export default LoginPage;
