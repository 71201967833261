import {Link} from 'react-router-dom';
import Button from '@components/Button';
import AddContactModal from '@components/ProjectContact/AddModal';
import FormPage from '@components/Form/Page';
import Input from '@components/Form/Fields/Input';
import PhoneNumberInput from '@components/Form/Fields/PhoneNumberInput';
import AutocapitalizeInput from '@components/Form/Fields/AutocapitalizeInput';
import ResourceSelect from '@containers/ResourceSelect';
import AppInfoSelect from '@containers/AppInfoSelect';
import {projectContactSchema} from '@utils/validation/project_contact';
import {sortTitles} from '@utils';

const ProjectContactFormPage = ({form,hasCompanyId,create,projectId,page}) => (
  <FormPage
    form={{validationSchema: projectContactSchema, ...form}}
    page={page}
    title={(<>
      <div>Contact Information</div>
      {create && <Button form as={Link} to="existing">Add Existing Contact</Button>}
    </>)}
    left={<>
      <Input name="project_id" type="hidden" className="hidden" />
      {!create && <Input name="contact_attributes[id]" type="hidden" className="hidden" />}
      <AppInfoSelect name="title_id" resource={(response) => response?.titles ? response.titles.sort(sortTitles) : []} labelAccessor="title" label="Role" placeholder="Select Role..." />
      <AutocapitalizeInput name="contact_attributes[first_name]" label="First Name" placeholder="Enter First Name" />
      <AutocapitalizeInput name="contact_attributes[last_name]" label="Last Name" placeholder="Enter Last Name" />
    </>}
    right={<>
      <Input type="email" name="contact_attributes[email]" label="Email" placeholder="Enter Email" />
      <PhoneNumberInput name="contact_attributes[mobile_phone]" label="Phone" placeholder="Enter Phone" />
      <PhoneNumberInput name="contact_attributes[office_phone]" label="Office Phone" placeholder="Enter Office Phone" />
    </>}
  />
)

export default ProjectContactFormPage;
