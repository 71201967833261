import {Link} from 'react-router-dom';
import Page from '@components/Layouts/CommonPage';
import Value from '@components/Value';
import Button from '@components/Button';
import {Switch} from '@components/Form/Fields/Switch';
import SplitRow from '@components/SplitRow';
import Request from '@containers/Request';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';

const CostCodePage = withParams(({params:{id}}) => (
  <Request url={`/cost_codes/${id}.json`}>
    {(response) => (
      <Page
        page={{
          title: "Cost Code",
          back: "Back To Cost Codes",
          right: (<Button as={Link} to="edit">Update</Button>)
        }}
        title="Cost Code Information"
      >
        <Value value={response.cost_type} label="Type" />
        <SplitRow>
          <Value value={response.abbrev} label="Abbreviation" />
          <Value value={<Switch checked={response.active} value={response.active} disabled />} label="Active" />
        </SplitRow>
      </Page>
    )}
  </Request>
))

export default withPageTitle(CostCodePage, "Cost Code Details");
