import {useState} from 'react';
import FileViewer from 'react-file-viewer';
import DeleteButton from '@containers/DeleteButton';
import {useToastAddAction} from '@containers/ToastContext';
import styles from './index.module.css';
import {classNames,getFormattedDate,getFileNameFromUrl,isFileImage,getFileType} from '@utils';
import closeIcon from '@assets/images/close-white-24px.svg';
import downloadIcon,{ReactComponent as DownloadIcon} from '@assets/images/file_download_white-24px.svg';
import deleteIcon,{ReactComponent as DeleteIcon} from '@assets/images/delete_white_24dp.svg';
import zoomInIcon from '@assets/images/zoom_in_white_24dp.svg';
import zoomOutIcon from '@assets/images/zoom_out_white_24dp.svg';
import rotateIcon from '@assets/images/refresh_white_24dp.svg';
import {ReactComponent as ArrowLeft} from '@assets/images/arrow-left.svg';
import {ReactComponent as ArrowRight} from '@assets/images/arrow-right.svg';
import {rotatePhoto} from '@utils/api/project_asset';

const GalleryHeaderButton = ({src,as: WrapperComponent = "button",projectId,...props}) => (
  <WrapperComponent {...props} className={styles.galleryHeaderButtonContainer}>
    <img src={src} className={styles.galleryHeaderButtonIcon} />
  </WrapperComponent>
)

const GalleryInfoButton = ({as: WrapperComponent = "button", icon: IconComponent = DownloadIcon,children,...props}) => (
  <WrapperComponent {...props} className={`${styles.actionButton} selectable`}>
    <IconComponent className={styles.actionButtonIcon} />
    <div  className={styles.actionButtonText}>
      {children}
    </div>
  </WrapperComponent>
)

const GalleryInfo = ({label,value}) => (
  <div className={styles.galleryInfoContainer}>
    <div className={styles.galleryInfoLabel}>{label}</div>
    <div className={styles.galleryInfoValue}>{value}</div>
  </div>
)

export default function ProjectAssetListComponent({projectAssets,updateData,children,...props}){
  const [gallery,setGallery] = useState(),
        toastDispatch = useToastAddAction(),
        openGallery = (idx) => setGallery({idx,zoom:1}),
        closeGallery = (idx) => setGallery(),
        handlePrevClick = () => setGallery(({idx}) => ({idx: idx-1})),
        handleNextClick = () => setGallery(({idx}) => ({idx: idx+1})),
        handleDeleteAsset = () => {
          if(projectAssets.length === 1){
            closeGallery()
          } else if(gallery.idx === projectAssets.length - 1){
            openGallery(gallery.idx - 1)
          }
          updateData({
            project_assets: [...projectAssets.slice(0, gallery.idx), ...projectAssets.slice(gallery.idx + 1)]
          })
        },
        handleAssetRotate = async () => {
          try {
            const photo = {...projectAssets[gallery.idx]};
            photo.rotation = photo.rotation ? photo.rotation + 90 : 90
            updateData({
              project_assets: [...projectAssets.slice(0, gallery.idx), photo, ...projectAssets.slice(gallery.idx + 1)]
            })
            await rotatePhoto(photo.project_id,photo.id,photo.asset_url)
          } catch (e) {
            toastDispatch("Unable to save photo orientation")
          }
        },
        zoomIn = () => setGallery(({idx,zoom}) => ({idx,zoom: zoom < 4 ? zoom + 1 : 4})),
        zoomOut = () => setGallery(({idx,zoom}) => ({idx,zoom: zoom > 1 ? zoom - 1 : 1})),
        leftDisabled = gallery?.idx === 0,
        rightDisabled = gallery?.idx === projectAssets.length - 1,
        file = projectAssets?.[gallery?.idx],
        fileName = getFileNameFromUrl(file?.asset_url),
        isImage = isFileImage(fileName);

  return(<>
    {gallery && (
      <div className={styles.galleryContainer}>
        <div className={styles.galleryHeader}>
          <GalleryHeaderButton src={downloadIcon} as="a" href={file?.asset_url} download />
          <DeleteButton button={GalleryHeaderButton} src={deleteIcon} url={`/projects/${file?.project_id}/project_assets/${file?.id}.json`} toast="Asset has been deleted" to={false} onSuccess={handleDeleteAsset} />

          <GalleryHeaderButton src={closeIcon} onClick={closeGallery} />

        </div>
        <div className={styles.galleryContent}>
          <div className={styles.gallery}>
            <div className={classNames(styles.button, leftDisabled && styles.disabled)} onClick={leftDisabled ? undefined : handlePrevClick}>
              <ArrowLeft className={styles.arrow} />
            </div>
            <div className={styles.imageWrapper}>
              {isImage ? (<>
                <div className={styles.imageTransformWrapper} style={{transform: `scale(${gallery?.zoom}) rotate(${file?.rotation ? file.rotation : 0}deg) translate(0px, 0px)`}}>
                  <div style={{backgroundImage: `url(${file?.asset_url})`}} className={styles.galleryImage}>

                  </div>
                </div>
                <div className={styles.imageBanner}>
                  <GalleryHeaderButton src={rotateIcon} onClick={handleAssetRotate} />
                  <GalleryHeaderButton src={zoomInIcon} onClick={zoomIn} />
                  <GalleryHeaderButton src={zoomOutIcon} onClick={zoomOut} />
                </div>
              </>) : (
                <div className={styles.fileViewerWrapper} key={Math.random()}>
                  <FileViewer
                    fileType={getFileType(file?.asset_url)}
                    filePath={file?.asset_url}
                  />
                </div>

              )}
            </div>
            <div className={classNames(styles.button, rightDisabled && styles.disabled)} onClick={rightDisabled ? undefined : handleNextClick}>
              <ArrowRight className={styles.arrow} />
            </div>
          </div>
          <div className={styles.galleryInfo}>
            <div className={styles.galleryInfoHeading}>Information</div>
          <GalleryInfo label="Uploaded On" value={getFormattedDate(file?.created_at)} />
        {file?.tags?.[0]?.tag && <GalleryInfo label="Directory" value={file.tags[0].tag} />}

      {file?.sub_tags?.[0]?.name && <GalleryInfo label="Subdirectory" value={file.sub_tags[0].name} />}
      {fileName && <GalleryInfo label="File Name" value={<a href={file.asset_url} target="_blank">{fileName}</a>} />}
        <GalleryInfoButton as="a" href={file?.asset_url} download>
          Download
        </GalleryInfoButton>
        <DeleteButton button={GalleryInfoButton} icon={DeleteIcon} url={`/projects/${file?.project_id}/project_assets/${file?.id}.json`} toast="Asset has been deleted" to={false} onSuccess={handleDeleteAsset}>
          Delete
        </DeleteButton>
          </div>
        </div>
      </div>
    )}
    {children(openGallery)}
  </>)
}
