import {useState,useLayoutEffect} from 'react';
import {Link} from 'react-router-dom';
import Page from '@components/Layouts/Page';
import EmailList from './List';
import EmailMessage from './Message';
import {PaginationComponent} from '@components/Pagination';
import EmptyResourcePlaceholder from '@components/EmptyResourcePlaceholder';
import {RequestStatusSwitch} from '@containers/Request';
import usePaginationRequest from '@containers/usePaginationRequest';
import {withIsMobile} from '@containers/ScreenWidth';
import withPageTitle from '@containers/withPageTitle';
import withParams from '@containers/withParams';
import styles from './index.module.css';

const ProjectEmailInbox = ({
  openEmail,
  backHandler,
  setOpenEmail,
  emails,
  ...props
}) => (
  <div className={styles.container}>
    <div className={styles.emailList}>
      <PaginationComponent {...props}>
        <EmailList
          emails={emails}
          onClick={setOpenEmail}
          activeId={openEmail?.id} />
      </PaginationComponent>
    </div>
    {openEmail && (
      <EmailMessage
        email={openEmail}
        backHandler={backHandler} />
    )}
  </div>
)

const ProjectEmailInboxContainer = withIsMobile(({
  emails,
  isMobile,
  ...props
}) => {
  const [openEmail,setOpenEmail] = useState(),
        backHandler = isMobile ? () => {setOpenEmail()} : null;

  useLayoutEffect(() => {
    if(emails?.length > 0 && !isMobile && !openEmail){
      setOpenEmail(emails[0])
    }
  },[emails,isMobile,openEmail])

  return emails?.length > 0 ? (
    <ProjectEmailInbox
      {...props}
      emails={emails}
      openEmail={openEmail}
      setOpenEmail={setOpenEmail}
      backHandler={backHandler} />
  ) : (
    <EmptyResourcePlaceholder text="There are no project emails" hideButton />
  )
})

export const ProjectEmailListPageComponent = ({url,...props}) => {
  const {state,makeRequest,setQuery,previousPage,nextPage,setPage} = usePaginationRequest(url,10);
  return(
    <Page {...props} className={styles.noPadding}>
      <RequestStatusSwitch status={state.status} error={state.error}>
        <ProjectEmailInboxContainer emails={state.data.project_emails} previousPage={previousPage} nextPage={nextPage} setPage={setPage} pageCount={state.data.page_count} page={state.data.page} />
      </RequestStatusSwitch>
    </Page>
  )
}

const ProjectEmailListPage = withParams(({params:{projectId}}) => (
  <ProjectEmailListPageComponent
    url={`/projects/${projectId}/project_emails.json`}
    title="Project Emails"
    back="Back To Project"
  />
))

export default withPageTitle(ProjectEmailListPage, "Project Emails");
