import ProjectAssetsFormPage from '@components/ProjectAsset/Form';
import Request from '@containers/Request';
import DeleteButton from '@containers/DeleteButton';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {updateProjectAsset} from '@utils/api/project_asset';

const makeParamValues = (project_id,tag_ids,sub_tag_ids) => ({project_id,tag_ids: tag_ids ? tag_ids : undefined,sub_tag_ids: sub_tag_ids ? sub_tag_ids : undefined})

export const ProjectAssetUpdatePage = ({projectId,tagId,subTagId,projectAssetId}) => {
  const url = `/projects/${projectId}/project_assets/${projectAssetId}.json`;
  return(
    <Request url={url}>
      {(response) => (
        <ProjectAssetsFormPage
          form={{
            url,
            defaultValues: {asset: response.asset_url,...response,...makeParamValues(projectId,tagId,subTagId)},
            onSubmit: updateProjectAsset,
            toast: "Project file updated",
            to: "../.."
          }}
          page={{
            title: "Update Project File",
            back: {to: "../..",text: "Back To Directory"},
            submit: "Save",
            right: <DeleteButton url={url} />
          }}
        />
      )}
    </Request>
  );
}

const ProjectAssetUpdatePageContainer = withParams(({params}) => (
  <ProjectAssetUpdatePage {...params} />
))

export default withPageTitle(ProjectAssetUpdatePageContainer, "Update Project File");
