import {Link} from 'react-router-dom';
import Page from '@components/Layouts/CommonPage';
import Value from '@components/Value';
import Button from '@components/Button';
import {Switch} from '@components/Form/Fields/Switch';
import SplitRow from '@components/SplitRow';
import Request from '@containers/Request';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';

const TitlePage = withParams(({params:{id}}) => (
  <Request url={`/titles/${id}.json`}>
    {(response) => (
      <Page
        page={{
          title: "Role",
          back: "Back To Roles",
          right: (<Button as={Link} to="edit">Update</Button>)
        }}
        title="Role Information"
      >
        <Value value={response.title} label="Title" />
      </Page>
    )}
  </Request>
))

export default withPageTitle(TitlePage, "Role Details");
