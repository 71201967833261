import CompanyFormPage from '@components/Company/Form';
import withPageTitle from '@containers/withPageTitle';
import {createCompany} from '@utils/api/company';

const CompanyCreatePage = () => (
  <CompanyFormPage
    form={{
      url: "/companies.json",
      defaultValues: {addresses: [{}]},
      onSubmit: createCompany,
      toast: "Company added"
    }}
    page={{
      title: "Create Company",
      back: "Back To Companies",
      submit: "Create New Company"
    }}
  />
);

export default withPageTitle(CompanyCreatePage, "Create A Company");
