import {Link} from 'react-router-dom';
import Page from '@components/Layouts/Page';
import Button from '@components/Button';
import ProjectStageList from '@components/Tool/Stage/List';
import Request from '@containers/Request';
import withPageTitle from '@containers/withPageTitle';

export const ProjectStageListPageComponent = ({url,...props}) => (
  <Page
    {...props}
    right={<Button as={Link} to="new">Add Project Stage</Button>}
  >
    <Request url={url}>
      {(project_stages) => <ProjectStageList projectStages={project_stages} />}
    </Request>
  </Page>
)

const ProjectStageListPage = () => (
  <ProjectStageListPageComponent
    url="/project_stages.json"
    title="Project Stages"
  />
)

export default withPageTitle(ProjectStageListPage, "Project Stages");
