import { Link } from 'react-router-dom';
import Page from '@components/Layouts/CommonPage';
import Button from '@components/Button';
import Value from '@components/Value';
import AddressSection from '@components/Address/Section';
import ContactSection from '@components/Contact/Section';
import ProjectSection from '@components/Project/Section';
import Request from '@containers/Request';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {phoneFormat} from '@utils';

const CompanyPage = withParams(({params:{id}}) => (
  <Request url={`/companies/${id}.json`}>
    {(response) => (
      <Page
        page={{
          title: "Company",
          back: "Back To Companies",
          right: (<Button as={Link} to="edit">Update</Button>)
        }}
        title="Company Information"
        /*left={<>
          <Value label="Name" value={response.name} />
          <Value label="DBA" value={response.dba_name} />
          <Value label="Short Name" value={response.short_name} />
        </>}
        right={<>
          <Value label="Website" value={response.website && <a href={response.website} target="_blank" rel="noreferrer">{response.website}</a>} />
          <Value label="Office Phone" value={response.office_phone} format={phoneFormat} />
          <Value label="Fax Phone" value={response.fax_phone} format={phoneFormat} />
          <Value label="Email" value={response.email && <a href={`mailto:${response.email}`}>{response.email}</a>} />
        </>}*/
      >
        <Value label="Name" value={response.name} />
        <Value label="Website" value={response.website && <a href={response.website} target="_blank" rel="noreferrer">{response.website}</a>} />
        <ProjectSection companyId={id} />
        <ContactSection url={`/companies/${id}/contacts.json`} collapsible placeholder="This company has no contacts" back="Back To Company" />
        {response.addresses?.length > 0 && <AddressSection addresses={response.addresses} />}

      </Page>
    )}
  </Request>
))

export default withPageTitle(CompanyPage, "Company Details");
