import styles from './index.module.css'

const EmailInfoItem = ({label,value}) => (
  <div className={styles.container}>
    <div className={styles.label}>{label}:</div>
    <div>{value}</div>
  </div>
)

export default EmailInfoItem
