import {Link} from 'react-router-dom';
import Page from '@components/Layouts/CommonPage';
import Value from '@components/Value';
import Button from '@components/Button';
import SplitRow from '@components/SplitRow';
import Request from '@containers/Request';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {getFormattedDate} from '@utils';

const ProjectTypePage = withParams(({params:{id}}) => (
  <Request url={`/project_types/${id}.json`}>
    {(response) => (
      <Page
        page={{
          title: "Project Type",
          back: "Back To Project Types",
          right: (<Button as={Link} to="edit">Update</Button>)
        }}
        title="Project Type Information"
      >
        <Value value={response.name} label="Name" />
        <SplitRow>
          <Value value={response.created_at && getFormattedDate(response.created_at)} label="Created" />
          <Value value={response.updated_at && getFormattedDate(response.updated_at)} label="Last Updated" />
        </SplitRow>
        <Value value={response.description} label="Description" />
      </Page>
    )}
  </Request>
))

export default withPageTitle(ProjectTypePage, "Project Type Details");
