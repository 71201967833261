import FormPage from '@components/Form/Page';
import Input from '@components/Form/Fields/Input';
import Switch from '@components/Form/Fields/Switch';
import {titleSchema} from '@utils/validation/title';

const TitleFormPage = ({form,...props}) => (
  <FormPage
    {...props}
    form={{
      ...form,
      validationSchema: titleSchema
    }}
    title="Role Information"
  >
    <Input name="title" label="Title" placeholder="Enter Title" />
  </FormPage>
);

export default TitleFormPage;
