import logo from '@assets/images/logo.svg';
import styles from './index.module.css';

const Shell = () => (
  <div className={styles.container}>
    <img alt="Darana Hybrid logo" src={logo} className={styles.logo} />
  </div>
)

export default Shell;
