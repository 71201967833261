import {forwardRef} from 'react';
import {Controller} from 'react-hook-form';
import styles from './index.module.css';
import closeIcon from '@assets/images/close.svg';
import dragIcon from '@assets/images/drag_indicator-24px.svg';
import {classNames} from '@utils';

const ArrayField = forwardRef(({onChange,remove,value,name,root,title,dragHandleProps,children,id,i},ref) => {
  const handleRemoveClick = () => {
    if(id){
      onChange(true)
    } else {
      remove(i)
    }
  }
  return(
    <div className={classNames(styles.container, value && styles.hidden)}>
      <div {...dragHandleProps} className={styles.title}>
        {dragHandleProps && <img alt="Drag handle" src={dragIcon} className={styles.dragIcon} />}
        {title}
      </div>
      {children}
      {id ? <Controller as="input" type="hidden" name={`${root}id`} defaultValue={id} className={styles.hidden} /> : null}
      <img src={closeIcon} alt="Remove" className={styles.close} onClick={handleRemoveClick} />
      {id ? <input name={name} type="hidden" className={styles.hidden} value={value} ref={ref} /> : null}
    </div>
  )
})

const ArrayFieldController = ({name,id,...props}) => (id && !isNaN(id)) ? (
  <Controller {...props} name={`${name}_destroy`} root={name} id={id} defaultValue={false} as={ArrayField} />
) : (
  <ArrayField {...props} root={name} />
)

export default ArrayFieldController;
