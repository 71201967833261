import FormPage from '@components/Form/Page';
import Input from '@components/Form/Fields/Input';
import DateInput from '@components/Form/Fields/Date';
import Switch from '@components/Form/Fields/Switch';
import SplitRow from '@components/SplitRow';
import ReactSelect from '@components/Form/Fields/ReactSelect';
import RequestSelect from '@containers/RequestSelect';
import AppInfoSelect from '@containers/AppInfoSelect';
import {projectMeetingSchema} from '@utils/validation/project_meeting';

const contactLabelAccessor = ({contact}) => `${contact.first_name} ${contact.last_name}`
const timezoneAccessor = (time_zone) => time_zone
const ProjectMeetingFormPage = ({form,projectId,...props}) => (
  <FormPage
    {...props}
    form={{
      ...form,
      validationSchema: projectMeetingSchema
    }}
    title="Project Meeting Information"
  >
    <Input name="project_id" type="hidden" className="hidden" />
    <SplitRow>
      <Input name="meeting_num" label="Meeting #" placeholder="Enter Meeting #" defaultValue="1" required />
      <Input name="name" label="Meeting Name" placeholder="Enter Meeting Name" />
    </SplitRow>
    <Input name="video_conference_link" label="Video Conferencing Link" placeholder="Enter Video Conferencing Link" />
    <Switch name="private" label="Private Meeting" />
      <SplitRow>
        <DateInput name="meeting_date" label="Meeting Date" closeOnScroll withPortal />
        <AppInfoSelect name="time_zone" label="Time Zone" placeholder="Select Time Zone..." resource="time_zones" labelAccessor={timezoneAccessor} valueAccessor={timezoneAccessor}  />
      </SplitRow>
    <SplitRow>
      <Input type="time" name="start_time" label="Start Time" />
      <Input type="time" name="end_time" label="End Time" />
    </SplitRow>
    <Input name="meeting_location" label="Meeting Location" placeholder="Enter Meeting Location" />
    <Input as="textarea" rows="6" name="overview" label="Overview" placeholder="Enter Overview" />
    <RequestSelect name="contact_ids" label="Scheduled Attendees" placeholder="Select Scheduled Attendees..." isMulti url={`/projects/${projectId}/contact_projects.json?page_size=200`} labelAccessor={contactLabelAccessor} valueAccessor={({contact}) => contact.id} as={ReactSelect} />
  </FormPage>
);

export default ProjectMeetingFormPage;
