import {RequestSection} from '@components/Section';
import ProjectMeetingList from '@components/ProjectMeeting/List';
import ViewMoreLink from '@components/ViewMoreLink';
import EmptyResourcePlaceholder from '@components/EmptyResourcePlaceholder';

const ProjectMeetingSection = ({projectId,hideButton,back = "Back To Project", title = "Meetings", collapsible = true, pageSize = 10, ...props}) => (
  <RequestSection title={title} url={`/projects/${projectId}/project_meetings.json`} pageSize={pageSize} collapsible={collapsible} {...props}>
    {({project_meetings}) => (
      project_meetings?.length > 0 ? (<>
        <ProjectMeetingList projectMeetings={project_meetings} linkRoot="meetings/" secondary />
        <ViewMoreLink to="meetings" hideButton={hideButton} back={back} />
      </>) : (
        <EmptyResourcePlaceholder to="meetings/new" text="This project has no meetings" hideButton={hideButton} back={back} />
      )
    )}
  </RequestSection>
)

export default ProjectMeetingSection
