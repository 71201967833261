import {useState,useEffect,useRef} from 'react';
import {Link,useLocation} from 'react-router-dom';
import Modal from '@components/Modal';
import {Buttons} from '@components/Pagination';
import useDebounce from '@containers/useDebounce';
import usePaginationRequest from '@containers/usePaginationRequest';
import {RequestStatusSwitch} from '@containers/Request';
import {canUser,withUser} from '@containers/Auth';
import {classNames} from '@utils';
import styles from './index.module.css';
import searchIcon from '@assets/images/search_white_24dp.svg';
import backIcon from '@assets/images/arrow_back_ios-24px.svg';

const makeNameDefault = ({name}) => name
const makeContactName = ({first_name,last_name}) => `${first_name} ${last_name}`

const ItemList = ({items,root,makeName = makeNameDefault}) => (
  <div className={styles.sectionList}>
    {items?.map(item => (
      <Link to={`/${root === "projects" ? `companies/${item.company_id}/${root}` : root}/${item.id}`} className={`${styles.item} selectable`} key={item.id}>{makeName(item)}</Link>
    ))}
  </div>
)

const SearchSection = ({label,items,makeName = makeNameDefault,setCategory}) => items?.length > 0 && (
  <div className={styles.section}>
    <div className={styles.sectionLabel}>{label}</div>
    <ItemList items={items} root={label} makeName={makeName} />
    <div onClick={() => {setCategory(label)}} className={styles.viewLink}>View More</div>
  </div>
)
const makeCategory = (category) => {
  switch (category) {
    case "companies":
      return "company"
    case "projects":
      return "project"
    case "contacts":
      return "contact"
    default:
      return "contact"
  }
}
const SearchContent = ({category,setCategory,data,previousPage,nextPage,setPage,back}) => category ? (
  <div className={styles.searchContentContainer}>
    <div className={styles.backWrapper} onClick={back}>
      <img alt="Back" src={backIcon} className={styles.back} />
      <div className={styles.backText}>{category}</div>
    </div>
    <div className={styles.paginatedList}>
      <Buttons
        className={styles.pagination}
        pageCount={data[`${makeCategory(category)}_page_count`]}
        page={data.page}
        previousPage={previousPage}
        nextPage={nextPage}
        setPage={setPage}
        white
      />
      <ItemList items={data[category]} root={category} makeName={category === "contacts" ? makeContactName : undefined} />
    </div>
  </div>
) : (<>
  <SearchSection label="companies" items={data.companies} setCategory={setCategory} />
  <SearchSection label="projects" items={data.projects} setCategory={setCategory} />
  <SearchSection label="contacts" items={data.contacts} makeName={makeContactName} setCategory={setCategory} />
</>)


const URL = "/search.json"
const SearchModal = ({close}) => {
  const [value,setValue] = useState(),
        [category,setCategory] = useState(),
        debouncedValue = useDebounce(value, 500),
        {state,makeRequest,setQuery,previousPage,nextPage,setPage} = usePaginationRequest(URL,8,undefined,"POST",true),
        ref = useRef(),
        handleTextChange = (e) => setValue(e.target.value),
        back = () => {
          setCategory();
        }

  useEffect(
    () => {
      if (debouncedValue) {
        setQuery({which: category,search: debouncedValue},category ? 5 : 8)
      }
    },
    [debouncedValue,category]
  );
  useEffect(() => {
    setTimeout(() => ref.current && ref.current.focus(),1)
  },[]);
  return(<>
      <div className={styles.inputWrapper}><input type="text" name="search" autoFocus value={value} onChange={handleTextChange} className={styles.input} ref={ref} /></div>
      <div className={styles.searchContent}>
        {value ? (
          <RequestStatusSwitch status={(value && value !== debouncedValue) ? "loading" : state.status} error={state.error} white>
            {state.data ? (
              <SearchContent back={back} category={category} data={state.data} setCategory={setCategory} setPage={setPage} nextPage={nextPage} previousPage={previousPage} />
            ) : (
              <div className={styles.placeholderText}>Empty</div>
            )}
          </RequestStatusSwitch>
        ) : (
          <div className={styles.placeholderText}>Start typing to search</div>
        )}
      </div>
  </>)
}


const Search = ({children, button, className,headerLink,wrapperClassName,buttonClassName,contentClassName,title}) => {
  const [isOpen,setIsOpen] = useState(),
        location = useLocation(),
        close = () => setIsOpen(),
        open = () => setIsOpen(true);

  useEffect(() => {
    setIsOpen(false)
  }, [location]);
  return(<>
    <div className={styles.search} onClick={open}><img src={searchIcon} className={styles.searchIcon} /> Search</div>
    {isOpen && <Modal close={close} className={styles.modal}><SearchModal close={close} /></Modal>}
  </>)
}

export default Search;
