import {useState} from 'react';
import Button from '@components/Button';
import AddContactModal from '@components/ProjectContact/AddModal';
import Section from '@components/Section';
import ViewMoreLink from '@components/ViewMoreLink';
import EmptyResourcePlaceholder from '@components/EmptyResourcePlaceholder';
import Contacts from '@components/ProjectContact/List';

const AddContactModalContainer = ({projectId,contacts,makeRequest,text = "Add",...props}) => {
  const [modal,setModal] = useState();
  return(<>
    <Button type="button" {...props} onClick={() => setModal(true)}>{text}</Button>
    {modal && <AddContactModal onSuccess={makeRequest} filter={(c) => contacts.findIndex(({contact}) => contact.id === c.id) === -1} close={() => setModal()} projectId={projectId} />}
  </>)
}

const ContactSection = ({contacts,user,hideButton,linkRoot = "contacts/",back = "Back To Project", hideViewMore, placeholder = "This project has no contacts",makeRequest, collapsible = true,projectId,projectEmail,...props}) => (
  <Section title="Contacts" collapsible={collapsible} {...props}>
    {contacts?.length > 0 ? (<>
      <Contacts contacts={contacts} linkRoot={linkRoot} secondary noLink={hideButton} projectEmail={projectEmail} />
      {!hideViewMore && <ViewMoreLink to="contacts" text={placeholder} hideButton={hideButton} back={back} />}
    </>) : (
      <EmptyResourcePlaceholder to="contacts/new" text={placeholder} hideButton={hideButton} back={back} />
    )}
  </Section>
)

export default ContactSection;
