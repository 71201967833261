import moment from 'moment';
import {makeRequest} from '@utils/api';

export const createProjectBudgetItem = (project_budget_item, url) => makeRequest("POST",url,{
  project_budget_item
})

export const updateProjectBudgetItem = (project_budget_item, url) => makeRequest("PATCH",url,{
  project_budget_item
})
