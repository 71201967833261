import { Link } from 'react-router-dom';
import Page from '@components/Layouts/CommonPage';
import Button from '@components/Button';
import Value from '@components/Value';
import Request from '@containers/Request';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import withIsAuthorized from '@containers/withIsAuthorized';
import styles from './index.module.css';

const ViewProjectFormPage = withParams(withIsAuthorized(({isAuthorized,params:{projectId,projectFormId}}) => (
  <Page
    page={{
      title: "Project Form",
      back: "Back To Forms",
      right: isAuthorized && (<Button as={Link} to="edit">Update</Button>)
    }}
    title="Project Form Information"
  >
    <Request url={`/projects/${projectId}/project_forms/${projectFormId}.json`}>
      {({form_values}) => form_values && form_values.map(f => <Value className={styles.value} key={f.id} label={f.label} value={f.form_field.type === "BooleanField" ? f.value === "1" ? "Yes" : "No" : f.value} />)}
    </Request>
  </Page>
),"Project Forms","write"))

export default withPageTitle(ViewProjectFormPage, "Project Form Details");
