import {makeRequest} from '@utils/api';

export const updateProjectContact = (contact_project, url) => makeRequest("PATCH",url,{
  contact_project
})

export const createProjectContact = ({project_id,contact_id,title_id,...contact_attributes},url) => makeRequest("POST",url,{
  contact_project: {
    ...(contact_id ? {contact_id} : {title_id,contact_attributes:{title_id,...contact_attributes}}),
    project_id
  }
})
