import {getValue} from '@components/Form/Fields/ReactSelect';
import {makeRequest} from '@utils/api';
import {apiDateFormat} from '@utils';

const makePrimeContract = (contract) => {
  const obj = {...contract};
  if(obj.start_date) obj.start_date = apiDateFormat(obj.start_date)
  if(obj.estimated_completion_date) obj.estimated_completion_date = apiDateFormat(obj.estimated_completion_date)
  if(obj.actual_completion_date) obj.actual_completion_date = apiDateFormat(obj.actual_completion_date)
  if(obj.signed_contract_received_date) obj.signed_contract_received_date = apiDateFormat(obj.signed_contract_received_date)
  if(obj.contract_terminated_date) obj.contract_terminated_date = apiDateFormat(obj.contract_terminated_date)
  obj.owner_company_id = getValue(obj.owner_company_id)
  obj.contractor_company_id = getValue(obj.contractor_company_id)
  return obj
}

export const createProjectPrimeContract = (project_prime_contract, url) => makeRequest("POST",url,{
  project_prime_contract: makePrimeContract(project_prime_contract)
})

export const updateProjectPrimeContract = (project_prime_contract, url) => makeRequest("PATCH",url,{
  project_prime_contract: makePrimeContract(project_prime_contract)
})
