import RequestButton from '@containers/RequestButton';
import {sendInvitation} from '@utils/api/contact';

const handleInviteButtonClick = (id) => () => new Promise(
  async (resolve,reject) => {
    try {
      resolve(await sendInvitation(id))
    } catch (e) {
      reject(e)
    }
  }
)

const InviteButton = ({id,reinvite,toast = "Contact invite successful",...props}) => (
  <RequestButton {...props} toast={toast} makeRequest={handleInviteButtonClick(id)}>{reinvite ? "Re-Invite" : "Invite"}</RequestButton>
)

export default InviteButton;
