import styles from './index.module.css';
import {classNames} from '@utils';

const ImageComponent = ({src, alt, className, small,imgClassName, children,...props}) => (
    <div className={classNames(styles.container, small && styles.small, className)} {...props}>
      <img src={src} alt={alt} className={classNames(styles.image, imgClassName)} />
      {children}
    </div>
)

export default ImageComponent;
