import styles from './index.module.css';
import {classNames} from '@utils';

export const Loader = () => <div className={styles.loader} />

export const RequestLoader = ({white}) => (
  <div className={classNames(styles.wrapper, white && styles.white)}>
    <Loader />
  </div>
)
const LoaderOverlay = () => (
  <div className={styles.container}>
    <Loader />
  </div>
)

export default LoaderOverlay;
