import { Link } from 'react-router-dom';
import {Card} from '@components/ProjectAsset/Card';
import folderIcon from '@assets/images/folder_open-24px.svg';
import styles from './index.module.css';
import {classNames} from '@utils';

const FolderCard = ({folder, className, as = Link,...props}) => (
  <Card {...props} as={Link} to={`${folder.id}`} className={classNames(styles.container, className)}>
    <img src={folderIcon} className={styles.image} />
    {folder.name}
  </Card>
)

export default FolderCard;
