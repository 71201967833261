import UserFormPage from '@components/User/Form';
import Request from '@containers/Request';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {updateUser} from '@utils/api/user';

const UserUpdatePage = withParams(({params:{id}}) => {
  const url = `/users/${id}.json`;
  return(
    <Request url={url}>
      {({permissions,...user}) => (
        <UserFormPage
          form={{
            url,
            defaultValues: {permission_set_id: permissions.id,...user},
            onSubmit: updateUser,
            toast: "User updated"
          }}
          page={{
            title: "Update User",
            back: "Back To Contact",
            submit: "Update User"
          }}
        />
      )}
    </Request>
  );
});

export default withPageTitle(UserUpdatePage, "Update User");
