import Header from './Header';
import Footer from './Footer';
import styles from './index.module.css';

const Layout = ({children}) => (<>
  <Header />
  <main className={styles.container}>
    {children}
  </main>
  <Footer />
</>);

export default Layout;
