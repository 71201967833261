import {classNames} from '@utils';
import Label from '@components/Form/Label';
import Error from '@components/Form/Fields/Error';
import styles from './index.module.css';

const withField = (WrappedComponent, WrapperComponent = "label") => ({label, className,onClick, secondary, small,...props}) => (
  <WrapperComponent className={classNames(styles.container,className, props.as === "textarea" && styles.textarea, secondary && styles.secondary, small && styles.small)} onClick={onClick}>
    {label && <Label>{label}</Label>}
    <div className={styles.inputWrapper}>
      <WrappedComponent {...props} />
      <Error name={props.name} className={styles.error} />
    </div>
  </WrapperComponent>
);

export default withField;
