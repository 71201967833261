import InfoItem from './InfoItem';
import styles from './index.module.css'

const EmailSubheader = ({email}) => (
  <div className={styles.container}>
    <InfoItem label="From" value={email.from} />
    <InfoItem label="Sent" value={email.sent_at} />
    <InfoItem label="To" value={email.to} />
    <InfoItem label="Subject" value={email.title} />
  </div>
)

export default EmailSubheader
