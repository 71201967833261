import ProjectTypeFormPage from '@components/Tool/Type/Form';
import Request from '@containers/Request';
import DeleteButton from '@containers/DeleteButton';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {updateProjectType} from '@utils/api/project_type';

const ProjectTypeUpdatePage = withParams(({params:{id}}) => {
  const url = `/project_types/${id}.json`
  return(
    <Request url={url}>
      {(defaultValues) => (
        <ProjectTypeFormPage
          form={{
            url,
            defaultValues,
            onSubmit: updateProjectType,
            toast: "Project type updated"
          }}
          page={{
            title: "Update Project Type",
            back: "Back To Project Type",
            submit: "Update Project Type",
            right: <DeleteButton url={url} />
          }}
        />
      )}
    </Request>
  )
})

export default withPageTitle(ProjectTypeUpdatePage, "Update Project Type");
