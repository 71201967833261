import Section from '@components/Section';
import Addresses from '@components/Address/List';

const AddressSection = ({addresses,...props}) => addresses?.length > 0 ? (
  <Section title="Addresses" {...props}>
    <Addresses addresses={addresses} secondary />
  </Section>
) : null

export default AddressSection
