import {string, object,mixed,array} from 'yup';

export const projectAssetSchema = object().shape({
  project_id: string().required('Project is required'),
  asset: mixed().required('This field is required'),
  tag_ids: mixed().required('Directory is required'),
  sub_tag_ids: mixed().test('is required if new directory selected',"Subdirectory is required",function(){
    return (this.parent.tag_ids == 21 || this.parent.tag_ids?.value == 21) ? this.parent.sub_tag_ids : true;
  }),
  album_name: string().test('is required if new directory selected',"Subdirectory name is required",function(){
    return this.parent.sub_tag_ids?.value === "new" ? this.parent.album_name : true;
  }),
});
