import TitleFormPage from '@components/Tool/Title/Form';
import Request from '@containers/Request';
import DeleteButton from '@containers/DeleteButton';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {useAppInfoRequest} from '@containers/AppInfo';
import {updateTitle} from '@utils/api/title';

const TitleUpdatePage = withParams(({params:{id}}) => {
  const url = `/titles/${id}.json`,
        onSuccess = useAppInfoRequest();
  return(
    <Request url={url}>
      {(defaultValues) => (
        <TitleFormPage
          form={{
            url,
            defaultValues,
            onSubmit: updateTitle,
            toast: "Title updated",
            to: "../..",
            onSuccess: () => {onSuccess()}
          }}
          page={{
            title: "Update Title",
            back: {to: '../..',text:"Back To Titles"},
            submit: "Update Title",
            right: <DeleteButton url={url} onSuccess={onSuccess} />
          }}
        />
      )}
    </Request>
  )
})

export default withPageTitle(TitleUpdatePage, "Update Title");
