import { Link } from 'react-router-dom';
import Page from '@components/Layouts/Page';
import Table from '@components/Table';
import Button from '@components/Button';
import Pagination from "@components/Pagination";
import {phoneFormat,getFormattedDate} from '@utils';

const CompanyListPage = () => (
  <Page
    title="Companies"
    right={<Button as={Link} to="new" >Create New Company</Button>}
  >
    <Pagination url="/companies.json" pageSize={25}>
      {(request) => (
        <Table
          cols={["Company Name", /*"Phone Number", "Email",*/"Website"]}
          rows={request.data.companies}
          renderRow={(p) => (<>
            <div>{p.name}</div>
            {/*<div>{phoneFormat(p.office_phone)}</div>
            <div>{p.email}</div>*/}
            <div>{p.website && <a href={p.website} target="_blank">{p.website}</a>}</div>
          </>)}
          placeholder={{to: "new", text: "There are no companies"}}
        />
      )}
    </Pagination>
  </Page>
)

export default CompanyListPage;
