import { Link } from 'react-router-dom';
import Page from '@components/Layouts/Page';
import ContactList from '@components/ProjectContact/List';
import Button from '@components/Button';
import Request from '@containers/Request';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import withIsAuthorized from '@containers/withIsAuthorized';

export const ProjectContacts = ({isAuthorized,project}) => (
  <Page
    right={isAuthorized && <Button as={Link} to="new">Add Contact</Button>}
    title="Project Contacts"
    back="Back To Project"
  >
    <Request url={`/projects/${project.id}/contact_projects.json`}>
      {(contacts,makeRequest) => <ContactList contacts={contacts} projectEmail={project.email} />}
    </Request>    
  </Page>
)

const ProjectContactsContainer = withParams(withIsAuthorized(({isAuthorized,params:{projectId}}) => (
  <Request url={`/projects/${projectId}.json`}>
    {(project) => (
      <ProjectContacts isAuthorized={isAuthorized} project={project}  />
    )}
  </Request>
),"Contacts","write"))

export default withPageTitle(ProjectContactsContainer, "Project Contacts");
