import { Link } from 'react-router-dom';
import Page from '@components/Layouts/Page';
import ProjectFormList from '@components/ProjectForm/List';
import Button from '@components/Button';
import {PaginatedTable} from '@components/Table';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import withIsAuthorized from '@containers/withIsAuthorized';

export const ProjectFormListPageComponent = withIsAuthorized(({isAuthorized,url,...props}) => (
  <Page
    right={isAuthorized && <Button as={Link} to="new">Create New Form</Button>}
    {...props}
  >
    <PaginatedTable url={url} pageSize={25}>
      {(request) => <ProjectFormList error={request.error} status={request.status} projectForms={request.data.project_forms} />}
    </PaginatedTable>
  </Page>
),"Project Forms","write")

const ProjectFormListPage = withParams(({params:{projectId}}) => (
  <ProjectFormListPageComponent
    title="Project Forms"
    back="Back to Project"
    url={`/projects/${projectId}/project_forms.json?page_size=2`}
  />
))


export default withPageTitle(ProjectFormListPage, "Project Forms");
