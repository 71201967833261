import FormPage from '@components/Form/Page';
import Input from '@components/Form/Fields/Input';
import {projectStageSchema} from '@utils/validation/project_stage';

const ProjectStageFormPage = ({form,...props}) => (
  <FormPage
    {...props}
    form={{
      ...form,
      validationSchema: projectStageSchema
    }}
    title="Project Stage Information"
  >
    <Input name="name" label="Name" placeholder="Enter Name" />
  </FormPage>
);

export default ProjectStageFormPage;
