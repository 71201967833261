import {RequestStatusSwitch} from '@containers/Request';
import usePaginationRequest from '@containers/usePaginationRequest';
import styles from './index.module.css';
import {classNames} from '@utils';

const makePageButtonArray = (page,pageCount,buttonCount = 5) => {
  const arr = [],
        wings = Math.floor(buttonCount / 2);
  var start = 1,
      end = pageCount < buttonCount ? pageCount : buttonCount;
  if(pageCount > buttonCount && page > wings){
    if(page > pageCount - wings){
      start = pageCount - (buttonCount - 1)
      end = pageCount
    } else {
      start = page - wings
      end = page + wings
    }
  }
  for (var i = start; i < end + 1; i++) {
    arr.push(i)
  }
  return arr;
}
export const Buttons = ({pageCount,page,previousPage,nextPage,setPage,className,section}) => pageCount > 1 && (
  <div className={classNames(styles.paginatedButtons,section && styles.section,className)}>
    <div onClick={() => setPage(1)} className={classNames(styles.wingButton, page === 1 && styles.disabledPageButton)} title="First">«</div>
    <div onClick={page === 1 ? undefined : previousPage} className={classNames(styles.wingButton, page === 1 && styles.disabledPageButton)} title="Previous">‹</div>
    {makePageButtonArray(page,pageCount).map(n => (
      <div className={classNames(styles.paginatedPageButton, page === n && styles.activePageButton)} key={n} onClick={() => {setPage(n)}}>{n}</div>
    ))}
    <div onClick={page >= pageCount ? undefined : nextPage} className={classNames(styles.wingButton, page >= pageCount && styles.disabledPageButton)} title="Next">›</div>
    <div onClick={() => setPage(pageCount)} className={classNames(styles.wingButton, page >= pageCount && styles.disabledPageButton)} title="Last">»</div>
  </div>
)

export const PaginationComponent = ({children,className,...props}) => (
  <div className={classNames(styles.container,className)}>
    {children}
    <Buttons {...props} />
  </div>
)

export const Pagination = ({status,error,children,...props}) => (
  <RequestStatusSwitch status={status} error={error}>
    <PaginationComponent {...props}>
      {children}
    </PaginationComponent>
  </RequestStatusSwitch>
)

const StatefulPagination = ({url,pageSize,children,initialQuery,...props}) => {
  const {state,makeRequest,setQuery,previousPage,nextPage,setPage} = usePaginationRequest(url,pageSize,initialQuery);
  return(
    <Pagination {...props} previousPage={previousPage} nextPage={nextPage} setPage={setPage} pageCount={state.data.page_count} page={state.data.page} status={state.status} error={state.error}>
      {children(state)}
    </Pagination>
  )
}

export default StatefulPagination;
