import Table from '@components/Table';
import {sortTitles} from '@utils';

const TitleList = ({titles = []}) => (
  <Table
    cols={["Title"]}
    rows={titles.sort(sortTitles)}
    to={({id}) => `${id}/edit`}
    renderRow={(p) => (<div>{p.title}</div>)}
    placeholder={{to: "new", text: "There are no roles"}}
  />
)

export default TitleList;
