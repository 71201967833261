import Table from '@components/Table';

const TagList = ({tags}) => (
  <Table
    cols={["Name","Has Subdirectories"]}
    rows={tags}
    to={({id}) => `${id}/edit`}
    renderRow={(p) => (<>
      <div>{p.tag}</div>
      <div>{p.has_subtags ? "Yes" : "No"}</div>
    </>)}
    placeholder={{to: "new", text: "There are no directories"}}
  />
)

export default TagList;
