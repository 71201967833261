import styles from './index.module.css';
import {classNames} from '@utils'

const Content = ({left,right,title,children}) => (
  <div className={styles.container}>
    <div className={styles.title}>{title}</div>
    <div className={styles.content}>
      <div className={styles.flex}>
        <div>
          {left}
        </div>
        <div>
          {right}
        </div>
      </div>
      {children}
    </div>
  </div>
)

export default Content;
