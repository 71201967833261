import {Link} from 'react-router-dom';
import Button from '@components/Button';
import makeBackState from '@containers/makeBackState';
import styles from './index.module.css';

const EmptyResourcePlaceholder = ({text,as,to,back,buttonText,button,hideButton,...props}) => {
  const backState = makeBackState(back)
  return(
    <div className={styles.container}>
      <div className={styles.text}>{text}</div>
      {!hideButton && (button ? button : <Button {...props} form as={to ? Link : undefined} state={backState} to={to}>{buttonText ? buttonText : "Add One"}</Button>)}
    </div>
  )
}
export default EmptyResourcePlaceholder;
