import {Link} from 'react-router-dom';
import Page from '@components/Layouts/Page';
import Button from '@components/Button';
import PermissionSetList from '@components/Tool/PermissionSet/List';
import Request from '@containers/Request';
import withPageTitle from '@containers/withPageTitle';

export const PermissionSetListPageComponent = ({url,...props}) => (
  <Page
    {...props}
    right={<Button as={Link} to="new">Add Permission Set</Button>}
  >
    <Request url={url}>
      {(permission_sets) => <PermissionSetList permissions={permission_sets} />}
    </Request>
  </Page>
)

const PermissionSetListPage = () => (
  <PermissionSetListPageComponent
    url="/permission_sets.json"
    title="Permission Sets"
  />
)

export default withPageTitle(PermissionSetListPage, "Permission Sets");
