import { useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import {useLoaderDispatch} from "@containers/Loader";
import {useToastAddAction} from '@containers/ToastContext';
import {useAuthDispatch, logoutAction} from '@containers/Auth';
import {useErrorModalDispatch} from "@containers/ErrorModal";
import {FormError,TokenError} from '@utils/api/error';

export default function Form({validationSchema, onSubmit,to = "..", toast, onError, children, className,blockNav, defaultValues, url, onSuccess = () => {}, ...props}) {
  const loaderDispatch = useLoaderDispatch(),
        navigate = useNavigate(),
        errorModalDispatch = useErrorModalDispatch(),
        addToast = useToastAddAction(),
        authDispatch = useAuthDispatch(),
        form = useForm({
          resolver: validationSchema && yupResolver(validationSchema),
          defaultValues
        }),
        handleSubmit = async formValues => {
          loaderDispatch({type: "START_LOADING"});
          try {
            const response = await onSubmit(formValues, url,defaultValues);
            onSuccess({response, formValues, defaultValues});
            if(to){
              if(typeof to === "function"){
                to(navigate,response)
              } else {
                navigate(to)
              }
            }
            if(toast){
              addToast(toast)
            }
          } catch (e) {
            if(onError){

              onError(e,errorModalDispatch)
            } else {
              if(e instanceof FormError){
                if(e.fieldErrors){
                  e.fieldErrors.forEach(({ name, type, message }) =>
                    form.setError(name, { type, message })
                  )
                }

                if(e.message){
                  errorModalDispatch(e.message)
                }
              } else if(e instanceof TokenError) {
                authDispatch(logoutAction())
              } else {
                errorModalDispatch("An unexpected error has occured")
              }
            }
          } finally {
            loaderDispatch({type: "STOP_LOADING"});
          }
        }
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className={className} {...props}>
        {children}
      </form>
    </FormProvider>
  );
};
