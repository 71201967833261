import { useWatch } from "react-hook-form";
import Form from '@components/Form';
import Input from '@components/Form/Fields/Input';
import Page from '@components/Layouts/CommonPage';
import FileInput from '@components/Form/Fields/File';
import TagSelect from '@components/ProjectAsset/TagSelect';
import ReactSelect from '@components/Form/Fields/ReactSelect';
import AppInfoSelect from '@containers/AppInfoSelect';
import {projectAssetSchema} from '@utils/validation/project_asset';

export const SubDirectoryFields = () => {
  const subTagId = useWatch({name: "sub_tag_ids"});
  return(<>
    <AppInfoSelect name="sub_tag_ids" as={ReactSelect} resource={(response) => response?.sub_tags ? ([{id: "new",name: "Add New Subdirectory"},...response.sub_tags]) : []} label="Subdirectory" placeholder="Select Subdirectory..." />
    {subTagId?.value === "new" && (
      <Input name="album_name" label="New Subdirectory Name" placeholder="Enter Subdirectory Name" />
    )}
  </>)
}

export const SubDirectoryFieldsContainer = () => {
  const tagId = useWatch({name: "tag_ids"});
  return (tagId == 21 || tagId?.value == 21) ? (
    <SubDirectoryFields />
  ) : null
}

const ProjectAssetsForm = ({
  children,
  title = "Asset Information",
  form,
  ...props}) => (
  <Form
    {...form}
    validationSchema={projectAssetSchema}
  >
    <Page
      {...props}
      title={title}
    >
      <Input name="project_id" type="hidden" className="hidden" />
      <TagSelect name="tag_ids" label="Directory" />
      <SubDirectoryFieldsContainer />
      <FileInput multiple hideLabel name='asset' isFile style={{width: "100%", height: 400}} placeholder accept='image/png, image/jpg, image/jpeg, image/gif' minSize={0} maxSize={3145728} />
      {children}
    </Page>
  </Form>
);

export default ProjectAssetsForm;
