import {useState} from 'react';
import { useNavigate  } from 'react-router-dom';
import Modal from '@components/Modal';
import Page from '@components/Layouts/Page';
import ContactList from '@components/Contact/List';
import Request from '@containers/Request';
import {useToastAddAction} from '@containers/ToastContext';
import {useLoaderDispatch} from "@containers/Loader";
import {useErrorModalDispatch} from "@containers/ErrorModal";
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {createProjectContact} from '@utils/api/contact';
import styles from './index.module.css';

export const AddExistingContactPage = ({project,placeholderTo = "/contacts/new",onSuccess = () => {}}) => {
  const [contact,setContact] = useState(),
        addToast = useToastAddAction(),
        loaderDispatch = useLoaderDispatch(),
        errorDispatch = useErrorModalDispatch(),
        navigate = useNavigate(),
        handleConfirmClick = async () => {
          loaderDispatch({type: "START_LOADING"});
          try {
            const response = await createProjectContact({project_id: project.id,contact_id: contact.id},`/projects/${project.id}/contact_projects.json`);
            if(response.error){
              loaderDispatch({type: "STOP_LOADING"});
              setContact()
              errorDispatch(response.error_description || response.error)

            } else {
              onSuccess()
              navigate("../..")
              loaderDispatch({type: "STOP_LOADING"});
              addToast(`${contact.first_name} has been added to the project`)
            }
          } catch (e) {
            loaderDispatch({type: "STOP_LOADING"});
            setContact()
            errorDispatch("An unexpected error has occured")

          }
        }
  return(<>
    <Page
      title="Add Existing Contact"
      back="Back To Contact Form"
    >
      <Request url={`/companies/${project.company_id}/contacts.json?for_project_add=true`}>
        {({contacts}) => (
          <ContactList contacts={contacts} onClick={(c) => {setContact(c)}} noLink secondary placeholderTo={placeholderTo} placeholderText="There are no contacts to add" hideInvite />
        )}
      </Request>
    </Page>
    {contact && <Modal text={`Are you sure you want to add ${contact.first_name} to this project?`} onClick={handleConfirmClick} cancel={() => setContact()} />}
  </>)
}

const AddExistingContactPageContainer = withParams(({params:{projectId}}) => (
  <Request url={`/projects/${projectId}.json`}>
    {(project) => (
      <AddExistingContactPage project={project} />
    )}
  </Request>
))

export default withPageTitle(AddExistingContactPageContainer, "Add Existing Project Contact");
