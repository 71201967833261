//import Input from '@components/Form/Fields/Input';
import AutocapitalizeInput from '@components/Form/Fields/AutocapitalizeInput';
import SplitRow from '@components/SplitRow';
import AppInfoSelect from '@containers/AppInfoSelect';
import {Controller} from 'react-hook-form';
import withField from '@components/Form/Fields/withField';
import styles from './index.module.css';

const Input = withField((props) => (
  <Controller as="input" {...props} />
))

const AddressField = ({name,values = {}}) => (
  <div>
    <AutocapitalizeInput defaultValue={values.street} name={`${name}street`} label="Address" className={styles.label} />
    <AutocapitalizeInput defaultValue={values.city} name={`${name}city`} label="City" className={styles.label} />
    <SplitRow>
      <AppInfoSelect defaultValue={values.state_id} name={`${name}state_id`} label="State" className={styles.label} resource="states" labelAccessor="abbrev" />
      <Input defaultValue={values.zipcode} name={`${name}zipcode`} label="ZIP" className={styles.label} />
    </SplitRow>
  </div>
);

export default AddressField;
