import Header from './Header';
import Subheader from './Subheader';
import Body from './Body';
import styles from './index.module.css'

const ProjectEmailMessage = ({email,backHandler}) => (
  <div className={styles.container}>
    <Header title={email.title} date={email.sent_at} backHandler={backHandler} />
    <Subheader email={email} />
    <div className={styles.bodyWrapper}>
      <div className={styles.body}>
        <Body url={email.email_url} />
      </div>
    </div>
  </div>
)

export default ProjectEmailMessage;
