import { useWatch, useFieldArray } from "react-hook-form";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Form from '@components/Form';
import Page from '@components/Layouts/Page';
import Button from '@components/Button';
import SplitRow from '@components/SplitRow';
import Label from '@components/Form/Label';
import ArrayField from '@components/Form/Fields/ArrayField';
import {ControlledSwitch} from '@components/Form/Fields/Switch';
import Input,{ControlledInput} from '@components/Form/Fields/Input';
import Select from '@components/Form/Fields/Select';
import Content from '@components/Layouts/Content';
import styles from './index.module.css';
import {formTemplateSchema} from '@utils/validation/form_template';
import {renderFieldType} from "@utils";

const MinMaxField = ({name,values,type}) => (
  <SplitRow>
    <ControlledInput type={type} name={`${name}min`} label="Min" defaultValue={values.min} />
    <ControlledInput type={type} name={`${name}max`} label="Max" defaultValue={values.max} />
  </SplitRow>
)
const DateField = (props) => <MinMaxField type="date" {...props} />
const IntegerField = (props) => <MinMaxField type="number" {...props} />

const makeFieldObject = (type) => {
  switch (type) {
    case "DateField":
      return {type,min: "", max: "", label: "", required: false}
    case "IntegerField":
      return {type,min: "", max: "", label: "", required: false}
    default:
      return {type, label: "", required: false}
  }
}
const renderFieldComponent = (name,values) => {
  switch (values.type) {
    case "DateField":
      return <DateField name={name} values={values} />
    case "IntegerField":
      return <IntegerField name={name} values={values} />
    default:
      return null
  }
}


const AddQuestionButton = ({append}) => {
  const type = useWatch({name: "field_type"});
  return(
    <Button type="button" form onClick={() => append(makeFieldObject(type || "Text"))}>Add Question</Button>
  )
}

const NAME = "form_fields_attributes";

const TemplateFields = () => {
  const { fields, append, remove, move } = useFieldArray({
    name: NAME
  });
  const handleDrag = ({ source, destination }) => {
    if (destination) {
      move(source.index, destination.index);
    }
  };
  return(
    <Content
      title="Form Template Information"
      left={<>
        <Input name="title" label="Title" placeholder="Enter Title" />
        <div>
          <Select name="field_type" options={[{value: "TextField", label: "Text"}, {value: "DateField", label: "Date"}, {value: "IntegerField", label: "Number"}, {value: "BooleanField", label: "True/False"}]} />
          <AddQuestionButton append={append} />
        </div>
      </>}
      right={<>
        <Label className={styles.label}>Questions</Label>
        <DragDropContext onDragEnd={handleDrag}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {fields && fields.map((item, i) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id+""}
                    index={i}
                  >
                    {(provided, snapshot) => {
                      const name = `${NAME}[${i}].`
                      return(
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                          <ArrayField name={name} i={i} id={item.id} dragHandleProps={provided.dragHandleProps} title={renderFieldType(item.type)} remove={remove} >
                            <ControlledInput name={`${name}type`} defaultValue={item.type} type="hidden" className="hidden" />
                            <ControlledInput name={`${name}label`} defaultValue={item.label} label="Question" placeholder="Enter Question" />
                            <ControlledSwitch name={`${name}required`} defaultValue={item.required} label="Required" />
                            {renderFieldComponent(name,item)}
                          </ArrayField>
                        </div>
                      )
                    }}
                  </Draggable>

                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </>}
    />
  )
}

const FormTemplateFormPage = ({form,page}) => (
  <Form
    {...form}
    validationSchema={formTemplateSchema}
  >
    <Page {...page}>
      <TemplateFields />
    </Page>
  </Form>
);

export default FormTemplateFormPage;
