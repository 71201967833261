import { Link } from 'react-router-dom';
import Page from '@components/Layouts/Page';
import {CalendarComponent} from '@components/ProjectEvent/Calendar';
import Button from '@components/Button';
import Request from '@containers/Request';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import withIsAuthorized from '@containers/withIsAuthorized';

export const ProjectEventListPageComponent = withIsAuthorized(({isAuthorized,url,...props}) => (
  <Page
    right={isAuthorized && <Button as={Link} to="new">Create New Event</Button>}
    {...props}
  >
    <Request url={url}>
      {({project_events}) => <CalendarComponent {...props} projectEvents={project_events} />}
    </Request>
  </Page>
),"Project Events","write")

const ProjectEventListPage = withParams(({params:{projectId}}) => (
  <ProjectEventListPageComponent
    title="Project Events"
    back="Back to Project"
    url={`/projects/${projectId}/project_events.json`}
    to
  />
))

export default withPageTitle(ProjectEventListPage, "Project Events");
