import { Link } from 'react-router-dom';
import Page from '@components/Layouts/Page';
import {Table,Row,Header} from '@components/Table';
import {Switch} from '@components/Form/Fields/Switch';
import Button from '@components/Button';
import Request from '@containers/Request';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {renderFieldType} from "@utils";

const FormTemplatePage = withParams(({params:{id}}) => (
  <Page
    title="Template"
    back="Back To Templates"
    right={<Button as={Link} to="edit">Update</Button>}
  >
    <Request url={`/form_templates/${id}.json`}>
      {({form_fields}) => (
        <Table>
          <Header>
            <div>Question</div>
            <div>Type</div>
            <div>Required</div>
            <div>Min.</div>
            <div>Max.</div>
          </Header>
          {form_fields && form_fields.map((c) => (
            <Row key={c.id}>
              <div>{c.label}</div>
              <div>{renderFieldType(c.type)}</div>
              <div><Switch checked={c.required} value={c.required} disabled /></div>
              <div>{c.min}</div>
              <div>{c.max}</div>
            </Row>
          ))}
        </Table>
      )}
    </Request>
  </Page>
))

export default withPageTitle(FormTemplatePage, "Template Details");
