import {useRef,useReducer,useEffect} from 'react';

const TOGGLE = "TOGGLE";
const OPEN = "OPEN";
const CLOSE = "CLOSE";
const SET_HEIGHT = "SET_HEIGHT";

const initialState = {maxHeight: "0px",isOpen: false};

function accordionReducer(state = initialState, {type,payload}) {
  switch (type) {
    case TOGGLE:
      return state.isOpen ? {
        maxHeight: "0px",
        isOpen: false
      } : {
        maxHeight: payload,
        isOpen: true
      }
    case CLOSE:
      return {
        maxHeight: "0px",
        isOpen: false
      }
    case OPEN:
      return {
        maxHeight: payload,
        isOpen: true
      }
    case SET_HEIGHT:
      return {
        ...state,
        maxHeight: payload
      }
    default:
      throw new Error(`Unhandled action type: ${type}`)
  }
}

const toggleAction = (payload = "0px") => ({
  type: TOGGLE,
  payload
})
const openAction = (payload = "0px") => ({
  type: OPEN,
  payload
})
const closeAction = () => ({
  type: CLOSE
})

const setHeightAction = (payload = "0px") => ({
  type: SET_HEIGHT,
  payload
})

export default function useAccordion(isOpen = false){
  const [state, stateDispatch] = useReducer(accordionReducer, {maxHeight: isOpen ? '100vh' : '0px',isOpen}),
        ref = useRef(),
        toggle = () => stateDispatch(toggleAction(ref?.current?.scrollHeight)),
        open = () => stateDispatch(openAction(ref?.current?.scrollHeight)),
        close = () => stateDispatch(closeAction()),
        setHeight = () => stateDispatch(setHeightAction(ref?.current?.scrollHeight));

  useEffect(() => {
    if(isOpen){
      open();
    }
  },[])
  return {state,ref,toggle,open,close,setHeight}
}
