import Table from '@components/Table';
import ResourceValue from '@containers/ResourceValue';
import {makeDateString} from '@utils';
import styles from './index.module.css';

const ProjectList = ({projects,hideButton,onlyName,placeholder = "There are no projects",...props}) => (
  <Table
    {...props}
    cols={onlyName ? (["Name"]) : (["Name","Type","Status","Location"])}
    rows={projects}
    to={({company_id,id}) => `/companies/${company_id}/projects/${id}`}
    renderRow={(p) => (<>
      <div>{p.name}</div>
      {!onlyName && (<>
        <div className={styles.nowrap}><ResourceValue resource="project_types" id={p.project_type_id} /></div>
        <div><ResourceValue resource="project_stages" id={p.project_stage_id} /></div>
        <div>
          <div>{p.addresses[0].street}</div>
          <div>{p.addresses[0].city}, <ResourceValue id={p.addresses[0].state_id} resource="states" valueAccessor="abbrev" /> {p.addresses[0].zipcode}</div>
        </div>
      </>)}
    </>)}
    placeholder={{to: "new", text: placeholder, hideButton}}
  />
)

export default ProjectList;
