import {ProjectContactPage} from '@components/ProjectContact/View';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {withRequestState} from '@containers/RequestContext';

const DefaultProjectContactPage = withParams(withRequestState(({params:{id},request:{state:{data}}}) => (
  <ProjectContactPage project={data} id={id} />
)))

export default withPageTitle(DefaultProjectContactPage, "Project Contact Details");
