import {useState} from 'react';
import { Link } from 'react-router-dom';
import Page from '@components/Layouts/Page';
import PermissionsModal from '@components/User/PermissionModalForm';
import Content from '@components/Layouts/Content';
import Value from '@components/Value';
import SplitRow from '@components/SplitRow';
import Button from '@components/Button';
import Request from '@containers/Request';
import InviteButton from '@containers/InviteButton';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {phoneFormat} from '@utils';

export const ContactPageComponent = ({contact,projectEmail}) => {
  const [modal,setModal] = useState();
  return(
    <Page
      title="Contact"
      back="Back To Contacts"
      right={(<>
        <Button as={Link} to="edit">Update</Button>
        {contact.invitable && <InviteButton id={contact.id} />}
        {contact.user_id && <Button onClick={() => setModal(true)}>Permissions</Button>}
      </>)}
    >
      <Content
        title="Contact Information"
      >
        <SplitRow>
          <div>
            <Value value={`${contact.first_name} ${contact.last_name}`} label="Name" />
            <Value value={contact.title?.title} label="Role" />
          </div>
          <div>
            <Value value={contact.email && <a href={`mailto:${contact.email}${projectEmail ? `?bcc=${projectEmail}` : ""}`}>{contact.email}</a>} label="Email" />
            <Value value={contact.mobile_phone} format={phoneFormat} label="Phone" />
            <Value value={contact.office_phone} format={phoneFormat} label="Office Phone" />
          </div>
        </SplitRow>
        <Value value={contact.company_id && <Link to={`/companies/${contact.company_id}`}>{contact.company_name}</Link>} label="Company" />

      </Content>
      {modal && <PermissionsModal id={contact.user_id} close={() => setModal()} /> }
    </Page>
  )
}

const ContactPage = withParams(({params:{id}}) => (
  <Request url={`/contacts/${id}.json`}>
    {(response) => (<ContactPageComponent contact={response} />)}
  </Request>
))

export default withPageTitle(ContactPage, "Contact Details");
