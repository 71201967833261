import ProjectChangeOrderFormPage from '@components/ProjectChangeOrder/Form';
import Request from '@containers/Request';
import DeleteButton from '@containers/DeleteButton';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {updateProjectChangeOrder} from '@utils/api/project_change_order';
import {convertTime12to24} from '@utils';

const ProjectChangeOrderUpdatePage = withParams(({isPotential,params:{projectId,projectChangeOrderId}}) => {
  const url = `/projects/${projectId}/project_change_orders/${projectChangeOrderId}.json`;
  return(
    <Request url={url}>
      {(response) => (
        <ProjectChangeOrderFormPage
          form={{
            url,
            defaultValues: {...response,meeting_date: new Date(response.meeting_date),start_time: convertTime12to24(response.start_time),end_time: convertTime12to24(response.end_time)},
            onSubmit: updateProjectChangeOrder,
            toast: isPotential ? "Potential change order updated" : "Project change order updated"
          }}
          page={{
            title: isPotential ? "Update Potential Change Order" : "Update Project Change Order",
            back: "Back",
            submit: isPotential ? "Update Potential Change Order" : "Update Project Change Order",
            right: <DeleteButton url={url} />
          }}
          projectId={projectId}
        />
      )}
    </Request>
  )
})

export default withPageTitle(ProjectChangeOrderUpdatePage, "Update Project Change Order");
