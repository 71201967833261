import ProjectFormPage from '@components/Project/Form';
import {withRequestState} from '@containers/RequestContext';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {updateProject} from '@utils/api/project';

const ProjectDefaultsUpdatePage = withRequestState(({request:{state:{data}}}) => (
  <ProjectFormPage
    form={{
      url: `/projects/${data.id}.json`,
      defaultValues: data,
      onSubmit: updateProject,
      toast: "Default project updated"
    }}
    page={{
      title: "Update Default Project",
      back: "Back To Default Project",
      submit: "Update Default Project"
    }}
    title="Default Project Information"
    isUpdate
  />
))

export default withPageTitle(ProjectDefaultsUpdatePage, "Update Default Project");
