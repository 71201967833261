import FormPage from '@components/Form/Page';
import Input from '@components/Form/Fields/Input';
import {projectTypeSchema} from '@utils/validation/project_type';

const ProjectTypeFormPage = ({form,...props}) => (
  <FormPage
    {...props}
    form={{
      ...form,
      validationSchema: projectTypeSchema
    }}
    title="Project Type Information"
  >
    <Input name="name" label="Name" placeholder="Enter Name" />
    <Input as="textarea" rows="6" name="description" label="Description" />
  </FormPage>
);

export default ProjectTypeFormPage;
