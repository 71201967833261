import Table from '@components/Table';

const UnitOfMeasureList = ({unitOfMeasures}) => (
  <Table
    cols={["Unit","Description","Category"]}
    rows={unitOfMeasures}
    renderRow={(p) => (<>
      <div>{p.unit}</div>
      <div>{p.description}</div>
      <div>{p.category}</div>
    </>)}
    placeholder={{to: "new", text: "There are no units of measure"}}
  />
)

export default UnitOfMeasureList;
