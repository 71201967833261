import {Table,Row,Header} from '@components/Table';
import Request from '@containers/Request';

const Permission = ({id}) => (
  <Request url={`/permission_sets/${id}.json`}>
    {({permissions}) => (
      <Table secondary>
        <Header>
          <div>Resource</div>
          <div>Access</div>
          <div>Scope</div>
        </Header>
        {permissions && permissions.map((c) => (
          <Row key={c.id}>
            <div>{c.resource}</div>
            <div>{c.access}</div>
            <div>{c.scopes}</div>
          </Row>
        ))}
      </Table>
    )}
  </Request>
)

export default Permission;
