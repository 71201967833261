import { Link } from 'react-router-dom';
import Page from '@components/Layouts/Page';
import Table from '@components/Table';
import Request from '@containers/Request';
import Button from '@components/Button';
import withPageTitle from '@containers/withPageTitle';
import {getFormattedDate} from '@utils';

const FormTemplatesPage = () => (
  <Page
    title="Form Templates"
    right={<Button as={Link} to="new">Create New Form Template</Button>}
  >
    <Request url="/form_templates.json">
      {({form_templates}) => (
        <Table
          cols={["Title","Created","Updated"]}
          rows={form_templates}
          renderRow={c => (<>
            <div>{c.title}</div>
            <div>{getFormattedDate(c.created_at)}</div>
            <div>{getFormattedDate(c.updated_at)}</div>
          </>)}
          placeholder={{to: "new", text: "There are no form templates"}}
        />
      )}
    </Request>
  </Page>
)

export default withPageTitle(FormTemplatesPage, "Form Templates");
