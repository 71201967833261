import { Link } from 'react-router-dom';
import Page from '@components/Layouts/Page';
import ProjectChangeOrderList from '@components/ProjectChangeOrder/List';
import Button from '@components/Button';
import {PaginatedTable} from '@components/Table';
import withPageTitle from '@containers/withPageTitle';
import withParams from '@containers/withParams';

export const ProjectChangeOrderListPageComponent = ({url,isPotential,...props}) => (
  <Page
    right={<Button as={Link} to="new">{isPotential ? "Create New Potential Change Order" : "Create New Project Change Order"}</Button>}

    {...props}
  >
    <PaginatedTable url={url} pageSize={25}>
      {(request) => <ProjectChangeOrderList error={request.error} status={request.status} projectChangeOrders={request.data.project_change_orders} hideButton={false} />}
    </PaginatedTable>
  </Page>
)
const ProjectChangeOrderListPage = withParams(({isPotential,params:{projectId,projectPrimeContractId}}) => (
  <ProjectChangeOrderListPageComponent
    title={isPotential ? "Potential Change Orders" : "Project Change Orders"}
    back="Back To Project"
    isPotential={isPotential}
    url={`/projects/${projectId}/project_prime_contracts/${projectPrimeContractId}/project_change_orders.json`}
  />
));

export default withPageTitle(ProjectChangeOrderListPage, "Project Change Orders");
