import { Link } from 'react-router-dom';
import Page from '@components/Layouts/Page';
import Permission from '@components/Tool/PermissionSet/Permission';
import Button from '@components/Button';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';

const PermissionSetPage = withParams(({params:{id}}) => (
  <Page
    title="Permission Set"
    back="Back To Permission Sets"
    right={<Button as={Link} to="edit">Update</Button>}
  >
    <Permission id={id} />
  </Page>
))

export default withPageTitle(PermissionSetPage, "Permission Set Details");
