import {useEffect} from 'react';
import {parse} from 'query-string';
import { useLocation,useNavigate } from 'react-router-dom';

export default function useQueryStringValidation(schema,url){
  const navigate = useNavigate(),
        {search} = useLocation(),
        query = parse(search),
        validateState = async () => {
          try {
            if(!search) throw "No query string"
            await schema.validate(query, {abortEarly: false, stripUnknown: true});
          } catch (e) {
            navigate(url)
          }
        }
  useEffect(() => {
    validateState()
  },[])
  return {query,navigate}
}
