import FormPage from '@components/Form/Page';
import Input from '@components/Form/Fields/Input';
import Select from '@components/Form/Fields/Select';
import {unitOfMeasureSchema} from '@utils/validation/unit_of_measure';

const categoryOptions = [{value: "time", label: "Time"},{value: "amount", label: "Amount"},{value: "length", label: "Length"},{value: "area", label: "Area"},{value: "volume", label: "Volume"},{value: "mass", label: "Mass"}]

const UnitOfMeasureFormPage = ({form,...props}) => (
  <FormPage
    {...props}
    form={{
      ...form,
      validationSchema: unitOfMeasureSchema
    }}
    title="Unit of Measure Information"
  >
    <Input name="unit" label="Unit" placeholder="Enter Unit" />
    <Select name="category" label="Category" placeholder="Select Category..." options={categoryOptions} />
    <Input type="textarea" name="description" label="Description" placeholder="Enter Description" />
  </FormPage>
);

export default UnitOfMeasureFormPage;
