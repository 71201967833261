import {Link} from 'react-router-dom';
import styles from './index.module.css';

const Footer = () => (
  <footer className={styles.container}>
    <div>&copy; <b>2021 Darana Hybrid</b>. All Rights Reserved</div>
    <div className={styles.links}>
      {/*
        <Link className="selectable" to="tools">Tools</Link>
        <Link className="selectable" to="cost">Cost</Link>
        <Link className="selectable" to="code">Code</Link>
        <Link className="selectable" to="directory">Directory</Link>
        <Link className="selectable" to="drawings">Drawings</Link>
        <Link className="selectable" to="specifications">Specifications</Link>
        <Link className="selectable" to="schedule">Schedule</Link>

        */}
    </div>
  </footer>
);

export default Footer;
