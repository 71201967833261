import { Outlet } from 'react-router-dom';
import {RequestLoader} from '@components/Loader';
import RequestProvider,{withRequestState} from '@containers/RequestContext';

const LayoutContent = withRequestState(({request}) => {
  return request?.state?.data ? (
    <Outlet />
  ) : (
    <RequestLoader />
  )
})

const DefaultProjectLayout = () => (
  <RequestProvider url="/projects/default.json">
    <LayoutContent />
  </RequestProvider>
)

export default DefaultProjectLayout;
