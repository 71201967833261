import { Link } from 'react-router-dom';
import Page from '@components/Layouts/CommonPage';
import Button from '@components/Button';
import Request from '@containers/Request';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import Tags from '@containers/Tags';
import withIsAuthorized from '@containers/withIsAuthorized';

const ProjectAssetPage = withParams(withIsAuthorized(({isAuthorized,params:{projectId,projectAssetId}}) => (
  <Page
    page={{
      title: "Project File",
      back: "Back To Files",
      right: isAuthorized && (<Button as={Link} to="edit">Update</Button>)
    }}
    title="Project File Information"
  >
    <Request url={`/projects/${projectId}/project_assets/${projectAssetId}.json`}>
      {({tag_ids,asset_url}) => (<>
        <Tags tags={tag_ids} label="Tags" />
        <img alt="Asset" src={asset_url} />
      </>)}
    </Request>
  </Page>
),"Project Assets","write"));

export default withPageTitle(ProjectAssetPage, "File Details");
