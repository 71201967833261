import {useEffect} from 'react';
import {useFormContext} from 'react-hook-form';

export default function DateWatcher({startDateName = "start_date",endDateName = "end_date"}){
  const {watch,setValue} = useFormContext(),
        {[startDateName]: start_date,[endDateName]: end_date} = watch([startDateName,endDateName]);
  useEffect(() => {
    if(start_date && !end_date){
      const myDate = new Date(start_date)
      myDate.setHours(myDate.getHours() + 24);
      setValue(endDateName,myDate)
    }
  },[start_date,end_date,setValue])
  return null;
}
