import {ContactListPageComponent} from '@components/Contact/ListPage';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';

const CompanyContacts = withParams(({params:{companyId}}) => (
  <ContactListPageComponent
    title="Company Contacts"
    back="Back To Company"
    url={`/companies/${companyId}/contacts.json`}
  />
))

export default withPageTitle(CompanyContacts, "Company Contacts");
