import { Link } from 'react-router-dom';
import Dropdown from '@components/Dropdown';
import DeleteButton from '@containers/DeleteButton';
import withIsAuthorized from '@containers/withIsAuthorized';
import styles from './index.module.css';

const FileMenu = withIsAuthorized(({file = {},companyId,isAuthorized,onDelete,white,isDefault,...props}) => (
  <Dropdown button={(
      <svg fill={white ? "#fff" : "#000"} height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/></svg>
    )} {...props}>
    <a className={`${styles.link} selectable`} href={file.asset_url} download>Download</a>
    {isAuthorized && <Link className={`${styles.link} selectable`} to={`${isDefault ? "/tools/defaults" : `/companies/${companyId}/projects/${file.project_id}`}/directories/${file.tags?.[0]?.id}${file.sub_tags?.[0]?.id ? `/${file.sub_tags.[0].id}` : ""}/${file.id}/edit`}>Edit</Link>}
    {isAuthorized && <DeleteButton onSuccess={onDelete} toast="Photo has been deleted" className={`${styles.link} selectable`} button="button" url={`/projects/${file.project_id}/project_assets/${file.id}.json`} to={false} />}
  </Dropdown>
),"Project Assets","write")

export default FileMenu;
