import {ProjectAssetCreatePage} from '@components/ProjectAsset/Create';
import {withRequestState} from '@containers/RequestContext';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';

const ProjectDefaultAssetCreatePage = withParams(withRequestState(({request:{state:{data}},params}) => (
  <ProjectAssetCreatePage {...params} projectId={data.id} companyId={data.company_id} projectUrl="/tools/defaults" />
)))

export default withPageTitle(ProjectDefaultAssetCreatePage, "Add Project Default File");
