import axios from 'axios';
import {createContext,useReducer,useContext,useCallback,useLayoutEffect,useState} from 'react';
import Shell from '@components/Shell';
import {getUser} from '@utils/api/auth';
import LocalStorageService from '@utils/storage';

const localStorageService = LocalStorageService.getService();

const AuthStateContext = createContext();
const AuthDispatchContext = createContext();

const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";
const SET_USER = "SET_USER";
const initialState = null;

function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
    case SET_USER:
      return {
        ...state,
        ...action.payload,
      }
    case LOGOUT:
      localStorageService.clearToken();
      return initialState;
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

export const loginAction = (payload) => ({
  type: LOGIN,
  payload
})
export const setUserAction = (payload) => ({
  type: SET_USER,
  payload
})
export const logoutAction = () => ({ type: LOGOUT })

const checkAccess = (a,b) => {
  switch (a) {
    case "write":
      return a === b;
    case "read":
      return b === "read" || b === "write";
    default:
      return false
  }
}

export const canUser = (user,resource,access) => user?.permissions?.permissions?.findIndex(p => p.resource === resource && checkAccess(access,p.access)) !== -1

export function AuthProvider({children}) {
  const [isLoading,setIsLoading] = useState(true)
  const [authState, authDispatch] = useReducer(authReducer, initialState);
  const handleStorageAuth = useCallback(async () => {
    try {
      const access_token = localStorageService.getAccessToken()
      if(access_token){
        axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
        authDispatch(setUserAction(await getUser()))
      }
    } catch (e) {
      authDispatch(logoutAction())
    } finally {
      setIsLoading()
    }
  },[authDispatch,setIsLoading])
  useLayoutEffect(() => {
    handleStorageAuth()
  },[handleStorageAuth])
  return (
    <AuthStateContext.Provider value={authState}>
      <AuthDispatchContext.Provider value={authDispatch}>
        {isLoading ? <Shell /> : children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}

export function useAuthState() {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }
  return context;
}

export function useAuthDispatch() {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  }
  return context;
};

export function withUser(WrappedComponent){
  return (props) => {
    const user = useAuthState();
    return (
      <WrappedComponent {...props} user={user} />
    )
  }
}
