import Page from '@components/Layouts/Page';
import Content from '@components/Layouts/Content';

const CommonPage = ({page,children,...props}) => (
  <Page {...page}>
    <Content {...props}>
      {children}
    </Content>
  </Page>
)
export default CommonPage;
