import { useWatch } from "react-hook-form";
import FormPage from '@components/Form/Page';
import Input from '@components/Form/Fields/Input';
import DateInput from '@components/Form/Fields/Date';
import FileInput from '@components/Form/Fields/File';
import Switch from '@components/Form/Fields/Switch';
import SplitRow from '@components/SplitRow';
import CurrencyInput from '@components/Form/Fields/CurrencyInput';
import AddressArrayField from '@components/Address/ArrayField';
import RequestSelect from '@containers/RequestSelect';
import DateWatcher from '@containers/DateWatcher';
import AppInfoSelect from '@containers/AppInfoSelect';
import {projectSchema} from '@utils/validation/project';

const NewCustomerField = () => {
  const companyId = useWatch({name: "company_id"});
  return companyId === "new" ? (
    <Input name="company_attributes[name]" label="New Customer Name" placeholder="Enter Customer Name" />
  ) : null
}

const ProjectFormPage = ({form,isUpdateForm,title = "Project Information",...props}) => (
  <FormPage
    {...props}
    form={{
      ...form,
      validationSchema: projectSchema
    }}
    title={title}
    left={<>
      <DateWatcher startDateName="expected_start_date" endDateName="expected_end_date" />
      <Input name="name" label="Name" placeholder="Enter Name" />
      <RequestSelect name="company_id" label="Customer" url="/companies.json" resource={(response) => response?.companies ? ([{id: "new",name: "Add New Customer"},...response.companies]) : [{name: "Loading..."}]} placeholder="Select Customer..." />
      <NewCustomerField />
      <Input name="customer_name" label="Company" placeholder="Enter Company" />
      <CurrencyInput name="value" label="Total Value" placeholder="Enter Value in $" />
      <DateInput name="expected_start_date" label="Start Date" closeOnScroll withPortal />
      <DateInput name="expected_end_date" label="End Date" closeOnScroll withPortal />
      {isUpdateForm && <Input type="email" name="email" label="Email" placeholder="Enter Email" />}
      <AppInfoSelect name="project_stage_id" label="Status" resource="project_stages" placeholder="Select Status..." />
      <AppInfoSelect name="project_type_id" label="Type" resource="project_types" placeholder="Select Type..." />
      <Input name="project_number" label="Project #" />
      {isUpdateForm && <Input type="number" name="sqft" label="Sq Feet" />}
      <Input as="textarea" rows="6" name="description" label="Description" />
    </>}
    right={<>
      <Switch name="active" label="Active" />
      <FileInput name='logo' width={200} height={70} accept='image/png, image/jpg, image/jpeg, image/gif' minSize={0} maxSize={3145728} />
      <AddressArrayField name='addresses' />
      {/*<FileInput name='photo' width={200} height={70} accept='image/png, image/jpg, image/jpeg, image/gif' minSize={0} maxSize={3145728} large />*/}
    </>}
  />
);

export default ProjectFormPage;
