import {Link} from 'react-router-dom';
import Button from '@components/Button';
import makeBackState from '@containers/makeBackState';
import styles from './index.module.css';

export default function ViewMoreLink({to,button,newTo = to,back,hideButton}){
  const backState = makeBackState(back)
  return(
    <div className={styles.container}>
      <Link to={to} state={backState} className={styles.link}>View All</Link>
      {!hideButton && (button ? button : <Button small as={Link} state={backState} to={`${newTo}/new`}>Add</Button>)}
    </div>
  )
}
