import {Link} from 'react-router-dom';
import Page from '@components/Layouts/CommonPage';
import {Switch} from '@components/Form/Fields/Switch';
import Value from '@components/Value';
import Button from '@components/Button';
import SplitRow from '@components/SplitRow';
import {ContactSection} from '@components/Contact/Section';
import Request from '@containers/Request';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {canUser,withUser} from '@containers/Auth';
import {getFormattedDate} from '@utils';


const ProjectBudgetItemPage = withUser(withParams(({user,params:{projectId,projectBudgetItemId}}) => (
  <Request url={`/projects/${projectId}/project_budget_items/${projectBudgetItemId}.json`}>
    {(response) => (
      <Page
        page={{
          title: "Project Budget Item",
          back: "Back To Project Budget Items",
          right: /*canUser(user,"Project Budget Items","write") && */(<Button as={Link} to="edit">Update</Button>)
        }}
        title="Project Budget Item Information"
      >
        <SplitRow>
          <Value value={response.meeting_num} label="Meeting #" />
          <Value value={response.name} label="Meeting Name" />
        </SplitRow>
        <Value value={response.video_conference_link} label="Video Conferencing Link" />
        <Value value={<Switch checked={response.private} value={response.private} disabled />} label="Private Meeting" />

        <SplitRow>
          <Value value={response.meeting_date} format={getFormattedDate} label="Meeting Date" />
          <Value value={response.time_zone} label="Time Zone" />
        </SplitRow>
        <SplitRow>
          <Value value={response.start_time} label="Start Time" />
          <Value value={response.end_time} label="End Time" />
        </SplitRow>
        <Value value={response.meeting_location} label="Meeting Location" />
        <Value value={response.overview} label="Overview" />
        <ContactSection contacts={response.contacts} hideButton />
      </Page>
    )}
  </Request>
)))

export default withPageTitle(ProjectBudgetItemPage, "Project Budget Item Details");
