import { Link } from 'react-router-dom';
import Page from '@components/Layouts/Page';
import ContactList from '@components/Contact/List';
import Button from '@components/Button';
import Pagination from "@components/Pagination";
import withPageTitle from '@containers/withPageTitle';
import withIsAuthorized from '@containers/withIsAuthorized';

export const ContactListPageComponent = withIsAuthorized(({url,isAuthorized,...props}) => (
  <Page
    right={isAuthorized && <Button as={Link} to="new">Create New Contact</Button>}
    {...props}
  >
    <Pagination url={url} pageSize={10}>
      {(request) => <ContactList contacts={request.data.contacts} />}
    </Pagination>
  </Page>
),"Contacts","write")

const ContactListPage = () => (
  <ContactListPageComponent
    title="Contacts"
    url="/contacts.json"
  />
)

export default withPageTitle(ContactListPage, "Contacts");
