import {useCallback,useEffect,useReducer} from 'react';
import {makeRequest as requestFunc} from '@utils/api';

const REQUEST = "REQUEST";
const REQUEST__SUCCESS = "REQUEST__SUCCESS";
const REQUEST__FAILURE = "REQUEST__FAILURE";

const initialState = {status: "loading", data: {page: 1}, error: null,query: null};
export function requestReducer(state = initialState, {type,payload}) {
  switch (type) {
    case REQUEST:
      return {
        ...state,
        query: payload,
        error: null,
        status: "loading"
      };
    case REQUEST__SUCCESS:
      return {
        ...state,
        status: "success",
        data: payload,
        error: null
      }
    case REQUEST__FAILURE:
      return {
        ...initialState,
        status: "error",
        error: payload || "An unexpected error has occurred"
      }
    default:
      throw new Error(`Unhandled action type: ${type}`)
  }
}

const stringifyQuery = (obj) => {
  if(!obj) return ""
  var qs = ""
  for (var key in obj) {
    if (obj[key]) {
      qs += `&${key}=${obj[key]}`
    } else if (obj[key] === null){
      qs += `&${key}=`
    }
  }
  return qs
}


export default function usePaginationRequest(url,pageSize = 50,initialQuery,action = "GET",blockInitialRequest,defaultQuery){
  const [state, dispatch] = useReducer(requestReducer, initialState),
        makeRequest = async (page = 1,query,page_size) => {
          dispatch({type: REQUEST, payload: query})
          try {
            dispatch({type: REQUEST__SUCCESS, payload: await requestFunc(action,`${url}?page_size=${page_size || pageSize}&page=${page}${stringifyQuery(defaultQuery? {...defaultQuery,...query} : query)}`)})
          } catch (e) {
            dispatch({type: REQUEST__FAILURE, payload: "An unexpected error has occurred"})
          }
        },
        setPage = (page) => {
          makeRequest(page,state.query);
        },
        nextPage = () => {
          setPage(state.data.page+1)
        },
        previousPage = () => {
          setPage(state.data.page-1)
        },
        setQuery = (query = {},page_size) => {
          makeRequest(1,query,page_size)
        }
  useEffect(() => {
    if(!blockInitialRequest){
      makeRequest(1, initialQuery);
    }
  },[])
  return {state,makeRequest,setQuery,previousPage,nextPage,setPage};
}
