import ImageComponent from '@components/Image';
import FileMenu from '@components/ProjectAsset/Directory/File/Menu';
import {getFileNameFromUrl,isFileImage,classNames,getFileType} from '@utils';
import styles from './index.module.css';
import fileIcon from '@assets/images/insert_drive_file_white-24px.svg';
import blackFileIcon from '@assets/images/insert_drive_file-24px.svg';
import pdfIcon from '@assets/images/PDF_file_icon.svg';
import docxIcon from '@assets/images/docx_icon.svg';
import xlsxIcon from '@assets/images/xlsx_icon.svg';

export const getIconFile = (url,black) => {
  const type = getFileType(url);
  switch (type) {
    case "pdf":
      return pdfIcon
    case "docx":
      return docxIcon
    case "xlsx":
    case "xls":
      return xlsxIcon
    default:
      if(black){
        return blackFileIcon
      } else {
        return fileIcon
      }

  }
}

export const Card = ({className,children,as: WrapperComponent = "div",...props}) => (
  <WrapperComponent {...props} className={classNames(styles.container,className)}>
    {children}
  </WrapperComponent>
)
const ProjectAssetCard = ({values = {},companyId,onDelete,onClick,isDefault,...props}) => {
  const fileName = getFileNameFromUrl(values.asset_url),
        isImage = isFileImage(fileName);
  return(
    <Card {...props} title={fileName}>
      <ImageComponent onClick={onClick} src={isImage ? values.asset_url : getIconFile(values.asset_url)} alt="Project Asset" className={styles.image} imgClassName={isImage ? undefined : styles.icon}>
        {!isImage && <div className={styles.text}>{fileName}</div>}
      </ImageComponent>
      {isImage && (
        <div className={styles.banner}>
          <div className={styles.fileName}>{fileName}</div>
        </div>
      )}
      <FileMenu file={values} isDefault={isDefault} companyId={companyId} white onDelete={onDelete} buttonClassName={styles.menu} wrapperClassName={styles.menuWrapper} />
    </Card>
  )
}

export default ProjectAssetCard;
