import TagFormPage from '@components/Tool/Tag/Form';
import Request from '@containers/Request';
import DeleteButton from '@containers/DeleteButton';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {useAppInfoRequest} from '@containers/AppInfo';
import {updateTag} from '@utils/api/tag';

const TagUpdatePage = withParams(({params:{id}}) => {
  const url = `/tags/${id}.json`,
        onSuccess = useAppInfoRequest();
  return(
    <Request url={url}>
      {(defaultValues) => (
        <TagFormPage
          form={{
            url,
            defaultValues,
            onSubmit: updateTag,
            toast: "Directory updated",
            to: "../..",
            onSuccess: () => {onSuccess()}
          }}
          page={{
            title: "Update Directory",
            back: {to: '../..',text:"Back To Directories"},
            submit: "Update Directory",
            right: <DeleteButton url={url} onSuccess={onSuccess} />
          }}
        />
      )}
    </Request>
  )
})

export default withPageTitle(TagUpdatePage, "Update Tag");
