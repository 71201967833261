import {createContext,useContext} from 'react';

import {useRequest} from '@containers/Request';
import {RequestLoader} from '@components/Loader';

const RequestStateContext = createContext();

export function useRequestState() {
  const context = useContext(RequestStateContext);
  if (context === undefined) {
    throw new Error('useRequestState must be used within a RequestProvider');
  }
  return context;
}

export function withRequestState(WrappedComponent){
  return (props) => {
    const request = useRequestState()
    return (
      <WrappedComponent {...props} request={request} />
    )
  }
}


export default function RequestProvider({children,url}) {
  const request = useRequest(url);
  if(request.state.status === "loading"){
    return <RequestLoader />
  }
  if(request.state.status === "error"){
    return <div>{request.error}</div>
  }
  return (
    <RequestStateContext.Provider value={request}>
      {children}
    </RequestStateContext.Provider>
  );
};
