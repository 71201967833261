import {useWatch} from 'react-hook-form';
import ProjectFormValueField from '@components/ProjectForm/ValueField';
import ValueFieldArray from '@components/ProjectForm/ValueFieldArray';
import Input from '@components/Form/Fields/Input';
import Select from '@components/Form/Fields/Select';
import FormPage from '@components/Form/Page';
import Request from '@containers/Request';
import {projectFormSchema,updateFormSchema} from '@utils/validation/project_form';


const FormBuilder = ({templates}) => {
  const template = useWatch({name: "form_template_id"});
  const formIdx = templates.findIndex(t => t.id == template);
  return formIdx > -1 ? (
    <Request url={`/form_templates/${templates[formIdx].id}.json`}>
      {({form_fields}) => form_fields.map(({id,...f},i) => <ProjectFormValueField key={f.id} field={{form_field_id: id,...f}} root={`form_values_attributes[${i}].`} />)}
    </Request>
  ) : (null)
}

const ProjectFormsFormPageWrapper = ({form,page,validationSchema,children}) => (
  <FormPage
    form={{
      ...form,
      validationSchema
    }}
    page={page}
    title="Form Information"
  >
    <Input type="hidden" name="project_id" className="hidden" />
    {children}
  </FormPage>
)

export const UpdateProjectFormsFormPage = (p) => (
  <ProjectFormsFormPageWrapper {...p} validationSchema={updateFormSchema}>
    <ValueFieldArray name="form_values_attributes" />
  </ProjectFormsFormPageWrapper>
)

const ProjectFormsFormPage = (p) => (
  <ProjectFormsFormPageWrapper {...p} validationSchema={projectFormSchema}>
    <Request url="/form_templates.json">
      {({form_templates}) => (<>
        <Select options={form_templates ? form_templates.map(s => ({value: s.id, label: s.title})) : []} name="form_template_id" label="Form" placeholder="Select Form Template" />
        <FormBuilder templates={form_templates} />
      </>)}
    </Request>
  </ProjectFormsFormPageWrapper>
)

export default ProjectFormsFormPage;
