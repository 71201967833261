import {ProjectDocumentsPage} from '@components/ProjectAsset/Directory';
import {withRequestState} from '@containers/RequestContext';
import withPageTitle from '@containers/withPageTitle';
import withParams from '@containers/withParams';

const ProjectDefaultDocumentsPageContainer = withParams(withRequestState(({params,request:{state:{data}}}) => (
  <ProjectDocumentsPage {...params} companyId={data.company_id} projectId={data.id} projectUrl="/tools/defaults" isAuthorized isDefault />
)))

export default withPageTitle(ProjectDefaultDocumentsPageContainer, "Default Project Photo Directories");
