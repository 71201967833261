import {useAppInfoState} from '@containers/AppInfo';
import useAccessorFunction from '@containers/useAccessorFunction'

export default function ResourceValue({id,resource,idAccessor = "id",valueAccessor = "name",...props}){
  const info = useAppInfoState(),
        json = resource ? info?.[resource] : info,
        valueFunc = useAccessorFunction(valueAccessor),
        idFunc = useAccessorFunction(idAccessor);
  return valueFunc(json?.find(t => idFunc(t) == id)) || null;
}
