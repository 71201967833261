import { Link } from 'react-router-dom';
import Page from '@components/Layouts/Page';
import ProjectBudgetItemList from '@components/ProjectBudgetItem/List';
import Button from '@components/Button';
import {PaginatedTable} from '@components/Table';
import withPageTitle from '@containers/withPageTitle';
import withParams from '@containers/withParams';

export const ProjectBudgetItemListPageComponent = ({url,...props}) => (
  <Page
    right={<Button as={Link} to="new">Create New Budget Item</Button>}

    {...props}
  >
    <PaginatedTable url={url} pageSize={25}>
      {(request) => <ProjectBudgetItemList error={request.error} status={request.status} projectBudgetItems={request.data?.project_budget_items} hideButton={false} />}
    </PaginatedTable>
  </Page>
)

const ProjectBudgetItemListPage = withParams(({params:{projectId,projectPrimeContractId}}) => (
  <ProjectBudgetItemListPageComponent
    title="Project Budget Items"
    back="Back To Project Prime Contract"
    url={`/projects/${projectId}/project_prime_contracts/${projectPrimeContractId}/project_budget_items.json`}
  />
));

export default withPageTitle(ProjectBudgetItemListPage, "Schedule of Values");
