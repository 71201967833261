import {StatefulTable} from '@components/Table';
import ResourceValue from '@containers/ResourceValue'
import {cashFormat} from '@utils';

const costCodeValueAccessor = ({abbrev,cost_type}) => `${abbrev} - ${cost_type}`

const ProjectBudgetItemList = ({projectBudgetItems,hideButton,...props}) => (
  <StatefulTable
    {...props}
    cols={["Total","Cost Code","Unit Cost","Units","Unit Of Measure"]}
    rows={projectBudgetItems}
    renderRow={(p) => (<>
      <div>{cashFormat(p.unit_cost * p.units)}</div>
      <div><ResourceValue id={p.cost_code_id} resource="cost_codes" valueAccessor={costCodeValueAccessor} /></div>
      <div>{cashFormat(p.unit_cost)}</div>
      <div>{p.units}</div>
      <div><ResourceValue id={p.unit_of_measure_id} resource="unit_of_measures" valueAccessor="unit" /></div>
    </>)}
    placeholder={{to: "new", text: "There are no project budget items", hideButton}}
  />
)

export default ProjectBudgetItemList;
