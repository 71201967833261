import FormPage from '@components/Form/Page';
import Input from '@components/Form/Fields/Input';
import DateInput from '@components/Form/Fields/Date';
import Switch from '@components/Form/Fields/Switch';
import SplitRow from '@components/SplitRow';
import RequestSelect from '@containers/RequestSelect';
import DateWatcher from '@containers/DateWatcher';
import {projectEventSchema} from '@utils/validation/project_event';

const ProjectEventFormPage = ({form,projectId,eventId,...props}) => (
  <FormPage
    {...props}
    form={{
      ...form,
      validationSchema: projectEventSchema
    }}
    title="Project Event Information"
  >
    <DateWatcher />
    <Input name="project_id" className="hidden" />
    <Input name="name" label="Name" placeholder="Enter Name" />
    <SplitRow>
      <DateInput name="start_date" label="Start Date" closeOnScroll withPortal />
      <DateInput name="end_date" label="End Date" closeOnScroll withPortal />
    </SplitRow>
    <SplitRow>
      <Switch name="active" label="Status" />
      <Switch name="completed" label="Completed" />
    </SplitRow>
    <Input as="textarea" rows="6" name="description" label="Description" />
    <RequestSelect name="dependent_on" label="Dependency" resource={eventId ? (events) => events?.project_events?.filter(({id}) => id != eventId) : "project_events"} url={`/projects/${projectId}/project_events.json`} placeholder="Select Dependencies..." />
  </FormPage>
);

export default ProjectEventFormPage;
