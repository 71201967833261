import {useState,useEffect } from 'react';
import {createPortal} from 'react-dom';
import Button from '@components/Button';
import styles from './index.module.css';
import {classNames} from '@utils';
import {ReactComponent as CloseIcon} from '@assets/images/close-24px.svg';

const modalRoot = document.getElementById("modal");
export function Modal({close,className,children}){
  const [isShowing,setIsShowing] = useState();
  const el = document.createElement("div");
  useEffect(() => setIsShowing(true), []);
  useEffect(() => {
    modalRoot.appendChild(el);
    return () => {
      modalRoot.removeChild(el);
    };
  }, [el]);
  return createPortal(
    <div className={classNames(styles.container,isShowing && styles.show)}>
      <div className={classNames(styles.content,isShowing && styles.show,className)}>
        {close && <CloseIcon alt="Close" onClick={close} className={styles.closeIcon + " selectable"}  />}
        {children}
      </div>
    </div>,
    el
  )
}

export default function ModalComponent({text,onClick,cancel,children,...props}){
  return(
    <Modal {...props}>
      <div className={styles.text}>{text}</div>
      {children ? (
        children
      ) : (
        cancel ? (
          <div className={styles.buttonRow}>
            <Button type="button" onClick={cancel}>Cancel</Button>
            <Button type="button" onClick={onClick}>Continue</Button>
          </div>
        ) : (
          <Button type="button" onClick={onClick}>OK</Button>
        )
      )}
    </Modal>
  )
}
