import Table from '@components/Table';

const ProjectStageList = ({projectStages}) => (
  <Table
    cols={["Name"]}
    rows={projectStages}
    renderRow={(p) => (<>
      <div>{p.name}</div>
    </>)}
    placeholder={{to: "new", text: "There are no project stages"}}
  />
)

export default ProjectStageList;
