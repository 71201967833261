import {RequestSection} from '@components/Section';
import ProjectFormList from '@components/ProjectForm/List';
import ViewMoreLink from '@components/ViewMoreLink';
import EmptyResourcePlaceholder from '@components/EmptyResourcePlaceholder';

const ProjectFormSection = ({projectId,linkRoot = "project_forms/",back = "Back To Project", title = "Forms", collapsible = true, pageSize = 10, hideButton,...props}) => (
  <RequestSection title={title} url={`/projects/${projectId}/project_forms.json`} pageSize={pageSize} section collapsible={collapsible} {...props}>
    {({project_forms}) => (
      project_forms?.length > 0 ? (<>
        <ProjectFormList projectForms={project_forms} linkRoot={linkRoot} secondary />
        <ViewMoreLink to="project_forms" hideButton={hideButton} back={back} />
      </>) : (
        <EmptyResourcePlaceholder to="project_forms/new" text="This project has no forms" hideButton={hideButton} back={back} />
      )
    )}
  </RequestSection>
)

export default ProjectFormSection
