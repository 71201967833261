import {string, object, ref} from 'yup';

export const loginSchema = object().shape({
  email: string().email('Invalid email').required('Email is required'),
  password: string().required('Password is required')
})

export const passwordSchema = object().shape({
  password: string().required('Password is required'),
  password_confirmation: string().required('Passwords must match').oneOf([ref('password'), null], 'Passwords must match')
})

export const forgotPasswordSchema = object().shape({
  email: string().email('Invalid email').required('Email is required')
})

export const invitePageValidation = object().shape({
  invitation_token: string().required('Invitation token is required')
})


export const updatePasswordPageValidation = object().shape({
  reset_password_token: string().required('Password token is required')
})
