import ContactFormPage from '@components/ProjectContact/Form';
import Request from '@containers/Request';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {createProjectContact as onSubmit} from '@utils/api/contact';

export const ProjectContactCreatePage = ({project,onSuccess = () => {}}) => (
  <ContactFormPage
    form={{
      url: `/projects/${project.id}/contact_projects.json`,
      defaultValues: {project_id: project.id, contact_attributes: {company_id: project.company_id}},
      onSubmit,
      onSuccess,
      toast: "Contact added to project"
    }}
    page={{
      title: "Create Project Contact",
      back: "Back To Project",
      submit: "Create New Contact"
    }}
    projectId={project.id}
    create
  />
)

const ProjectContactCreatePageContainer = withParams(({params:{projectId}}) => (
  <Request url={`/projects/${projectId}.json`}>
    {(project) => (
      <ProjectContactCreatePage project={project} />
    )}
  </Request>
))

export default withPageTitle(ProjectContactCreatePageContainer, "Create Project Contact");
