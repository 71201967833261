import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import DatePicker from 'react-datepicker';
import withField from '@components/Form/Fields/withField';
import styles from './index.module.css';

export const DateInputComponent = ({onChange, placeholderText = "MM-DD-YYYY", value = null,...props}) => {
  return (
    <div className={styles.container}>
      <DatePicker
        selected={value}
        onChange={onChange}
        placeholderText={placeholderText}
        {...props}
      />
    </div>
  );
}

const DateInputWithState = withField(props => <Controller {...props} autoComplete="off" onChange={([e]) => e} as={DateInputComponent} />)

const DateInput = (props) => <DateInputWithState onClick={(e) => e.preventDefault()} {...props} />

export default DateInput;
