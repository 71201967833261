import FormTemplateFormPage from '@components/FormTemplate/Form';
import withPageTitle from '@containers/withPageTitle';
import {createFormTemplate} from '@utils/api/form_template';

const FormTemplateCreatePage = () => (
  <FormTemplateFormPage
    form={{
      url: "/form_templates.json",
      defaultValues: {field_type: "TextField"},
      onSubmit: createFormTemplate
    }}
    page={{
      title: "Create Form Template",
      back: "Back To Form Templates",
      submit: "Create Form Template"
    }}
  />
)

export default withPageTitle(FormTemplateCreatePage, "Create Form Template");
