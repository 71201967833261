import styles from './index.module.css';
import {classNames} from '@utils';

const Hamburger = ({isOpen,className,...props}) => (
  <button {...props} className={classNames(styles.container,isOpen && styles.active,className)} type="button">
    <span className={styles.box}>
      <span className={styles.inner}></span>
    </span>
  </button>
)

export default Hamburger;
