import Table from '@components/Table';
import ResourceValue from '@containers/ResourceValue'

const Addresses = ({addresses,...props}) => (
  <Table
    {...props}
    cols={["Address","City","State","ZIP"]}
    rows={addresses}
    renderRow={address => (<>
      <div>{address.street}</div>
      <div>{address.city}</div>
      <div><ResourceValue id={address.state_id} resource="states" valueAccessor="abbrev" /></div>
      <div>{address.zipcode}</div>
    </>)}
    placeholder={{to: "new", text: "There are no addresses",hideButton: true}}
    noLink
  />
)

export default Addresses
