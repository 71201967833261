import {ContactPageComponent} from '@components/Contact/View';
import Request from '@containers/Request';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';

export const ProjectContactPage = ({project,id}) => (
  <Request url={`/projects/${project.id}/contact_projects/${id}.json`}>
    {(response) => (<ContactPageComponent contact={response.contact} projectEmail={project.email} />)}
  </Request>
)

const ProjectContactPageContainer = withParams(({params:{id,projectId}}) => (
  <Request url={`/projects/${projectId}.json`}>
    {(project) => (
      <ProjectContactPage project={project} id={id} />
    )}
  </Request>
))

export default withPageTitle(ProjectContactPageContainer, "Project Contact Details");
