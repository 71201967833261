import ContactFormPage from '@components/Contact/Form';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {createContact,createProjectContact} from '@utils/api/contact';

const ContactCreatePage = withParams(({params:{companyId,projectId}}) => {
  var url = "/contacts.json",
      defaultValues = {},
      onSubmit = createContact,
      back = "Back To Contacts";
  if(companyId){
    url = `/companies/${companyId}/contacts.json`
    defaultValues = {company_id: companyId}
    back = "Back To Contacts"
  } else if(projectId) {
    url = `/projects/${projectId}/contact_projects.json`;
    defaultValues = {project_id: projectId}
    onSubmit = createProjectContact
    back = "Back To Project"
  }
  return(
    <ContactFormPage
      form={{
        url,
        defaultValues,
        onSubmit,
        toast: "Contact added"
      }}
      page={{
        title: "Create Contact",
        back,
        submit: "Create New Contact"
      }}
      hasCompanyId={companyId}
      hasProjectId={projectId}
      create
    />
  )
})

export default withPageTitle(ContactCreatePage, "Create Contact");
