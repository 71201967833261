import {Controller} from 'react-hook-form';
import MaskedInput from "react-input-mask";
import withField from '@components/Form/Fields/withField';

const PhoneInput = ({ onChange,...props }) => (
  <MaskedInput
    {...props}
    mask="(999) 999-9999"
    onChange={e => {
      e.persist();
      onChange(e.target.value.replace(/[^0-9]/g, ""));
    }}
  />
);

export default withField((props) => <Controller name={props.name} as={PhoneInput} {...props} />)
