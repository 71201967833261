import ReactSelect from '@components/Form/Fields/ReactSelect';
import AppInfoSelect from '@containers/AppInfoSelect';
import {sortTags} from '@utils';

const TagSelect = ({
  name = "tag_ids",
  label = "Directory",
  placeholder = "Select Directory...",
  as = ReactSelect,
  labelAccessor = "tag",
  resource = (response) => response?.tags ? response.tags.sort(sortTags) : [],
  ...props
}) => (
  <AppInfoSelect {...props} name={name} labelAccessor={labelAccessor} as={as} resource={resource} label={label} placeholder={placeholder} />
)

export default TagSelect;
