import {Link} from 'react-router-dom';
import Page from '@components/Layouts/Page';
import Button from '@components/Button';
import TitleList from '@components/Tool/Title/List';
import {useAppInfoState} from '@containers/AppInfo';
import withPageTitle from '@containers/withPageTitle';

export const TitleListPageComponent = ({url,...props}) => {
  const info = useAppInfoState();
  return(
    <Page
      {...props}
      right={<Button as={Link} to="new">Add Title</Button>}
    >
      <TitleList titles={info?.titles} />
    </Page>
  )
}

const TitleListPage = () => (
  <TitleListPageComponent
    url="/titles.json"
    title="Titles"
  />
)

export default withPageTitle(TitleListPage, "Titles");
