import {useState,useRef,useEffect} from 'react';
import Section,{useSectionRequestHandler} from '@components/Section';
import ProjectList from '@components/Project/List';
import {ProjectFilters} from '@components/Project/ListPage';
import ViewMoreLink from '@components/ViewMoreLink';
import {Pagination} from '@components/Pagination';
import EmptyResourcePlaceholder from '@components/EmptyResourcePlaceholder';
import AppInfoSelect from '@containers/AppInfoSelect'
import usePaginationRequest from '@containers/usePaginationRequest';
import styles from './index.module.css';

const ProjectSectionComponent = ({pagination,accordion,back,hideButton,initialQuery}) => {
  useSectionRequestHandler(accordion.state.isOpen,pagination.state.status,accordion.setHeight,pagination.makeRequest,initialQuery)
  return(<>
    <ProjectFilters setQuery={pagination.setQuery} className={styles.filters} />
    <Pagination previousPage={pagination.previousPage} nextPage={pagination.nextPage} setPage={pagination.setPage} pageCount={pagination.state.data.page_count} page={pagination.state.data.page} status={pagination.state.status} error={pagination.state.error}>
      {pagination?.state?.data?.projects?.length > 0 ? (<>
        <ProjectList projects={pagination.state.data.projects} secondary />
        <ViewMoreLink to="projects" hideButton={hideButton} back={back} />
      </>) : (
        <EmptyResourcePlaceholder to="projects/new" back={back} text="There were no projects found with a matching criteria" hideButton={hideButton} />
      )}
    </Pagination>
  </>)
}

const ProjectSection = ({companyId,hideButton,back = "Back To Company",pageSize = 10,initialQuery,...props}) => {
  const pagination = usePaginationRequest(`/companies/${companyId}/projects.json`,pageSize);
  return(
    <Section {...props} url={`/companies/${companyId}/projects.json`} title="Projects">
      {(accordion) => (
        <ProjectSectionComponent back={back} hideButton={hideButton} pagination={pagination} accordion={accordion} initialQuery={initialQuery} />
      )}
    </Section>
  )
}

export default ProjectSection
