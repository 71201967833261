import FormPage from '@components/Form/Page';
import Input from '@components/Form/Fields/Input';
import RequestSelect from '@containers/RequestSelect';
import {userSchema} from '@utils/validation/user';


const UserFormPage = ({form,hasCompanyId,create,hasProjectId,page}) => (
  <FormPage
    form={{validationSchema: userSchema, ...form}}
    page={page}
    title="User Information"
  >
    <Input type="email" name="email" label="Email" placeholder="Enter Email" />
    <RequestSelect name="permission_set_id" label="Permissions" url="/permission_sets.json" placeholder="Select Permission Set..." />
  </FormPage>
)

export default UserFormPage;
