import {Link} from 'react-router-dom';
import Page from '@components/Layouts/CommonPage';
import Value from '@components/Value';
import Button from '@components/Button';
import {Switch} from '@components/Form/Fields/Switch';
import SplitRow from '@components/SplitRow';
import Request from '@containers/Request';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';

const TagPage = withParams(({params:{id}}) => (
  <Request url={`/tags/${id}.json`}>
    {(response) => (
      <Page
        page={{
          title: "Directory",
          back: "Back To Directories",
          right: (<Button as={Link} to="edit">Update</Button>)
        }}
        title="Directory Information"
      >
        <SplitRow>
          <Value value={response.unit} label="Unit" />
          <Value value={response.category} label="Category" />
        </SplitRow>
        <Value value={response.description} label="Description" />
      </Page>
    )}
  </Request>
))

export default withPageTitle(TagPage, "Directory Details");
