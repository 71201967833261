import {RequestSection} from '@components/Section';
import ProjectPrimeContractList from '@components/ProjectPrimeContract/List';
import ViewMoreLink from '@components/ViewMoreLink';
import EmptyResourcePlaceholder from '@components/EmptyResourcePlaceholder';

const ProjectPrimeContractSection = ({projectId,hideButton,linkRoot = "contracts/",back = "Back To Project", title = "Prime Contracts", collapsible = true, pageSize = 10, ...props}) => (
  <RequestSection title={title} collapsible={collapsible} url={`/projects/${projectId}/project_prime_contracts.json`} pageSize={pageSize} section {...props}>
    {({project_prime_contracts}) => (
      project_prime_contracts?.length > 0 ? (<>
        <ProjectPrimeContractList projectPrimeContracts={project_prime_contracts} secondary linkRoot={linkRoot} back={back} />
        <ViewMoreLink to="contracts" hideButton={hideButton} />
      </>) : (
        <EmptyResourcePlaceholder to="contracts/new" text="This project has no contracts" hideButton={hideButton} back={back} />
      )
    )}
  </RequestSection>
)

export default ProjectPrimeContractSection
