import {Link} from 'react-router-dom';
import Table from '@components/Table';
import InviteButton from '@containers/InviteButton';
import withIsAuthorized from '@containers/withIsAuthorized';
import {phoneFormat} from '@utils';
import styles from './index.module.css';

const stopPropagation = (e) => {
  e.stopPropagation();
}
const ContactsListPage = withIsAuthorized(({contacts,isAuthorized,noLink,placeholderTo = "new",hideButton = true, placeholderText = "There are no contacts",hideInvite,projectEmail,...props}) => (
  <Table
    {...props}
    fixed
    noLink={noLink || !isAuthorized}
    cols={(!isAuthorized || hideInvite) ? ["Name","Email","Phone","Office Phone","Company"] : ["Name","Email","Phone","Office Phone","Company", ""]}
    rows={contacts}
    renderRow={(p) => (<>
      <div>{`${p.contact.first_name} ${p.contact.last_name}`}</div>
      <div><a onClick={stopPropagation} href={`mailto:${p.contact.email}${projectEmail ? `?bcc=${projectEmail}` : ""}`}>{p.contact.email}</a></div>
      <div>{phoneFormat(p.contact.mobile_phone)}</div>
      <div>{phoneFormat(p.contact.office_phone)}</div>
      <div><Link to={`/companies/${p.contact.company_id}`}>{p.contact.company_name}</Link></div>
      {(isAuthorized && !hideInvite) && <div className={styles.center}>{p.contact.invitable && <InviteButton small id={p.contact.id} />}</div>}
    </>)}
    placeholder={{to: placeholderTo, text: placeholderText,hideButton}}
  />
),"Contacts","write")

export default ContactsListPage;
