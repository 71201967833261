import {string, object,array} from 'yup';
import {addressSchema} from '@utils/validation/address';

export const companySchema = object().shape({
  name: string().required("Name can't be blank"),
  //office_phone: string().required("Office phone can't be blank"),
  //email: string().email('Invalid email').required("Email can't be blank"),
  addresses: array().of(addressSchema).required("Requires at least address"),
  website: string().matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            'Website is not a valid URL'
        )
});
