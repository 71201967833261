import {Link} from 'react-router-dom';
import Form from '@components/Form';
import Button from '@components/Button';
import styles from './index.module.css';
import logo from '@assets/images/Darana_Hybrid_Electro_Mechanical_GRN_BLK.png';

export const PublicForm = ({buttonText = "Save",children,link,...props}) => (
  <Form {...props}>
    <PublicHeader />
    {children}
    <Button className={styles.button} type="submit">{buttonText}</Button>
    {link && (
      <Link to={link.to} className={styles.link}>{link.text}</Link>
    )}
  </Form>
)

export const PublicHeader = ({title,children}) => (
  <header className={styles.header}>
    <img src={logo} alt="Darana Hybrid Small Logo" className={styles.logo} />
    <div className={styles.title}>{title}</div>
    {children}
  </header>
)
const PublicLayout = ({children}) => (
  <div className={styles.container}>
    <div className={styles.content}>
      {children}
    </div>
  </div>
)

export default PublicLayout;
