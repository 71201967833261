import ProjectBudgetItemFormPage from '@components/ProjectBudgetItem/Form';
import Request from '@containers/Request';
import DeleteButton from '@containers/DeleteButton';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {updateProjectBudgetItem} from '@utils/api/project_budget_item';
import {convertTime12to24} from '@utils';

const ProjectBudgetItemUpdatePage = withParams(({params:{projectId,projectPrimeContractId,projectBudgetItemId}}) => {
  const url = `/projects/${projectId}/project_prime_contracts/${projectPrimeContractId}/project_budget_items/${projectBudgetItemId}.json`;
  return(
    <Request url={url}>
      {(defaultValues) => (
        <ProjectBudgetItemFormPage
          form={{
            url,
            defaultValues,
            onSubmit: updateProjectBudgetItem,
            toast: "Project budget item updated"
          }}
          page={{
            title: "Update Project Budget Item",
            back: "Back To Project Budget Items",
            submit: "Update Project Budget Item",
            right: <DeleteButton url={url} />
          }}
        />
      )}
    </Request>
  )
})

export default withPageTitle(ProjectBudgetItemUpdatePage, "Update Project Budget Item");
