import {Link} from 'react-router-dom';
import Page from '@components/Layouts/Page';
import Button from '@components/Button';
import ProjectEmailList from '@components/ProjectEmail/List';
import Request from '@containers/Request';
import withPageTitle from '@containers/withPageTitle';
import withParams from '@containers/withParams';

export const ProjectEmailListPageComponent = ({url,...props}) => (
  <Page {...props}>
    <Request url={url}>
      {({project_emails}) => <ProjectEmailList emails={project_emails} />}
    </Request>
  </Page>
)

const ProjectEmailListPage = withParams(({params:{projectId}}) => (
  <ProjectEmailListPageComponent
    url={`/projects/${projectId}/project_emails.json`}
    title="Project Emails"
  />
))

export default withPageTitle(ProjectEmailListPage, "Project Emails");
