import {makeRequest} from '@utils/api';

const makeAddress = ({state_id,...address}) => ({
  state_id: Number(state_id),
  ...address
})

const makeCompany = ({addresses,...data}) => ({
  addresses_attributes: addresses.map(makeAddress),
  ...data
})

export const createCompany = (data, url) => makeRequest("POST", url, {
  company: makeCompany(data)
})

export const updateCompany = (data, url) => makeRequest("PATCH", url, {
  company: makeCompany(data)
})
