import { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import Label from '@components/Form/Label';
import FileDrop from '@components/Form/Fields/FileDrop';
import Button from '@components/Button';
import styles from './index.module.css';

const FileInput = ({ name, label = name, hideLabel, width, height, multiple, maxSize, minSize, ...props }) => {
  const {
    setValue,
    watch,
    register,
    unregister,
    setError
  } = useFormContext()
  const file = watch(name)
  const onDrop = useCallback(
    (droppedFiles) => {
      if(droppedFiles && droppedFiles.length){
        setValue(name, droppedFiles)
        if(!multiple){
          const [file] = droppedFiles;
          const image = new Image();
          image.src = URL.createObjectURL(file)
        }

      }

    },
    [setValue, name,multiple/*, setError, maxSize, width, height*/],
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop
  })
  useEffect(() => {
    register(name)
    return () => {
      unregister(name)
    }
  }, [register, unregister, name]);
  return (
    <div className={styles.container}>
      {!hideLabel && (
        <div className={styles.label}>
          <Label>{label}</Label>
          <div className={styles.buttonWrapper}>
            <Button as="label" htmlFor={name} className={styles.button} form>Upload</Button>
            {/*{file && <Button red form className={styles.deleteButton} onClick={() => {setValue(name,null)}}>Delete</Button>}*/}
          </div>
        </div>
      )}
      <FileDrop {...props} form onDrop={onDrop} file={file} width={width} height={height} multiple={multiple} name={name} className={isDragActive ? styles.active : undefined} {...getRootProps()} input={getInputProps()} />
    </div>
  )
}

export default FileInput
