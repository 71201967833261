import {RequestSection} from '@components/Section';
import ProjectChangeOrderList from '@components/ProjectChangeOrder/List';
import ViewMoreLink from '@components/ViewMoreLink';
import EmptyResourcePlaceholder from '@components/EmptyResourcePlaceholder';

const ProjectChangeOrderSection = ({
  projectId,
  projectPrimeContractId,
  hideButton,
  back = "Back To Project Prime Contract",
  title = "Change Orders",
  pageSize = 10,
  collapsible = true,
  potentials,
  to,
  ...props
}) => (
  <RequestSection title={potentials ? "Potential Change Orders" : title} url={`/projects/${projectId}/project_prime_contracts/${projectPrimeContractId}/project_change_orders.json`} collapsible={collapsible} pageSize={pageSize} initialQuery={potentials ? {potentials} : {potentials: false}} {...props}>
    {({project_change_orders}) => (
      project_change_orders?.length > 0 ? (<>
        <ProjectChangeOrderList projectChangeOrders={project_change_orders} secondary linkRoot={potentials ? "change-orders/" : "potential-change-orders/"} />
        <ViewMoreLink to={potentials ? "potential-change-orders" : "change-orders"} hideButton={hideButton} back={potentials ? "Back To Project Prime Contract" : back} />
      </>) : (
        <EmptyResourcePlaceholder to={potentials ? "potential-change-orders/new" : "change-orders/new"} text={potentials ? "This project has no potential change orders" : "This project has no change orders"} hideButton={hideButton} back={potentials ? "Back To Project Prime Contract" : back} />
      )
    )}
  </RequestSection>
)

export default ProjectChangeOrderSection
