import FormPage from '@components/Form/Page';
import Input from '@components/Form/Fields/Input';
import PhoneNumberInput from '@components/Form/Fields/PhoneNumberInput';
import AddressArrayField from '@components/Address/ArrayField';
import {companySchema} from '@utils/validation/company';

const CompanyFormPage = ({form,page}) => (
  <FormPage
    form={{validationSchema: companySchema, ...form}}
    page={page}
    title="Company Information"
    left={<>
      <Input name="name" label="Name" placeholder="Enter Name" />
      {/*<Input name="dba_name" label="DBA" placeholder="Enter DBA" />
      <Input name="short_name" label="Short Name" placeholder="Enter Short Name" />*/}
      <Input name="website" label="Website" placeholder="Enter Website" />
      {/*<PhoneNumberInput name="office_phone" label="Office Phone" placeholder="Enter Office Phone" />
      <PhoneNumberInput name="fax_phone" label="Fax Phone" placeholder="Enter Fax Phone" />
      <Input type="email" name="email" label="Email" placeholder="Enter Email" />*/}
    </>}
    right={<AddressArrayField name="addresses" />}
  />
);

export default CompanyFormPage;
