import {useState,useRef,useEffect} from 'react';
import { Link } from 'react-router-dom';
import Page from '@components/Layouts/Page';
import ProjectList from '@components/Project/List';
import {SelectComponent} from '@components/Form/Fields/Select';
import Button from '@components/Button';
import {Pagination} from '@components/Pagination';
import usePaginationRequest from '@containers/usePaginationRequest';
import AppInfoSelect from '@containers/AppInfoSelect';
import {canUser,withUser} from '@containers/Auth';
import withPageTitle from '@containers/withPageTitle';
import styles from './index.module.css';
import {classNames} from '@utils';

const Section = ({title,filters,children}) => (
  <div className={styles.section}>
    <div className={styles.sectionHeader}>
      <div>{title}</div>
      {filters}
    </div>
    {children}
  </div>
)

const activeFilterOptions = [{value: false,label: "Active"},{value: true,label: "Inactive"}]
export const ProjectFilters = ({setQuery,initialState = false,initialActiveState = false,className}) => {
  const [stageId,setStageId] = useState(initialState),
        [inactive,setInactive] = useState(false),
        mounted = useRef();

  useEffect(() => {
    if(mounted.current){
      setQuery(stageId ? {stage_id: stageId,inactive: inactive === "true"} : {inactive: inactive === "true"})
    }
    mounted.current = true
  },[stageId,inactive]);
  return(
    <div className={classNames(styles.filters,className)} onClick={(e) => {e.stopPropagation()}}>
      <AppInfoSelect as={SelectComponent} value={stageId} onChange={(e) => {setStageId(e.target.value)}} resource="project_stages" placeholder="All Project Statuses" />
      <SelectComponent value={inactive} onChange={(e) => {setInactive(e.target.value)}} options={activeFilterOptions} />
    </div>
  )
}

const AllProjectsSection = ({pageSize = 25,url}) => {
  const {setQuery,previousPage,nextPage,setPage,state} = usePaginationRequest(url,pageSize);
  return(
    <Section title="All Projects" filters={<ProjectFilters setQuery={setQuery} className={styles.sectionFilter} />}>
      <Pagination previousPage={previousPage} nextPage={nextPage} setPage={setPage} pageCount={state.data.page_count} page={state.data.page} status={state.status} error={state.error}>
        <ProjectList status="success" projects={state?.data?.projects} placeholder="There were no projects found matching the critera" />
      </Pagination>
    </Section>
  )
}
export const ProjectListPageComponent = withUser(({url,user,...props}) => (
  <Page
    right={canUser(user,"Projects","write") && <Button as={Link} to="new">Create New Project</Button>}
    {...props}
  >
    {user.recents?.projects?.length > 0 && (
      <Section title="Recent Projects">
        <ProjectList status="success" projects={user.recents?.projects} hideButton onlyName />
      </Section>
    )}
    <AllProjectsSection url={url} />
  </Page>
))
const ProjectListPage = () => (
  <ProjectListPageComponent
    title="Projects"
    url="/projects.json"
  />
);

export default withPageTitle(ProjectListPage, "Projects");
