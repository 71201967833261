import {useState,useEffect,useCallback} from 'react';
import parse from 'html-react-parser';
import EmlParser from 'eml-parser';
import {RequestLoader} from '@components/Loader';
import ErrorDisplay from '@components/ErrorDisplay';

export default function ProjectEmailBody({url}){
  const [htmlString,setHtml] = useState(),
        [error,setError] = useState(),
        getAndRenderEmail = useCallback(async () => {
          try {
            setHtml()
            const response = await fetch(url),
                  emlString = await response.text(),
                  {html,attachments} = await new EmlParser(emlString).parseEml();
            setHtml(html)
          } catch (e) {
            setError()
          }
        },[url])
  useEffect(() => {
    getAndRenderEmail()
  },[getAndRenderEmail])

  if(error){
    return <ErrorDisplay error="Unable to load email file" />
  }

  if(!htmlString){
    return <RequestLoader />
  }

  return parse(htmlString)
}
