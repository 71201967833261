import Section,{RequestSection} from '@components/Section';
import ViewMoreLink from '@components/ViewMoreLink';
import EmptyResourcePlaceholder from '@components/EmptyResourcePlaceholder';
import Contacts from '@components/Contact/List';

const ContactSectionContent = ({contacts,hideButton,linkRoot = "contacts/",back,hideViewMore, placeholder = "There are no contacts"}) => contacts?.length > 0 ? (<>
  <Contacts contacts={contacts} linkRoot={linkRoot} secondary noLink={hideButton} />
  {!hideViewMore && <ViewMoreLink to="contacts" text={placeholder} hideButton={hideButton} back={back} />}
</>) : (
  <EmptyResourcePlaceholder to="contacts/new" text={placeholder} hideButton={hideButton} back={back} />
)


export const ContactSection = ({collapsible = true, title = "Contacts",...props}) => (
  <Section title={title} collapsible={collapsible}>
    <ContactSectionContent {...props} />
  </Section>
)


const ContactRequestSection = ({url, collapsible = true, title = "Contacts",...props}) => (
  <RequestSection url={url} title={title} collapsible={collapsible}>
    {({contacts}) => <ContactSectionContent {...props} contacts={contacts} />}
  </RequestSection>
)



export default ContactRequestSection;
