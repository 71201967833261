import EmptyResourcePlaceholder from '@components/EmptyResourcePlaceholder';
import Slider from '@components/Slider';
import ViewMoreLink from '@components/ViewMoreLink';
import ProjectAssetCard from '@components/ProjectAsset/Card';
import {withIsMobile} from '@containers/ScreenWidth';
import {RequestSection} from '@components/Section';
import Request from '@containers/Request';
import Slideshow from '@components/ProjectAsset/Slideshow';
import styles from './index.module.css';

export const SliderComponent = withIsMobile(({
  projectAssets,
  companyId,
  isMobile,
  updateData,
  makeRequest,
  hideButton,
  to,
  backState = "Back To Project",
  placeholder = "This project has no files",
  isDefault
}) => projectAssets?.length > 0 ? (<>
  <Slideshow projectAssets={projectAssets} updateData={updateData}>
    {openSlideshow => (
      <Slider className={styles.container} visibleCount={isMobile ? 2 : 6}>
        {projectAssets?.map((c,idx) => (
          <ProjectAssetCard key={c.id} values={c} isDefault={isDefault} companyId={companyId} onDelete={makeRequest} isProjectPage onClick={() => {openSlideshow(idx)}} />
        ))}
      </Slider>
    )}
  </Slideshow>
  <ViewMoreLink to={to} hideButton={hideButton} back={backState} />
</>) : (
  <EmptyResourcePlaceholder to={`${to}/new`} back={backState} text={placeholder} hideButton={hideButton} />
),1024)


const ProjectAssetSlider = ({
  projectId,
  companyId,
  pageSize = 15,
  tagIds,
  title,
  collapsible,
  ...props
}) => (
  <RequestSection title={title} url={`/projects/${projectId}/project_assets.json?page_size=${pageSize}${tagIds ? `&tag_ids=${tagIds}` : ""}`} collapsible={collapsible}>
    {({project_assets},makeRequest,updateData) => (
      <SliderComponent projectAssets={project_assets} companyId={companyId} makeRequest={makeRequest} updateData={updateData} {...props} />
    )}
  </RequestSection>
)

export default ProjectAssetSlider;
