import ContactFormPage from '@components/Contact/Form';
import Request from '@containers/Request';
import DeleteButton from '@containers/DeleteButton';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {updateContact} from '@utils/api/contact';

const makeContactDefaultValues = ({title,...response}) => title?.id ? ({
  title_id: title.id,
  ...response
}) : response

const ContactUpdatePage = withParams(({params:{id}}) => {
  const url = `/contacts/${id}.json`;
  return(
    <Request url={url}>
      {(response) => (
        <ContactFormPage
          form={{
            url,
            defaultValues: makeContactDefaultValues(response),
            onSubmit: updateContact,
            toast: "Contact updated"
          }}
          page={{
            title: "Update Contact",
            back: "Back To Contact",
            submit: "Update Contact",
            right: <DeleteButton url={url} />
          }}
          hasCompanyId={response.company_id}
        />
      )}
    </Request>
  );
});

export default withPageTitle(ContactUpdatePage, "Update Contact");
