import FormPage from '@components/Form/Page';
import Input from '@components/Form/Fields/Input';
import Select from '@components/Form/Fields/Select';
import DateInput from '@components/Form/Fields/Date';
import NumberInput from '@components/Form/Fields/CurrencyInput';
import Switch from '@components/Form/Fields/Switch';
import SplitRow from '@components/SplitRow';
import Section from '@components/Section';
import RequestSelect from '@containers/RequestSelect';
import AppInfoSelect from '@containers/AppInfoSelect';
import DateWatcher from '@containers/DateWatcher';
import {projectPrimeContractSchema} from '@utils/validation/project_prime_contract';
import {makeStatusOptions} from '@utils';

const popperModifiers = {
  preventOverflow: {
    enabled: true,
  },
}
const ProjectPrimeContractFormPage = ({form,projectId,...props}) => (
  <FormPage
    {...props}
    form={{
      ...form,
      validationSchema: projectPrimeContractSchema
    }}
    title="Project Prime Contract Information"
  >
    <DateWatcher startDateName="start_date" endDateName="estimated_completion_date" />
    <Input name="project_id" type="hidden" className="hidden" />
    <SplitRow>
      <Input name="contract_num" label="Contract #" placeholder="Enter Contract #" required />
      <Input name="title" label="Title" placeholder="Enter Title" />
    </SplitRow>
    <SplitRow>
      <RequestSelect name="owner_company_id" label="Owner/Client" url="/companies.json" resource={(response) => response?.companies ? response.companies : [{name: "Loading..."}]} placeholder="Enter Owner/Client" required />
      <RequestSelect name="contractor_company_id" label="Contractor" url="/companies.json" resource={(response) => response?.companies ? response.companies : [{name: "Loading..."}]} placeholder="Enter Contractor" required />
    </SplitRow>
    <SplitRow>
      <NumberInput name="value" label="Value" />
      <AppInfoSelect name="status" label="Status" resource={makeStatusOptions("project_prime_contract_statuses")} placeholder="Select Status..." />
      {/*<Switch name="was_executed" label="Executed" />*/}
    </SplitRow>
    <Switch name="private_contract" label="Private" />
    <NumberInput name="default_retainage" label="Retainage" prefix="" suffix="%" fixedDecimalScale={0} isAllowed={(r) => r?.value <= 100} />
    <Input as="textarea" rows={6} name="description" label="Description" placeholder="Enter Description" />
    <Section title="Contract Dates" initialIsOpen>
      <SplitRow>
        <DateInput name="start_date" label="Start Date" closeOnScroll withPortal />
        <DateInput name="estimated_completion_date" label="Estimated Completion Date" closeOnScroll withPortal />
      </SplitRow>
      <SplitRow>
        <DateInput name="actual_completion_date" label="Actual Completion Date" closeOnScroll withPortal />
        <DateInput name="signed_contract_received_date" label="Signed Contract Recieved Date" closeOnScroll withPortal />
      </SplitRow>
      <SplitRow>
        <DateInput name="contract_terminated_date" label="Contract Termination Date" closeOnScroll withPortal />
      </SplitRow>
    </Section>
    <Section title="Additional Information" initialIsOpen>
      <Input as="textarea" row={4} name="inclusions" label="Inclusions" placeholder="Enter Inclusions" />
      <Input as="textarea" row={4} name="exclusions" label="Exclusions" placeholder="Enter Exclusions" />
    </Section>
  </FormPage>
);

export default ProjectPrimeContractFormPage;
