import ProjectMeetingFormPage from '@components/ProjectMeeting/Form';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {createProjectMeeting} from '@utils/api/project_meeting';

const ProjectMeetingCreatePage = withParams(({params:{projectId}}) => (
  <ProjectMeetingFormPage
    form={{
      url: `/projects/${projectId}/project_meetings.json`,
      defaultValues: {project_id: projectId},
      onSubmit: createProjectMeeting,
      toast: "Project meeting added"
    }}
    page={{
      title: "Create Project Meeting",
      back: "Back To Project Meetings",
      submit: "Create New Project Meeting"
    }}
    projectId={projectId}
  />
))

export default withPageTitle(ProjectMeetingCreatePage, "Create Project Meeting");
