import { useFieldArray } from "react-hook-form";
import AddressField from '@components/Address/Field';
import ArrayField from '@components/Form/Fields/ArrayField';
import Button from '@components/Button';
import Label from '@components/Form/Label';
import styles from './index.module.css';

export default function AddressArrayField({name}) {
  const { fields, append, remove } = useFieldArray({
    name
  });
  return (<>
    <Label className={styles.label}>Addresses</Label>
    {fields && fields.map((item,i) => (
      <ArrayField key={item.id} i={i} remove={remove} name={`${name}[${i}].`} id={item.id}>
        <AddressField values={item} name={`${name}[${i}].`} />
      </ArrayField>
    ))}
    <Button className={styles.addBtn} type="button" form onClick={() => append({street: '',city: '',state_id: '',zipcode:''})}>Add Address</Button>
  </>)
}
