import {Link} from 'react-router-dom';
import Page from '@components/Layouts/CommonPage';
import {Switch} from '@components/Form/Fields/Switch';
import Value from '@components/Value';
import Button from '@components/Button';
import SplitRow from '@components/SplitRow';
import ProjectAssetSection from '@components/ProjectAsset/Section';
import ProjectEventSection from '@components/ProjectEvent/Section';
import {ProjectAssetListSection} from '@components/ProjectAsset/Section';
import AddressSection from '@components/Address/Section';
import EmailSection from '@components/ProjectEmail/Section';
import FormSection from '@components/ProjectForm/Section';
import ProjectMeetingSection from '@components/ProjectMeeting/Section';
import ProjectPrimeContractSection from '@components/ProjectPrimeContract/Section';
import ContactSection from '@components/ProjectContact/Section';
import {withRequestState} from '@containers/RequestContext';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import ResourceValue from '@containers/ResourceValue'
import {canUser,withUser} from '@containers/Auth';
import {cashFormat,getBirthdate} from '@utils';
import styles from './index.module.css';

export const ProjectPage = ({
  page,
  title = "Project Information",
  user,
  showEditButton,
  project,
  children
}) => (
    <Page
      page={{
        ...page,
        right: showEditButton && (<Button as={Link} to="edit">Update</Button>)
      }}
      title={title}
    >
      <SplitRow>
        <Value value={project.name} label="Project Name" />
        <SplitRow>
          {/*<Value value={project.value} format={cashFormat} label="Total Value" />*/}
          <Value value={<Switch checked={project.active} value={project.active} disabled />} label="Active" />
        </SplitRow>
      </SplitRow>
      <SplitRow>
        <Value value={project.expected_start_date} format={getBirthdate} label="Start Date" />
        <Value value={project.expected_end_date} format={getBirthdate} label="End Date" />
      </SplitRow>
      <SplitRow>
        <Value label="Status" value={<ResourceValue id={project.project_stage_id} resource="project_stages" />} />
        <Value label="Type" value={<ResourceValue id={project.project_type_id} resource="project_types" />} />
      </SplitRow>
      <Value value={project.sqft} label="Sq Feet" />
      <SplitRow className={styles.alignTop}>
        <div>
          <Value value={project.company && <Link to={`/companies/${project.company.id}`}>{project.company.name}</Link>} label="Customer" />
          <Value value={project.customer_name} label="Company" />
          <Value value={project.project_number} label="Project #" />
          <Value value={project.email && <a href={`mailto:${project.email}`}>{project.email}</a>} label="Email" />
        </div>
        <Value value={project.logo_url && <img alt="Project logo" src={project.logo_url} className={styles.image} />} label="Logo" secondary />
      </SplitRow>
      <Value value={project.description} label="Description" />
      {children}
    </Page>
)


const ProjectPageContainer = withUser(withParams(withRequestState(({params:{projectId,companyId},user,request:{state:{data},makeRequest}}) => {
  const canUserWriteProjects = canUser(user,"Projects","write"),
        canUserWriteContacts = canUser(user,"Contacts","write"),
        canUserWriteAssets = canUser(user,"Project Assets","write"),
        canUserWriteEvents = canUser(user,"Project Events","write"),
        canUserWriteForms = canUser(user,"Project Forms","write"),
        canUserReadContacts = canUser(user,"Contacts","read"),
        canUserReadAssets = canUser(user,"Project Assets","read"),
        canUserReadEvents = canUser(user,"Project Events","read"),
        canUserReadForms = canUser(user,"Project Forms","read");

  return(
    <ProjectPage
      page={{
        title: "Project Information",
        back: "Back To Projects",
      }}
      showEditButton={canUserWriteProjects}
      title="Project Information"
      project={data}
    >
      {canUserReadAssets && <ProjectAssetSection companyId={companyId} projectId={projectId} hideButton={!canUserWriteAssets} />}
      {canUserReadContacts && <ContactSection contacts={data.contact_projects} projectEmail={data.email} projectId={projectId} hideButton={!canUserWriteContacts} makeRequest={makeRequest} />}
      <AddressSection addresses={data.addresses} />
      {canUserReadAssets && <ProjectAssetSection title="Photos" tagIds={21} placeholder="This project has no photos" projectId={projectId} companyId={companyId} hideButton={!canUserWriteAssets} />}
      {canUserReadAssets && <ProjectAssetListSection tagIds={2} title="Drawings" placeholder="This project has no drawing files" projectId={projectId} companyId={companyId} hideButton={!canUserWriteAssets} onDelete={makeRequest} />}
      <EmailSection />
      {canUserReadForms && <FormSection projectId={projectId} hideButton={!canUserWriteForms} />}
      <ProjectMeetingSection projectId={projectId} />
      <ProjectPrimeContractSection projectId={projectId} />
      {canUserReadEvents && <ProjectEventSection projectId={projectId} hideButton={!canUserWriteEvents} />}
      {canUserReadAssets && <ProjectAssetListSection tagIds={3} title="Specifications" placeholder="This project has no specification files" projectId={projectId} companyId={companyId} hideButton={!canUserWriteAssets} onDelete={makeRequest} />}
    </ProjectPage>
  )
})))

export default withPageTitle(ProjectPageContainer, "Project Details");
