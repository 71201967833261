import {makeRequest} from '@utils/api';
import LocalStorageService from '@utils/storage';

const localStorageService = LocalStorageService.getService();

export const getUser = () => makeRequest("GET", "/current_user.json")

export const login = (data) => new Promise(async (resolve,reject) => {
  try {
    const token = await makeRequest("POST", "/oauth/token.json",{
      grant_type: "password",
      ...data
    })
    localStorageService.setToken(token)
    resolve(await getUser())
  } catch (e) {
    reject(e)
  }
})

export const acceptInvitation = (user) => makeRequest("PUT", "/users/invitation.json",{
  user
})

export const updatePassword = (user) => makeRequest("PUT", "/users/password.json",{
  user
})

export const forgotPassword = (user) => makeRequest("POST", "/users/password.json",{
  user
})
