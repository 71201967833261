import {UpdateProjectFormsFormPage} from '@components/ProjectForm/Form';
import Request from '@containers/Request';
import DeleteButton from '@containers/DeleteButton';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {updateProjectForm} from '@utils/api/project_form';

const ProjectFormUpdatePage = withParams(({params:{projectId,projectFormId}}) => {
  const url = `/projects/${projectId}/project_forms/${projectFormId}.json`
  return(
    <Request url={url}>
      {({form_values,...response}) => (
        <UpdateProjectFormsFormPage
          form={{
            url,
            defaultValues: {
              ...response,
              form_values_attributes: form_values.map(({form_field,...f}) => ({...f, value: form_field.type === "BooleanField" ? f.value === "1" : f.value, form_field_id: form_field.id,type: form_field.type, required: form_field.required, min: form_field.min, max: form_field.max}))
            },
            onSubmit: updateProjectForm,
            toast: "Updated project form"
          }}
          page={{
            title: "Update Project Form",
            back: "Back To Form",
            submit: "Update Project Form",
            right: <DeleteButton url={url} />
          }}
        />
      )}
    </Request>
  );
})

export default withPageTitle(ProjectFormUpdatePage, "Update Project Form");
