import {useState} from 'react';
import { useNavigate  } from 'react-router-dom';
import Modal from '@components/Modal';
import Button from '@components/Button';
import {useToastAddAction} from '@containers/ToastContext';
import {useLoaderDispatch} from "@containers/Loader";
import {useErrorModalDispatch} from "@containers/ErrorModal";

const ButtonComponent = ({children,...props}) => (
  <Button {...props} type="button">{children}</Button>
)

export default function RequestButton({id,makeRequest,toast,to,confirmationModal = true,onSuccess = () => {},modalText = "Are you sure?",children, button: WrapperComponent = ButtonComponent,...props}) {
  const loaderDispatch = useLoaderDispatch(),
        errorDispatch = useErrorModalDispatch(),
        addToast = useToastAddAction(),
        navigate = useNavigate(),
        [modal,setModal] = useState(),
        handleConfirmClick = async (e) => {
          e.preventDefault();
          loaderDispatch({type: "START_LOADING"});
          try {
            const response = await makeRequest();
            loaderDispatch({type: "STOP_LOADING"});
            setModal();
            if(toast){
              addToast(toast)
            }
            if(to){
              navigate(to)
            }
            onSuccess(response)
          } catch (e) {
            loaderDispatch({type: "STOP_LOADING"});
            errorDispatch("An unexpected error has occured")
            setModal()
          }
        },
        handleButtonClick = (e) => {
          e.preventDefault();
          e.stopPropagation();
          if(confirmationModal){
            setModal(true);
          } else {
            handleConfirmClick(e)
          }
        }

  return (<>
    <WrapperComponent {...props} onClick={handleButtonClick}>{children}</WrapperComponent>
    {modal && <Modal text={modalText} onClick={handleConfirmClick} cancel={(e) => {setModal();e.preventDefault();}} />}
  </>);
};
