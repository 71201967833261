import ProjectAssetFormPage from '@components/ProjectAsset/Form';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {createProjectAsset} from '@utils/api/project_asset';

const makeParamValues = (project_id,tag_ids,sub_tag_ids) => ({project_id,tag_ids: tag_ids ? tag_ids : undefined,sub_tag_ids: sub_tag_ids ? sub_tag_ids : undefined})

export const ProjectAssetCreatePage = ({projectId,companyId,tagId,subTagId,projectUrl}) => (
  <ProjectAssetFormPage
    form={{
      url: `/projects/${projectId}/project_assets.json`,
      defaultValues: makeParamValues(projectId,tagId,subTagId),
      onSubmit: createProjectAsset,
      toast: "Project file added",
      to: (navigate,response) => {navigate(`${projectUrl}/directories/${response.tags[0].id}${response.sub_tags?.[0]?.id ? `/${response.sub_tags[0].id}` : ""}`)}
    }}
    page={{
      title: "Create Project File",
      back: "Back To Directory",
      submit: "Save"
    }}
  />
)

const ProjectAssetCreatePageContainer = withParams(({params}) => (
  <ProjectAssetCreatePage {...params} projectUrl={`/companies/${params.companyId}/projects/${params.projectId}`} />
))

export default withPageTitle(ProjectAssetCreatePageContainer, "Add Project File");
