import ProjectTypeFormPage from '@components/Tool/Type/Form';
import withPageTitle from '@containers/withPageTitle';
import {createProjectType} from '@utils/api/project_type';

const ProjectTypeCreatePage = () => (
  <ProjectTypeFormPage
    form={{
      url: "/project_types.json",
      onSubmit: createProjectType,
      toast: "Project type added"
    }}
    page={{
      title: "Create Project Type",
      back: "Back To Project Types",
      submit: "Create New Project Type"
    }}
  />
)

export default withPageTitle(ProjectTypeCreatePage, "Create Project Type");
