import {PublicForm} from '@components/Layouts/Public';
import Input from '@components/Form/Fields/Input';
import withQueryStringValidation from '@containers/withQueryStringValidation';
import {passwordSchema,invitePageValidation} from '@utils/validation/auth';
import {acceptInvitation} from '@utils/api/auth';

const InvitationConfirmation = ({query}) => (
  <PublicForm buttonText="Set Password" to="/login" toast="Your password has been set, you may now login" onSubmit={acceptInvitation} defaultValues={{invitation_token: query?.invitation_token}} validationSchema={passwordSchema}>
    <Input name="invitation_token" className="hidden" />
    <Input type="password" name="password" secondary label="Password" />
    <Input type="password" name="password_confirmation" secondary label="Confirm Password" />
  </PublicForm>
)

export default withQueryStringValidation(InvitationConfirmation, invitePageValidation, "/login");
