import {makeRequest} from '@utils/api';

export const createContact = (contact, url) => makeRequest("POST",url,{
  contact
})

export const updateContact = (contact, url) => makeRequest("PATCH",url,{
  contact
})

export const sendInvitation = (id) => makeRequest("POST", `/contacts/${id}/invite.json`)

export const createProjectContact = ({project_id,contact_id,title_id,...contact_attributes},url) => makeRequest("POST",url,{
  contact_project: {
    ...(contact_id ? {contact_id} : contact_attributes),
    project_id,
    title_id
  }
})
