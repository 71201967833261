import Select from '@components/Form/Fields/Select';
import useAccessorFunction from '@containers/useAccessorFunction'

const ResourceSelect = ({as: Component = Select,resource,labelAccessor = "name",valueAccessor = "id",data,placeholder = true,...props}) => {
  const jsonFunc = useAccessorFunction(resource),
        labelFunc = useAccessorFunction(labelAccessor),
        valueFunc = useAccessorFunction(valueAccessor),
        json = jsonFunc(data);
  return(
    <Component {...props} placeholder={placeholder} options={json ? json.map(s => ({value: valueFunc(s), label: labelFunc(s)})) : [{name: "Loading..."}]} />
  )
}

export default ResourceSelect;
