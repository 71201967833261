import ProjectMeetingFormPage from '@components/ProjectMeeting/Form';
import Request from '@containers/Request';
import DeleteButton from '@containers/DeleteButton';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {updateProjectMeeting} from '@utils/api/project_meeting';
import {convertTime12to24} from '@utils';

const ProjectMeetingUpdatePage = withParams(({params:{projectId,projectMeetingId}}) => {
  const url = `/projects/${projectId}/project_meetings/${projectMeetingId}.json`;
  return(
    <Request url={url}>
      {(response) => (
        <ProjectMeetingFormPage
          form={{
            url,
            defaultValues: {...response,contact_ids: response.contacts.map(({id}) => id), meeting_date: new Date(response.meeting_date),start_time: convertTime12to24(response.start_time),end_time: convertTime12to24(response.end_time)},
            onSubmit: updateProjectMeeting,
            toast: "Project meeting updated"
          }}
          page={{
            title: "Update Project Meeting",
            back: "Back To Project Meeting",
            submit: "Update Project Meeting",
            right: <DeleteButton url={url} />
          }}
          projectId={projectId}
        />
      )}
    </Request>
  )
})

export default withPageTitle(ProjectMeetingUpdatePage, "Update Project Meeting");
