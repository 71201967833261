import {ProjectPage} from '@components/Project/View';
import ContactSection from '@components/ProjectContact/Section';
import ProjectAssetSection from '@components/ProjectAsset/Section';
import {withRequestState} from '@containers/RequestContext';
import withPageTitle from '@containers/withPageTitle';

const ProjectDefaultPage = withRequestState(({request:{makeRequest,state:{data}}}) => (
  <ProjectPage
    page={{
      title: "Default Project"
    }}
    title="Default Project Information"
    showEditButton
    project={data}
    makeRequest={makeRequest}
  >
    <ContactSection contacts={data.contact_projects} projectEmail={data.email} projectId={data.id} makeRequest={makeRequest} />
    <ProjectAssetSection companyId={data.company_id} projectId={data.id} isDefault />
  </ProjectPage>
))

export default withPageTitle(ProjectDefaultPage, "Default Project Details");
