import {Link} from 'react-router-dom';
import Page from '@components/Layouts/Page';
import Button from '@components/Button';
import ProjectTypeList from '@components/Tool/Type/List';
import Request from '@containers/Request';
import withPageTitle from '@containers/withPageTitle';

export const ProjectTypeListPageComponent = ({url,...props}) => (
  <Page
    {...props}
    right={<Button as={Link} to="new">Add Project Type</Button>}
  >
    <Request url={url}>
      {(project_types) => <ProjectTypeList projectTypes={project_types} />}
    </Request>
  </Page>
)

const ProjectTypeListPage = () => (
  <ProjectTypeListPageComponent
    url="/project_types.json"
    title="Project Types"
  />
)

export default withPageTitle(ProjectTypeListPage, "Project Types");
