import { Link } from 'react-router-dom';
import Page from '@components/Layouts/Page';
import Table from '@components/Table';
import Button from '@components/Button';
import Request from '@containers/Request';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';


const ProjectFormList = ({projectForms,...props}) => (
  <Table
    {...props}
    cols={["Form"]}
    rows={projectForms}
    renderRow={(p) => (
      <div>{p.title}</div>
    )}
    placeholder={{to: "new", text: "This project has no forms"}}
  />
)

export default ProjectFormList;
