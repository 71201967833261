import { useFieldArray } from "react-hook-form";
import ProjectFormValueField from '@components/ProjectForm/ValueField';

export default function ValueFieldArray({name = "form_values_attributes"}) {
  const { fields } = useFieldArray({
    name,
    keyName: "_id"
  });
  return fields.map((f,i) => <ProjectFormValueField key={f.id} field={f} root={`${name}[${i}].`} />)
}
