import {useCallback,useEffect,useReducer} from 'react';
import {RequestLoader} from '@components/Loader';
import ErrorDisplay from '@components/ErrorDisplay';
import {useAuthDispatch, logoutAction} from '@containers/Auth';
import {getRequest} from '@utils/api';
import {TokenError} from '@utils/api/error';

const REQUEST = "REQUEST";
const REQUEST__SUCCESS = "REQUEST__SUCCESS";
const REQUEST__FAILURE = "REQUEST__FAILURE";
const UPDATE_DATA = "UPDATE_DATA";

const INITIAL_STATE = {status: "loading", data: null, error: null};
function requestReducer(state = INITIAL_STATE, {type,payload}) {
  switch (type) {
    case REQUEST:
      return INITIAL_STATE;
    case REQUEST__SUCCESS:
      return {
        status: "success",
        data: payload,
        error: null
      }
    case REQUEST__FAILURE:
      return {
        status: "error",
        data: null,
        error: payload || "An unexpected error has occurred"
      }
    case UPDATE_DATA:
      return {
        ...state,
        data: payload
      }
    default:
      throw new Error(`Unhandled action type: ${type}`)
  }
}
export const useRequestState = (url,initialState = INITIAL_STATE, action) => {
  const [state, dispatch] = useReducer(requestReducer, initialState),
        authDispatch = useAuthDispatch(),
        makeRequest = useCallback(async (u,body) => {
          dispatch({type: REQUEST})
          try {
            dispatch({type: REQUEST__SUCCESS, payload: await getRequest(u || url,action,body)})
          } catch (e) {
            if(e instanceof TokenError) {
              authDispatch(logoutAction())
            } else {
              dispatch({type: REQUEST__FAILURE, payload: e.message})
            }
          }
        },[url,dispatch]),
        updateData = (payload) => dispatch({type: UPDATE_DATA, payload})

  return {state,makeRequest,updateData};
}

export const useRequest = (url) => {
  const request = useRequestState(url);
  useEffect(() => {
    request.makeRequest();
  },[request.makeRequest])

  return request;
}

export const RequestStatusSwitch = ({status,error,children,white}) => {
  if(status === "loading"){
    return <RequestLoader white={white} />
  }
  if(status === "error"){
    return <ErrorDisplay error={error} />
  }
  return children;
}

export default function Request({url,children,white}){
  const {state:{status,data,error},makeRequest,updateData} = useRequest(url);
  if(status === "loading"){
    return <RequestLoader white={white} />
  }
  if(status === "error"){
    return <ErrorDisplay error={error} />
  }
  return children(data,makeRequest,updateData)
};
