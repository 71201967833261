import CostCodeFormPage from '@components/Tool/CostCode/Form';
import Request from '@containers/Request';
import DeleteButton from '@containers/DeleteButton';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {updateCostCode} from '@utils/api/cost_code';

const CostCodeUpdatePage = withParams(({params:{id}}) => {
  const url = `/cost_codes/${id}.json`
  return(
    <Request url={url}>
      {(defaultValues) => (
        <CostCodeFormPage
          form={{
            url,
            defaultValues,
            onSubmit: updateCostCode,
            toast: "Project code updated"
          }}
          page={{
            title: "Update Cost Code",
            back: "Back To Cost Code",
            submit: "Update Cost Code",
            right: <DeleteButton url={url} />
          }}
        />
      )}
    </Request>
  )
})

export default withPageTitle(CostCodeUpdatePage, "Update Cost Code");
