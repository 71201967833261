import ProjectEventFormPage from '@components/ProjectEvent/Form';
import Request from '@containers/Request';
import DeleteButton from '@containers/DeleteButton';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {updateProjectEvent} from '@utils/api/project_event';

const ProjectEventUpdatePage = withParams(({params:{projectId,projectEventId}}) => {
  const url = `/projects/${projectId}/project_events/${projectEventId}.json`
  return(
    <Request url={url}>
      {({start_date,end_date,...response}) => (
        <ProjectEventFormPage
          form={{
            url,
            defaultValues: {start_date: new Date(start_date),end_date: new Date(end_date),...response},
            onSubmit: updateProjectEvent,
            toast: "Updated project event"
          }}
          page={{
            title: "Update Project Event",
            back: "Back To Project Event",
            submit: "Update Project Event",
            right: <DeleteButton url={url} />
          }}
          projectId={projectId}
          eventId={response.id}
        />
      )}
    </Request>
  )
})

export default withPageTitle(ProjectEventUpdatePage, "Update Project Event");
