import {Link} from 'react-router-dom';
import Page from '@components/Layouts/CommonPage';
import {Switch} from '@components/Form/Fields/Switch';
import Value from '@components/Value';
import Button from '@components/Button';
import SplitRow from '@components/SplitRow';
import Request from '@containers/Request';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import withIsAuthorized from '@containers/withIsAuthorized';
import {getFormattedDate} from '@utils';

const ProjectEventPage = withParams(withIsAuthorized(({isAuthorized,params:{projectId,projectEventId}}) => (
  <Request url={`/projects/${projectId}/project_events/${projectEventId}.json`}>
    {(response) => (
      <Page
        page={{
          title: "Project Event",
          back: "Back To Project Events",
          right: isAuthorized && (<Button as={Link} to="edit">Update</Button>)
        }}
        title="Project Event Information"
      >
        <Value value={response.name} label="Name" />
        <SplitRow>
          <Value value={response.start_date && getFormattedDate(response.start_date)} label="Start Date" />
          <Value value={response.end_date && getFormattedDate(response.end_date)} label="End Date" />
        </SplitRow>
        <SplitRow>
          <Value value={<Switch checked={response.active} value={response.active} disabled />} label="Status" />
          <Value value={<Switch checked={response.completed} value={response.completed} disabled />} label="Completed" />
        </SplitRow>

        <Value value={response.description} label="Description" />
        {response.dependent_on && <Value value={<Link to={`../${response.dependent_on}`}>View Event</Link>} label="Dependent On" />}


      </Page>
    )}
  </Request>
),"Project Events","write"))

export default withPageTitle(ProjectEventPage, "Project Event Details");
