import {classNames} from '@utils';
import styles from './index.module.css';
import addIcon from '@assets/images/add-24px.svg';

const Button = ({className, secondary, form, red, small, action, disabled, isProcessing, children, onClick, center, as: WrapperComponent = "button",...props}) => (
  <WrapperComponent
    {...props}
    onClick={(disabled || isProcessing) ? undefined : onClick}
    className={classNames(styles.container,
      "selectable",
      form && styles.form,
      secondary && styles.secondary,
      disabled && styles.disabled,
      red && styles.red,
      small && styles.small,
      center && styles.center,
      action && styles.action,
      className
    )}
  >
    {isProcessing ? (
      <div>Loading...</div>
    ) : (<>
      <div className={styles.text}>{action ? <img src={addIcon} className={styles.addIcon} /> : children}</div>
    </>)}
  </WrapperComponent>
);

export default Button;
