import {Outlet} from 'react-router-dom';
import RequestProvider,{withRequestState} from '@containers/RequestContext';
import withParams from '@containers/withParams';
import styles from './index.module.css';

const ProjectLayoutHeader = withRequestState(({request:{state:{data}}}) => (
  <div className={styles.header}>{data?.name}</div>
))

const ProjectLayout = withParams(({params:{projectId}}) => (
  <RequestProvider url={`/projects/${projectId}.json`}>
    <ProjectLayoutHeader />
    <Outlet />
  </RequestProvider>
));

export default ProjectLayout;
