import Table from '@components/Table';

const CostCodeList = ({costCodes}) => (
  <Table
    cols={["Type","Abbreviation","Active"]}
    rows={costCodes}
    renderRow={(p) => (<>
      <div>{p.cost_type}</div>
      <div>{p.abbrev}</div>
      <div>{p.active ? "Yes" : "No"}</div>
    </>)}
    placeholder={{to: "new", text: "There are no cost codes"}}
  />
)

export default CostCodeList;
