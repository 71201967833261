import {Link} from 'react-router-dom';
import Page from '@components/Layouts/Page';
import Button from '@components/Button';
import UnitOfMeasureList from '@components/Tool/UnitOfMeasure/List';
import Request from '@containers/Request';
import withPageTitle from '@containers/withPageTitle';

export const UnitOfMeasureListPageComponent = ({url,...props}) => (
  <Page
    {...props}
    right={<Button as={Link} to="new">Add Unit of Measure</Button>}
  >
    <Request url={url}>
      {({unit_of_measures}) => <UnitOfMeasureList unitOfMeasures={unit_of_measures} />}
    </Request>
  </Page>
)

const UnitOfMeasureListPage = () => (
  <UnitOfMeasureListPageComponent
    url="/unit_of_measures.json"
    title="Units Of Measure"
  />
)

export default withPageTitle(UnitOfMeasureListPage, "Units Of Measure");
