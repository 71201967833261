import {StatefulTable} from '@components/Table';
import {makeDateString,cashFormat} from '@utils';

const ProjectChangeOrderList = ({projectChangeOrders,hideButton,...props}) => (
  <StatefulTable
    {...props}
    cols={["#","Revision","Title","Date Signed","Amount"]}
    rows={projectChangeOrders}
    renderRow={(p) => (<>
      <div>{p.change_order_num}</div>
      <div>{p.revision}</div>
      <div>{p.title}</div>
      <div>{makeDateString(p.date_signed)}</div>
      <div>{cashFormat(p.total_amount)}</div>
    </>)}
    placeholder={{to: "new", text: "There are no project change orders", hideButton}}
  />
)

export default ProjectChangeOrderList;
