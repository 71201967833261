export class RequestError extends Error {
  constructor(status, message = "An unexpected error has occurred") {
    super(status)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RequestError)
    }
    this.name = 'RequestError';
    this.status = status;
    this.message = message;
  }
}

export class AuthorizationError extends Error {
  constructor(status) {
    super(status)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AuthorizationError)
    }
    this.name = 'AuthorizationError';
  }
}

export class TokenError extends Error {
  constructor() {
    super()
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, TokenError)
    }
    this.name = 'TokenError';
  }
}

export class FormError extends Error {
  constructor(status,fieldErrors = {}) {
    super(status)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FormError)
    }
    this.name = 'FormError';
    this.fieldErrors  = fieldErrors;
  }
}

const makeFormErrorObj = (r = {}) => Object.keys(r).map(name => ({
  type: "manual",
  name,
  message: `${name.replace("_"," ")} ${r[name][0]}`
}))

export default function handleError(e){
  if(e?.response?.status === 401){
    throw new AuthorizationError(e?.response?.status)
  } else if(e?.response?.status === 400 && e.response.data?.error === "invalid_grant"){
    throw new TokenError()
  } else if(e?.response?.status === 422){
    throw new FormError(e?.response?.status, makeFormErrorObj(e.response.data))
  } else {
    throw new RequestError(e?.response?.status,e?.response?.status === 404 && "Unable to find this page")
  }
}
