import {getValue} from '@components/Form/Fields/ReactSelect';
import {makeRequest} from '@utils/api';
import {apiDateFormat} from '@utils';

export const createProjectMeeting = (project_meeting, url) => makeRequest("POST",url,{
  project_meeting: {
    ...project_meeting,
    meeting_date: apiDateFormat(project_meeting.meeting_date),
    contact_ids: project_meeting.contact_ids?.map(getValue)
  }
})

export const updateProjectMeeting = (project_meeting, url) => makeRequest("PATCH",url,{
  project_meeting: {
    ...project_meeting,
    meeting_date: apiDateFormat(project_meeting.meeting_date),
    contact_ids: project_meeting.contact_ids?.map(getValue)
  }
})
