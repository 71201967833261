import ProjectStageFormPage from '@components/Tool/Stage/Form';
import withPageTitle from '@containers/withPageTitle';
import {createProjectStage} from '@utils/api/project_stage';

const ProjectStageCreatePage = () => (
  <ProjectStageFormPage
    form={{
      url: "/project_stages.json",
      onSubmit: createProjectStage,
      toast: "Project stage added"
    }}
    page={{
      title: "Create Project Stage",
      back: "Back To Project Stages",
      submit: "Create New Project Stage"
    }}
  />
)

export default withPageTitle(ProjectStageCreatePage, "Create Project Stage");
