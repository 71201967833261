import {BackIcon} from '@components/Layouts/Page';
import styles from './index.module.css'
import {getFormattedDate,getTime} from '@utils';

const EmailHeader = ({title,date,backHandler}) => (
  <div className={styles.container}>
    <div className={styles.title}>
      {backHandler && <BackIcon onClick={backHandler} />}
      <div><b>{title}</b></div>
    </div>

    <div className={styles.date}>{getFormattedDate(date)} at {getTime(date)}</div>
  </div>
)

export default EmailHeader
