import Item from './Item';

const EmailList = ({
  activeId,
  onClick,
  emails
}) => emails.map(email =>
  <Item key={email.id} email={email} active={email.id === activeId} onClick={() => {onClick(email)}} />
)

export default EmailList;
