import FormPage from '@components/Form/Page';
import Input from '@components/Form/Fields/Input';
import Switch from '@components/Form/Fields/Switch';
import SplitRow from '@components/SplitRow';
import {costCodeSchema} from '@utils/validation/cost_code';

const CostCodeFormPage = ({form,...props}) => (
  <FormPage
    {...props}
    form={{
      ...form,
      validationSchema: costCodeSchema
    }}
    title="Cost Code Information"
  >
    <SplitRow>
      <Input name="cost_type" label="Type" placeholder="Enter Type" />
      <Input name="abbrev" label="Abbreviation" placeholder="Enter Abbreviation" />
    </SplitRow>
    <Switch name="active" label="Active" />

  </FormPage>
);

export default CostCodeFormPage;
