import TagFormPage from '@components/Tool/Tag/Form';
import withPageTitle from '@containers/withPageTitle';
import {useAppInfoRequest} from '@containers/AppInfo';
import {createTag} from '@utils/api/tag';

const TagCreatePage = () => {
  const appInfoRequest = useAppInfoRequest();
  return(
    <TagFormPage
      form={{
        url: "/tags.json",
        onSubmit: createTag,
        toast: "Project directory added",
        onSuccess: () => {appInfoRequest()}
      }}
      page={{
        title: "Create Directory",
        back: "Back To Directories",
        submit: "Create New Directory"
      }}
    />
  )
}

export default withPageTitle(TagCreatePage, "Create Directory");
