import {classNames} from '@utils';
import Label from '@components/Form/Label';
import styles from './index.module.css';

const Value = ({label, className, value, format,secondary,...props}) => value ? (
  <div className={classNames(styles.container,className,secondary && styles.secondary)} {...props}>
    {label && <Label>{label}</Label>}
    <div className={styles.value}>{format ? format(value) : value}</div>
  </div>
) : null

export default Value;
