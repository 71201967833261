import {useFormContext,Controller} from 'react-hook-form';
import withField from '@components/Form/Fields/withField';

export const Input = ({as: Component = "input",...props}) => {
  const { register } = useFormContext();
  return <Component {...props} ref={register} />
};

const ControlledInputComponent = ({as = "input",...props}) => <Controller as={as} {...props} />
export const ControlledInput = withField(ControlledInputComponent)

const InputField = withField(Input)

export default InputField;
