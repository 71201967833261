import {useRef,useEffect} from 'react';

export default function useHandleClickOutside(handler){
  const ref = useRef()
  useEffect(() => {
    const listener = (e) => {
      if (!ref.current || ref.current.contains(e.target)) {
        return;
      }
      handler(e)
    }
    document.addEventListener("click", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("click", listener);
      document.removeEventListener("touchstart", listener);
    }
  },[ref, handler]);
  return ref
}
