import FormPage from '@components/Form/Page';
import Input from '@components/Form/Fields/Input';
import DateInput from '@components/Form/Fields/Date';
import CurrencyInput from '@components/Form/Fields/CurrencyInput';
import Select from '@components/Form/Fields/Select';
import Switch from '@components/Form/Fields/Switch';
import SplitRow from '@components/SplitRow';
import RequestSelect from '@containers/RequestSelect';
import AppInfoSelect from '@containers/AppInfoSelect';
import {projectChangeOrderSchema} from '@utils/validation/project_change_order';
import {makeStatusOptions} from '@utils';

const ProjectChangeOrderFormPage = ({form,projectId,...props}) => (
  <FormPage
    {...props}
    form={{
      ...form,
      validationSchema: projectChangeOrderSchema
    }}
    title="Project Change Order Information"
  >
    <Input name="project_prime_contract_id" className="hidden" />
    <Input name="user_id" className="hidden" />
    <Input type="checkbox" name="is_potential" className="hidden" />
    <SplitRow>
      <Input name="change_order_num" label="#" placeholder="Enter #" />
      <Input name="title" label="Title" placeholder="Enter Title" />
    </SplitRow>
    <SplitRow>
      <Input name="revision" label="Revision" placeholder="Enter Revision" />
      <Switch name="paid_in_full" label="Paid" />
    </SplitRow>
    <SplitRow>
      <AppInfoSelect name="status" label="Status" resource={makeStatusOptions("project_change_order_statuses")} placeholder="Select Status..." />
      <Switch name="private" label="Private" />
    </SplitRow>
    <SplitRow>
      <DateInput name="due_date" label="Due Date" closeOnScroll withPortal />
      <DateInput name="signed_date" label="Invoiced Date" closeOnScroll withPortal />
    </SplitRow>
    <SplitRow>
      <CurrencyInput name="total_amount" label="Total Amount" />
      <Input name="change_reason" label="Change Reason" placeholder="Enter Change Reason" />
    </SplitRow>
    <Input as="textarea" name="description" label="Description" placeholder="Enter Description" />
    <CurrencyInput name="schedule_impact_days" label="# of Days Schedule Impacted" precision="0" prefix="" suffix=" days" fixedDecimalScale={false} />
    <RequestSelect name="address_id" label="Address" url={`/projects/${projectId}.json`} resource={(project) => project?.addresses} labelAccessor={({street,city,state_id}) => `${street}, ${city}`} />
  </FormPage>
);

export default ProjectChangeOrderFormPage;
