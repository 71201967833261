import styles from './index.module.css';
import {classNames} from '@utils';

const File = ({children, className, as: WrapperComponent = "div",...props}) => (
  <WrapperComponent {...props} className={classNames(styles.container, className)} >
    {children}
  </WrapperComponent>
)

export default File;
