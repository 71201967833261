import {forwardRef} from 'react';
import CurrencyInput from 'react-number-format';
import {Controller} from 'react-hook-form';
import withField from '@components/Form/Fields/withField';

const CurrencyInputContainer = forwardRef(({onChange,decimalScale = 2, thousandSeparator = true, fixedDecimalScale = 2, inputmode = "numeric", prefix = "$",...props},ref) => (
  <CurrencyInput {...props} onValueChange={({value}) => {onChange(value)}} decimalScale={decimalScale} fixedDecimalScale={fixedDecimalScale} thousandSeparator={thousandSeparator} inputmode={inputmode} prefix={prefix} getInputRef={ref} />
));

const CurrencyInputController = withField((props) => <Controller {...props} as={CurrencyInputContainer} />)

export default CurrencyInputController;
