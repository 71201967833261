import {Link} from 'react-router-dom';
import Page from '@components/Layouts/CommonPage';
import {Switch} from '@components/Form/Fields/Switch';
import Value from '@components/Value';
import Button from '@components/Button';
import SplitRow from '@components/SplitRow';
import {ContactSection} from '@components/Contact/Section';
import Request from '@containers/Request';
import withParams from '@containers/withParams';
import ProjectBudgetItemSection from '@components/ProjectBudgetItem/Section';
import withPageTitle from '@containers/withPageTitle';
import {canUser,withUser} from '@containers/Auth';
import {getFormattedDate,makeStatusLabel} from '@utils';

const ProjectChangeOrderPage = withUser(withParams(({isPotential,user,params:{projectId,projectPrimeContractId,projectChangeOrderId}}) => (
  <Request url={`/projects/${projectId}/project_prime_contracts/${projectPrimeContractId}/project_change_orders/${projectChangeOrderId}.json`}>
    {(response) => (
      <Page
        page={{
          title: isPotential ? "Potential Change Order" : "Project Change Order",
          back: isPotential ? "Back To Potential Change Orders" : "Back To Project Change Orders",
          right: /*canUser(user,"Project Change Orders","write") && */(<Button as={Link} to="edit">Update</Button>)
        }}
        title={isPotential ? "Potential Change Order Information" : "Project Change Order Information"}
      >
        <SplitRow>
          <Value value={response.change_order_num} label="#" />
          <Value value={response.created_at} label="Date Created" />
        </SplitRow>
        <SplitRow>
          <Value value={response.title} label="Title" />
          <Value value={response.revision} label="Revision" />
        </SplitRow>
        <SplitRow>
          <Value value={response.change_order_status} format={makeStatusLabel} label="Status" />
          <Value value={response.private ? "Yes" : "No"} label="Private" />
        </SplitRow>
        <Value value={response.change_reason} label="Change Reason" />
        <Value value={response.description} label="Description" />
        <SplitRow>
          <Value value={response.was_executed ? "Yes" : "No"} label="Executed" />
          <Value value={response.signed_date} label="Signed Change Order Received Date" format={getFormattedDate} />
        </SplitRow>
        <Value value={response.schedule_impact_days} label="# of Days Schedule Impacted" />
      </Page>
    )}
  </Request>
)))

export default withPageTitle(ProjectChangeOrderPage, "Project Change Order Details");
