import {NavLink,Link,useMatch} from 'react-router-dom';
import Dropdown from '@components/Dropdown';
import {useAuthDispatch,logoutAction} from '@containers/Auth';
import styles from './index.module.css';
import {classNames} from '@utils';
import {ReactComponent as DropdownIcon} from '@assets/images/dropdown.svg';

export const LogoutButton = () => {
  const dispatch = useAuthDispatch();
  const handleClick = () => {
    dispatch(logoutAction())
  }
  return <button type="button" onClick={handleClick} className={`${styles.link} selectable`} title="Logout">Logout</button>
}


// const LinkMenuWrapper = ({children, button, className,to}) => {
//   const match = useMatch(`${to}*`);
//   return(
//     <Dropdown
//       title={button}
//       button={<>{button}<DropdownIcon fill="#fff" className={styles.dropdownIcon} /></>}
//       wrapperClassName={styles.dropdownWrapper}
//       buttonClassName={classNames(styles.link,match && styles.active)}
//       contentClassName={styles.dropdownContent}
//       headerLink
//     >
//       {({close}) => children(close)}
//     </Dropdown>
//   )
// }

const LinkMenuWrapper = ({children, button, className,to}) => (
  <Dropdown
    title={button}
    button={<>{button}<DropdownIcon fill="#fff" className={styles.dropdownIcon} /></>}
    wrapperClassName={styles.dropdownWrapper}
    buttonClassName={classNames(styles.link)}
    contentClassName={styles.dropdownContent}
    headerLink
  >
    {({close}) => children(close)}
  </Dropdown>
)

const LinkMenu = ({links = [],to,makeName = makeNameDefault,fixed,onClick,resource}) => (<>
  <div className={styles.dropdownLabel}>{!fixed && "Recent "}{resource}</div>
  <div className={classNames(styles.menuLinks, !fixed && styles.menuLinksList)}>
    {/*{links.map(link => (
      <NavLink key={link.id} to={`${to}/${link.id}`} className={`${styles.menuLink} selectable`} activeClassName="darken" onClick={onClick}>{makeName(link)}</NavLink>
    ))}*/}
    {links.map(link => (
      <NavLink key={link.id} to={resource === "projects" ? `/companies/${link.company_id}/${resource}/${link.id}` : `/${resource}/${link.id}`} className={`${styles.menuLink} selectable`} activeClassName="darken" onClick={onClick}>{makeName(link)}</NavLink>
    ))}
  </div>
  {!fixed && <Link to={`/${resource}`} onClick={onClick} className={`${styles.viewAllLink} selectable`}>View All</Link>}
</>)

const makeNameDefault = ({name}) => name
const ResourceLinkMenu = ({resource,user,makeName,links,fixed}) => (
  <LinkMenuWrapper button={resource} to={`/${resource}`}>
    {(close) => <LinkMenu to={`/${resource}`} fixed={fixed} resource={resource} links={links} onClick={close} makeName={makeName} />}
  </LinkMenuWrapper>
)


export default ResourceLinkMenu;
