import styles from './index.module.css'
import {getFormattedDate,getTime,classNames} from '@utils';

const ProjectEmailItem = ({email,active,className,...props}) => (
  <div className={classNames(styles.container, active && styles.active, className)} {...props}>
    <div className={styles.from}>{email.from}</div>
    <div className={styles.flex}>
      <div className={styles.title}>{email.title}</div>
      <div className={styles.date}>{getFormattedDate(email.sent_at)} {getTime(email.sent_at)}</div>
    </div>
  </div>
)

export default ProjectEmailItem
