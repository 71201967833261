import { Link } from 'react-router-dom';
import Button from '@components/Button';
import {RequestStatusSwitch} from '@containers/Request';
import EmptyResourcePlaceholder from '@components/EmptyResourcePlaceholder';
import Pagination from "@components/Pagination";
import styles from './index.module.css';
import {classNames} from '@utils';
import {ReactComponent as BackIcon} from '@assets/images/arrow_back_ios-24px.svg';

export const Header = ({children}) => (
  <div className={styles.header}>
    {children}
  </div>
)

export const Row = ({to,children,onClick}) => {
  const WrapperComponent = to ? Link : "div";
  return(
    <WrapperComponent to={to} onClick={onClick} className={classNames(styles.row, (to || onClick) && "highlight")}>
      {children}
    </WrapperComponent>
  )
}

export const Table = ({children,secondary, add,fixed}) => (
  <div className={styles.wrapper}>
    <div className={classNames(styles.container,secondary && styles.secondary,fixed && styles.fixed)}>
      {children}
    </div>
    {add && <Button as={Link} to="new" action className={styles.addBtn} />}
  </div>
)



const LargeTable = ({cols,rows,fixed,secondary,to,renderRow,add,onClick,noLink,linkRoot,placeholder = {to: "new", text: "There are none"}}) => {
  const toFunc = to ? (r) => to(r) : noLink ? () => undefined : linkRoot ? (r) => `${linkRoot}${r.id}` : (r) => `${r.id}`
  return rows?.length > 0 ? (
    <Table add={add} secondary={secondary} fixed={fixed}>
      <Header>
          {cols.map(c => <div key={c}>{c}</div>)}
      </Header>
      {rows.map(r => (
        <Row onClick={onClick ? () => {onClick(r)} : undefined} to={toFunc(r)} key={r.id}>
          {renderRow(r)}
        </Row>
      ))}
    </Table>
  ) : (<>
    <Table add={add} secondary={secondary}>
      <Header>
        {cols.map(c => <div key={c}>{c}</div>)}
      </Header>
    </Table>
    <EmptyResourcePlaceholder {...placeholder} />
  </>)
}

export const StatefulTable = ({status,error,...props}) => (
  <RequestStatusSwitch status={status} error={error}>
    <LargeTable {...props} />
  </RequestStatusSwitch>
)

export const PaginatedTable = ({url,pageSize,children}) => (
  <Pagination url={url} pageSize={pageSize}>
    {(response) => children(response)}
  </Pagination>

)

export default LargeTable;
