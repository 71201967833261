import {makeRequest} from '@utils/api';

const makeFormTemplate = ({form_fields_attributes,field_type,...form_template}) => ({
  ...form_template,
  form_fields_attributes: form_fields_attributes.map((f,i) => ({rank: i+1,...f}))
})

export const createFormTemplate = (form_template, url) => makeRequest("POST",url,{
  form_template: makeFormTemplate(form_template)
})

export const updateFormTemplate = (form_template, url) => makeRequest("PATCH",url,{
  form_template: makeFormTemplate(form_template)
})
