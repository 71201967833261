import {useRef,useEffect} from 'react';
import {Pagination} from '@components/Pagination';
import useAccordion from '@containers/useAccordion';
import usePaginationRequest from '@containers/usePaginationRequest';
import {classNames} from '@utils';
import styles from './index.module.css';
import dropdownIcon from '@assets/images/dropdown.svg';

export const SectionWrapper = ({as: WrapperComponent = "div",title,collapsible = true,className,onClick,children,...props}) => (
  <WrapperComponent {...props} className={classNames(styles.container,className)}>
    <div className={styles.header} onClick={onClick}>
      <div className={styles.title}>{title}</div>
      {collapsible && <img src={dropdownIcon} className={styles.toggle} />}
    </div>
    {children}
  </WrapperComponent>
)

const Section = ({title,collapsible = true,className,children,wrapperClassName,initialIsOpen}) => {
  const accordion = useAccordion(collapsible ? initialIsOpen : true);
  return(
    <SectionWrapper onClick={accordion.toggle} collapsible={collapsible} title={title} className={classNames(wrapperClassName, collapsible && styles.collapsible,accordion.state.isOpen && styles.open)}>
      <div className={classNames(styles.content, className)} style={collapsible ? { maxHeight: accordion.state.maxHeight } : undefined} ref={accordion.ref}>
        <div className={styles.padding}>
          {typeof children === 'function' ? children(accordion) : children}
        </div>
      </div>
    </SectionWrapper>
  )
}

export function useSectionRequestHandler(isOpen,status,setHeight,makeRequest,initialQuery){
  const requestMade = useRef();
  useEffect(() => {
    if(isOpen && !requestMade.current){
      makeRequest(1,initialQuery);
      requestMade.current = true
    }
  },[isOpen,makeRequest,initialQuery]);
  useEffect(() => {
    if(isOpen){
      setHeight();
    }
  },[status,isOpen]);
  return null;
}

const RequestSectionComponent = ({accordion,url,pageSize,action,initialQuery,children}) => {
  const pagination = usePaginationRequest(url,pageSize,initialQuery,action,true);
  useSectionRequestHandler(accordion.state.isOpen,pagination.state.status,accordion.setHeight,pagination.makeRequest,initialQuery)
  return(
    <Pagination previousPage={pagination.previousPage} nextPage={pagination.nextPage} setPage={pagination.setPage} pageCount={pagination.state.data.page_count} page={pagination.state.data.page} status={pagination.state.status} error={pagination.state.error}>
      {children(pagination.state.data,pagination.makeRequest)}
    </Pagination>
  )
}
export const RequestSection = ({url,pageSize = 10,action = "GET",initialQuery,children,...props}) => (
  <Section {...props}>
    {(accordion) => (
      <RequestSectionComponent accordion={accordion} url={url} pageSize={pageSize} action={action} initialQuery={initialQuery}>
        {(data,makeRequest) => children(data,makeRequest)}
      </RequestSectionComponent>
    )}
  </Section>
)

export default Section;
