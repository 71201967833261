import ProjectBudgetItemFormPage from '@components/ProjectBudgetItem/Form';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {createProjectBudgetItem} from '@utils/api/project_budget_item';

const ProjectBudgetItemCreatePage = withParams(({params:{projectId,projectPrimeContractId}}) => (
  <ProjectBudgetItemFormPage
    form={{
      url: `/projects/${projectId}/project_prime_contracts/${projectPrimeContractId}/project_budget_items.json`,
      defaultValues: {project_prime_contract_id: projectPrimeContractId},
      onSubmit: createProjectBudgetItem,
      toast: "Project budget item added"
    }}
    page={{
      title: "Create Project Budget Item",
      back: "Back To Project Budget Items",
      submit: "Create New Project Budget Item"
    }}
    projectId={projectId}
  />
))

export default withPageTitle(ProjectBudgetItemCreatePage, "Create Project Budget Item");
