import {useState,useEffect} from 'react';
import {Link,useLocation} from 'react-router-dom';
import Hamburger from '@components/Layouts/App/Hamburger';
import Dropdown from '@components/Dropdown';
import HeaderLink,{LogoutButton} from '@components/Layouts/App/Header/Link';
import SearchDropdown from '@components/Layouts/App/Header/Search';
import {canUser,withUser} from '@containers/Auth';
import {withIsMobile} from '@containers/ScreenWidth';
import styles from './index.module.css';
import logo1 from '@assets/images/logo.svg';
import logo2 from '@assets/images/alt_logo.png';
import {classNames} from '@utils';

const toolLinks = [{id: "codes",name: "Cost Codes"},{id: "directories",name: "Directories"},{id: "templates",name: "Form Templates"},{id: "permissions",name: "Permissions"},{id: "defaults", name: "Project Defaults"},{id: "stages",name: "Project Statuses"},{id: "types",name: "Project Types"},{id: "titles",name: "Titles"},{id: "units",name: "Units Of Measure"}]
const Links = withUser(({className,isMobile,user}) => (
  <div className={classNames(styles.links, className)}>
    {isMobile && <img src={logo2} alt="Darana Hybrid Small Logo" className={styles.logo2} />}
    {isMobile && <SearchDropdown />}
    {canUser(user,"Projects","read") && <HeaderLink resource="projects" links={user.recents?.projects} />}
    {canUser(user,"Companies","write") && <HeaderLink resource="companies" links={user.recents?.companies} />}
    {canUser(user,"Contacts","write") && <HeaderLink resource="contacts" links={user.recents?.contacts} makeName={({first_name,last_name}) => `${first_name} ${last_name}`} />}
    {canUser(user,"Tools","write") && <HeaderLink fixed resource="tools" links={toolLinks} />}
    <LogoutButton />
  </div>
))


const DesktopLinks = () => (
  <div className={styles.content}>
    <Links />
    <div className={styles.right}>
      <SearchDropdown />
      <img src={logo2} alt="Darana Hybrid Small Logo" className={styles.logo2} />
    </div>
  </div>
)

const MobileLinks = () => {
  const [isOpen,setIsOpen] = useState();
  const location = useLocation();
  useEffect(() => {
    setIsOpen(false)
  }, [location]);
  return(<>
    <Hamburger onClick={() => setIsOpen(prev => !prev)} isOpen={isOpen} />

    <div className={classNames(styles.overlay,isOpen && styles.open)} />

    <Links className={isOpen ? styles.open : undefined} isMobile />
  </>)
}
const LinksContainer = withIsMobile((w) => w.isMobile ? (
  <MobileLinks />
) : (
  <DesktopLinks />
), 1024)
const Header = () => (
  <header className={styles.container}>
    <Link to="/" className={styles.logo}><img src={logo1} alt="Darana Hybrid Main Logo" /></Link>
    <LinksContainer />
  </header>
);

export default Header;
