import ProjectChangeOrderFormPage from '@components/ProjectChangeOrder/Form';
import withParams from '@containers/withParams';
import {withUser} from '@containers/Auth';

import withPageTitle from '@containers/withPageTitle';
import {createProjectChangeOrder} from '@utils/api/project_change_order';

const ProjectChangeOrderCreatePage = withParams(withUser(({user,params:{projectId,projectPrimeContractId},isPotential}) => (
  <ProjectChangeOrderFormPage
    form={{
      url: `/projects/${projectId}/project_prime_contracts/${projectPrimeContractId}/project_change_orders.json`,
      defaultValues: {project_prime_contract_id: projectPrimeContractId, user_id: user.id, is_potential: isPotential},
      onSubmit: createProjectChangeOrder,
      toast: "Project change order added"
    }}
    page={{
      title: "Create Project Change Order",
      back: "Back To Project Change Orders",
      submit: "Create New Project Change Order"
    }}
    projectId={projectId}
  />
)))

export default withPageTitle(ProjectChangeOrderCreatePage, "Create Project Change Order");
