import FormPage from '@components/Form/Page';
import Input from '@components/Form/Fields/Input';
import CurrencyInput from '@components/Form/Fields/CurrencyInput';
import SplitRow from '@components/SplitRow';
import AppInfoSelect from '@containers/AppInfoSelect';
import {projectBudgetItemSchema} from '@utils/validation/project_budget_item';

const costCodeLabelAccessor = ({abbrev,cost_type}) => `${abbrev} - ${cost_type}`
const ProjectBudgetItemFormPage = ({form,projectId,...props}) => (
  <FormPage
    {...props}
    form={{
      ...form,
      validationSchema: projectBudgetItemSchema
    }}
    title="Project Budget Item Information"
  >
    <Input name="project_prime_contract_id" type="hidden" className="hidden" />
    <AppInfoSelect name="cost_code_id" label="Cost Code" placeholder="Select Cost Code..." resource="cost_codes" labelAccessor={costCodeLabelAccessor} />
    <SplitRow>
      <CurrencyInput name="unit_cost" label="Unit Cost" />
      <Input name="units" type="number" label="Units" />
    </SplitRow>
    <AppInfoSelect name="unit_of_measure_id" label="Unit Of Measure" placeholder="Select Unit Of Measure..." resource="unit_of_measures" labelAccessor="unit" />

  </FormPage>
);

export default ProjectBudgetItemFormPage;
