import {string, object} from 'yup';
import {dateOnly} from '@utils';

export const projectEventSchema = object().shape({
  project_id: string().required('Company is required'),
  name: string().required('Name is required'),
  start_date: string().required('Start date is required'),
  end_date: string().test('is not before start_date',"Must be after start date",function(end_date){
    const s = dateOnly(this.parent.expected_start_date),
          e = dateOnly(this.parent.expected_end_date);
    return (s || e) ? e >= s : true;
  }).required('End date is required')
})
