import CostCodeFormPage from '@components/Tool/CostCode/Form';
import withPageTitle from '@containers/withPageTitle';
import {createCostCode} from '@utils/api/cost_code';

const CostCodeCreatePage = () => (
  <CostCodeFormPage
    form={{
      url: "/cost_codes.json",
      onSubmit: createCostCode,
      toast: "Project code added"
    }}
    page={{
      title: "Create Cost Code",
      back: "Back To Cost Codes",
      submit: "Create New Cost Code"
    }}
  />
)

export default withPageTitle(CostCodeCreatePage, "Create Cost Code");
