import {RequestSection} from '@components/Section';
import {StatefulTable} from '@components/Table';
import {cashFormat} from '@utils';

const makeRows = (changeOrders,value = 0) => {
  const approvedChangeOrders = changeOrders.filter(co => co.signed_date),
        approved = approvedChangeOrders.reduce((v, co) => (
          v + Number(co.total_amount)
        ),0),
        revised = value + approved,
        pending = changeOrders.filter(co => co.change_order_status === "pending").reduce((v, co) => (
          v + Number(co.total_amount)
        ),0),
        payments_received = approvedChangeOrders.filter(co => co.paid_in_full).reduce((v, co) => (
          v + Number(co.total_amount)
        ),0),
        draft = changeOrders.filter(co => co.change_order_status === "draft").reduce((v, co) => (
          v + Number(co.total_amount)
        ),0);
  return [{
    original: value,
    approved,
    revised,
    pending,
    pending_revised: revised + pending,
    draft,
    payments_received,
    percent_paid: value === 0 ? "N/A" : payments_received / value,
    remaining_balance: value - payments_received
  }]
}
const ProjectChangeOrderSection = ({
  changeOrders,
  hideButton = true,
  value,
  ...props
}) => (
  <StatefulTable
    {...props}
    cols={[<>Original<br />Contract</>,<>Approved<br />Change Orders</>,<>Revised<br />Contract</>,<>Pending<br />Change Orders</>,<>Pending<br />Revised Contract</>,<>Draft<br />Change Orders</>,<>Payments<br />Received</>,<>Percent<br />Paid</>,<>Remaining<br />Balance<br />Outstanding</>]}
    rows={makeRows(changeOrders,value ? Number(value) : 0)}
    renderRow={(p) => (<>
      <div>${cashFormat(p.original)}</div>
      <div>${cashFormat(p.approved)}</div>
      <div>${cashFormat(p.revised)}</div>
      <div>${cashFormat(p.pending)}</div>
      <div>${cashFormat(p.pending_revised)}</div>
      <div>${cashFormat(p.draft)}</div>
      <div>${cashFormat(p.payments_received)}</div>
      <div>{p.percent_paid}</div>
      <div>${cashFormat(p.remaining_balance)}</div>
    </>)}
    noLink
    secondary
    placeholder={{to: "new", text: "There are no project change orders", hideButton}}
  />
)

export default ProjectChangeOrderSection
