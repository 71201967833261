import {forwardRef} from 'react';
import Error from '@components/Form/Fields/Error';
import Label from '@components/Form/Label';
import Button from '@components/Button';
import styles from './index.module.css';
import {classNames,uniq,getFileNameFromUrl} from '@utils';
import fileIcon from '@assets/images/insert_drive_file-24px.svg';

const getExtReqStr = (str) => uniq(str.split(', ').map(img => {
  switch (img) {
    case "image/png":
      return "PNG"
    case "image/jpg":
    case "image/jpeg":
      return "JPG"
    case "image/gif":
      return "GIF"
    default:
      return "PNG"
  }
})).join(", ")


const isFile = (file) => file?.name && typeof file.name === "string"
const isInputFile = (file) => Array.isArray(file) && isFile(file?.[0])
const getFileName = file => isFile(file) ? file.name : getFileNameFromUrl(file)
const getFileNames = (files) => Array.isArray(files) ? files.map(file => getFileName(file)).join(", ") : getFileNameFromUrl(files)
const FileDrop = forwardRef(({style,large,width,height,input,accept,className,isDragActive,file,isFile,form,name,multiple,placeholder,...props},ref) => {
  const styleObj = style ? style : large ? {width: 480, height: (height / width) * 480} : { width, height };
  return(
    <div className={styles.container}>
      <div style={styleObj} className={classNames(styles.dropzone, "selectable", isDragActive && styles.active, className)} {...props} ref={ref}>
        <input
            name={name}
            multiple={multiple}
            className={styles.hidden}
            id={name}
            {...input}
          />
        {file ? isFile ? (
          <div className={styles.fileImageContainer}>
            <img
              className={styles.fileImage}
              src={fileIcon}
              alt="File"
            />
          <div className={styles.fileName}>{getFileNames(file)}</div>
          </div>
        ) : (
          <img
            className={styles.image}
            src={isInputFile(file) ? URL.createObjectURL(file[0]) : file}
            alt="File"
          />
      ) : null}
        {isFile ? (!file ? (
          <div className={styles.placeholder}>
            {placeholder && <div className={styles.placeholderHeader}>{placeholder}</div>}
            <div className={styles.placeholderText}>{multiple ? "Drag and drop your files here" : "Drag and drop your file here"}</div>
            <div className={styles.or}>or</div>
            <Button type="button" className={styles.button} form>Click To Add Files</Button>
          </div>
        ) : (
          <Button type="button" className={styles.button} form>Click To Replace Files</Button>
        )) : null}
      </div>
      {!style && <Label className={styles.requirements} hideAsterik>Image ({getExtReqStr(accept)}) must be {width} x {height} pixels. File Max: 3MB</Label>}
      {form && <Error name={name} className={styles.error} />}
    </div>
  )
})

export default FileDrop
