import { Link } from 'react-router-dom';
import Page from '@components/Layouts/Page';
import ProjectList from '@components/Project/List';
import {ProjectFilters} from '@components/Project/ListPage';
import Button from '@components/Button';
import {Pagination} from '@components/Pagination';
import usePaginationRequest from '@containers/usePaginationRequest';
import {canUser,withUser} from '@containers/Auth';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import styles from './index.module.css';

export const ProjectListPageComponent = withUser(({url,user,pageSize = 25,...props}) => {
  const {setQuery,previousPage,nextPage,setPage,state} = usePaginationRequest(url,pageSize),
        canWrite = canUser(user,"Projects","write");
  return(
    <Page
      title={(
        <div className={styles.titleWrapper}>
          <div className={styles.title}>Projects</div>
          <ProjectFilters setQuery={setQuery} />
        </div>
      )}
      right={canWrite && <Button as={Link} to="new">Create New Project</Button>}
      {...props}
    >
      <Pagination previousPage={previousPage} nextPage={nextPage} setPage={setPage} pageCount={state.data.page_count} page={state.data.page} status={state.status} error={state.error}>
        <ProjectList projects={state.data?.projects} hideButton={!canWrite} placeholder="There were no projects found with a matching criteria" />
      </Pagination>
    </Page>
  )
})
const CompanyProjects = withParams(({params:{companyId}}) => (
  <ProjectListPageComponent
    back="Back To Company"
    url={`/companies/${companyId}/projects.json`}
  />
))

export default withPageTitle(CompanyProjects, "Company Projects");
