import { Link } from 'react-router-dom';
import Page from '@components/Layouts/Page';
import ProjectMeetingList from '@components/ProjectMeeting/List';
import Button from '@components/Button';
import {PaginatedTable} from '@components/Table';
import withPageTitle from '@containers/withPageTitle';
import withParams from '@containers/withParams';

export const ProjectMeetingListPageComponent = ({url,...props}) => (
  <Page
    right={<Button as={Link} to="new">Create New Project Meeting</Button>}

    {...props}
  >
    <PaginatedTable url={url} pageSize={25}>
      {(request) => <ProjectMeetingList error={request.error} status={request.status} projectMeetings={request.data.project_meetings} hideButton={false} />}
    </PaginatedTable>
  </Page>
)
const ProjectMeetingListPage = withParams(({params:{projectId}}) => (
  <ProjectMeetingListPageComponent
    title="Project Meetings"
    back="Back To Project"
    url={`/projects/${projectId}/project_meetings.json`}
  />
));

export default withPageTitle(ProjectMeetingListPage, "Project Meetings");
