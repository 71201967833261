import {StatefulTable} from '@components/Table';
import {makeDateString} from '@utils';

const ProjectMeetingList = ({projectMeetings,hideButton,...props}) => (
  <StatefulTable
    {...props}
    cols={["Name","Location","Start","End"]}
    rows={projectMeetings}
    renderRow={(p) => (<>
      <div>{p.name}</div>
      <div>{p.active ? "Active" : "Inactive"}</div>
      <div>{makeDateString(p.meeting_date)}</div>
      <div>{makeDateString(p.meeting_date)}</div>
    </>)}
    placeholder={{to: "new", text: "There are no project meetings", hideButton}}
  />
)

export default ProjectMeetingList;
