import FormPage from '@components/Form/Page';
import Input from '@components/Form/Fields/Input';
import Switch from '@components/Form/Fields/Switch';
import {tagSchema} from '@utils/validation/tag';

const categoryOptions = [{value: "time", label: "Time"},{value: "amount", label: "Amount"},{value: "length", label: "Length"},{value: "area", label: "Area"},{value: "volume", label: "Volume"},{value: "mass", label: "Mass"}]

const TagFormPage = ({form,...props}) => (
  <FormPage
    {...props}
    form={{
      ...form,
      validationSchema: tagSchema
    }}
    title="Directory Information"
    left={<Input name="tag" label="Name" placeholder="Enter Name" />}
    right={<Switch name="has_subtags" label="Has Subdirectories" />}
  />
);

export default TagFormPage;
