import {string, object,array} from 'yup';

const formFieldSchema = object().shape({
  label: string().required('This field is required')
})

export const formTemplateSchema = object().shape({
  //company_id: mixed().required('Company is required'),
  title: string().required('Title is required'),
  form_fields_attributes: array().of(formFieldSchema).required('Requires at least 1 form field')
});
