import CompanyFormPage from '@components/Company/Form';
import Request from '@containers/Request';
import withParams from '@containers/withParams';
import DeleteButton from '@containers/DeleteButton';
import withPageTitle from '@containers/withPageTitle';
import {updateCompany} from '@utils/api/company';

const CompanyUpdatePage = withParams(({params:{id}}) => {
  const url = `/companies/${id}.json`;
  return(
    <Request url={url}>
      {(defaultValues) => (
        <CompanyFormPage
          form={{
            url,
            defaultValues,
            onSubmit: updateCompany,
            toast: "Company updated"
          }}
          page={{
            title: "Update Company",
            back: "Back To Company",
            submit: "Update Company",
            right: <DeleteButton url={url} toast="Company has been deleted" />
          }}
        />
      )}
    </Request>
  );
})

export default withPageTitle(CompanyUpdatePage, "Update Company");
