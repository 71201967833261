import {RequestSection} from '@components/Section';
import ProjectEventList from '@components/ProjectEvent/List';
import {CalendarComponent} from '@components/ProjectEvent/Calendar';
import ViewMoreLink from '@components/ViewMoreLink';
import EmptyResourcePlaceholder from '@components/EmptyResourcePlaceholder';

const ProjectEventSection = ({projectId,linkRoot = "project_events/",back = "Back To Project",defaultView = "agenda", title = "Schedule", hideButton, collapsible = true, pageSize = 10, ...props}) => (
  <RequestSection title={title} url={`/projects/${projectId}/project_events.json`} pageSize={pageSize} collapsible={collapsible} {...props}>
    {({project_events}) => (
      project_events?.length > 0 ? (<>
        <CalendarComponent projectEvents={project_events} to={linkRoot} defaultView={defaultView} />
        <ViewMoreLink to="project_events" hideButton={hideButton} back={back} />
      </>) : (
        <EmptyResourcePlaceholder to="project_events/new" text="This project has no events scheduled" back={back} hideButton={hideButton} />
      )
    )}
  </RequestSection>
)

export default ProjectEventSection
