import TitleFormPage from '@components/Tool/Title/Form';
import withPageTitle from '@containers/withPageTitle';
import {useAppInfoRequest} from '@containers/AppInfo';
import {createTitle} from '@utils/api/title';

const TitleCreatePage = () => {
  const appInfoRequest = useAppInfoRequest();
  return(
    <TitleFormPage
      form={{
        url: "/titles.json",
        onSubmit: createTitle,
        toast: "Title added",
        onSuccess: () => {appInfoRequest()}
      }}
      page={{
        title: "Create Title",
        back: "Back To Titles",
        submit: "Create New Title"
      }}
    />
  )
}

export default withPageTitle(TitleCreatePage, "Create Title");
