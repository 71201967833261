import ProjectPrimeContractFormPage from '@components/ProjectPrimeContract/Form';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {createProjectPrimeContract} from '@utils/api/project_prime_contract';

const ProjectPrimeContractCreatePage = withParams(({params:{projectId}}) => (
  <ProjectPrimeContractFormPage
    form={{
      url: `/projects/${projectId}/project_prime_contracts.json`,
      defaultValues: {project_id: projectId},
      onSubmit: createProjectPrimeContract,
      toast: "Project prime contract added"
    }}
    page={{
      title: "Create Project Prime Contract",
      back: "Back To Project Prime Contracts",
      submit: "Create New Project Prime Contract"
    }}
    projectId={projectId}
  />
))

export default withPageTitle(ProjectPrimeContractCreatePage, "Create Project Prime Contract");
