import ProjectFormsFormPage from '@components/ProjectForm/Form';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {createProjectForm} from '@utils/api/project_form';

const ProjectFormCreatePage = withParams(({params:{projectId}}) => (
  <ProjectFormsFormPage
    form={{
      url: `/projects/${projectId}/project_forms.json`,
      defaultValues: {project_id: projectId},
      onSubmit: createProjectForm,
      toast: "Added project form"
    }}
    page={{
      title: "Create Project Form",
      back: "Back To Forms",
      submit: "Create New Project Form"
    }}
  />
))

export default withPageTitle(ProjectFormCreatePage, "Create Project Form");
