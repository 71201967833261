import ProjectEventFormPage from '@components/ProjectEvent/Form';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {createProjectEvent} from '@utils/api/project_event';

const ProjectEventCreatePage = withParams(({params:{projectId}}) => (
  <ProjectEventFormPage
    form={{
      url: `/projects/${projectId}/project_events.json`,
      defaultValues: projectId ? ({project_id: projectId}) : undefined,
      onSubmit: createProjectEvent,
      toast: "Project event added"
    }}
    page={{
      title: "Create Project Event",
      back: "Back To Project Events",
      submit: "Create New Project Event"
    }}
    projectId={projectId}
  />
))

export default withPageTitle(ProjectEventCreatePage, "Create Project Event");
