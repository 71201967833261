import {useState} from 'react';
import { Link } from 'react-router-dom';
import Page from '@components/Layouts/Page';
import {Table,Header} from '@components/Table';
import Button from '@components/Button';
import FileComponent from '@components/ProjectAsset/Directory/File/Item';
import FolderItem from '@components/ProjectAsset/Directory/Folder/Item';
import FolderCard from '@components/ProjectAsset/Directory/Folder/Card';
import ProjectAssetItem from '@components/ProjectAsset/Item';
import ProjectAssetCard from '@components/ProjectAsset/Card';
import Slideshow from '@components/ProjectAsset/Slideshow';
import EmptyResourcePlaceholder from '@components/EmptyResourcePlaceholder';
import Request from '@containers/Request';
import withParams from '@containers/withParams';
import ResourceValue from '@containers/ResourceValue';
import withPageTitle from '@containers/withPageTitle';
import withIsAuthorized from '@containers/withIsAuthorized';
import {useAppInfoState} from '@containers/AppInfo';
import {classNames,stringifyQuery} from '@utils';
import styles from './index.module.css';
import backIcon from '@assets/images/subdirectory_arrow_left_white_24dp.svg';
import { ReactComponent as ListViewIcon } from '@assets/images/format_list_bulleted_black_24dp.svg';
import { ReactComponent as GridViewIcon } from '@assets/images/grid_view_black_24dp.svg';

const DirectoryLinks = ({companyId,projectId,projectUrl,tagId,subTagId}) => {
  const root = `${projectUrl}/directories`
  return(
    <div className={styles.links}>
      <Link to={tagId ? ".." : root}>
        <img src={backIcon} className={styles.backIcon} />
      </Link>
      <Link to={root}>Home</Link>
      {tagId && (<>
        <div>/</div>
        <Link to={`${root}/${tagId}`}><ResourceValue resource="tags" valueAccessor="tag" id={tagId}  /></Link>
      </>)}
      {subTagId && (<>
        <div>/</div>
        <Link to={`${root}/${tagId}/${subTagId}`}><ResourceValue resource="sub_tags" id={subTagId}  /></Link>
      </>)}
    </div>
  )
}

const sortByName = (a,b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}
const makeSubTagArray = (files) => {
  const subTagArray = [],subTagsMap = {};
  for (var i = 0; i < files.length; i++) {
    for (var j = 0; j < files[i].sub_tags.length; j++) {
      if(!subTagsMap[files[i].sub_tags[j].id]){
        subTagArray.push(files[i].sub_tags[j])
        subTagsMap[files[i].sub_tags[j].id] = true
      }
    }
  }
  return subTagArray
}

const formatTagArray = (tags) => tags?.length > 0 ? tags.map(({tag,...tagObj}) => ({name: tag,...tagObj})).sort(sortByName) : []
const makeItemProps = (tagId,subTagId,tags = [],requestFiles = []) => {
  var directories = [],files = [];
  if(!subTagId){
    if(tags.find(({id}) => id === Number(tagId))?.has_subtags){
      directories = makeSubTagArray(requestFiles).sort(sortByName)
    }
    return {
      directories,
      files: requestFiles.filter(file => file.sub_tags.length === 0)
    }
  }
  return {
    directories,
    files: requestFiles.filter(file => file.sub_tags.length > 0)
  }
}
const ProjectDocumentsList = ({directories,files,companyId,projectId,makeRequest,updateData,isList,isDefault}) => (
  <div className={isList ? styles.list : styles.grid}>
    {directories?.length > 0 && (
      directories.map(directory => isList ? <FolderItem key={directory.id} folder={directory} /> : <FolderCard key={directory.id} className={styles.card} folder={directory} />)
    )}
    {files?.length > 0 ? (
      <Slideshow projectAssets={files} updateData={updateData}>
        {openSlideshow => files.map((file,idx) => isList ? (
          <FileComponent isDefault={isDefault} companyId={companyId} onDelete={makeRequest} onClick={() => {openSlideshow(idx)}} projectId={projectId} key={file.id} file={file} tagId={file.tags?.[0]?.id} />
        ) : (
          <ProjectAssetCard isDefault={isDefault} companyId={companyId} key={file.id} values={file} projectId={projectId} onDelete={makeRequest} onClick={() => {openSlideshow(idx)}} className={styles.card} />
        ))}
      </Slideshow>
    ) : (
      directories?.length === 0 && <EmptyResourcePlaceholder to="new" text="There are no files here" />
    )}
  </div>
)
const ProjectDocumentsPageComponent = ({projectUrl,companyId,projectId,tagId,subTagId,isDefault}) => {
  const app_info = useAppInfoState(),
        [view,setView] = useState("list"),
        isList = view === "list",
        rootProjectUrl = `/projects/${projectId}`;


  return(<>
    <Table>
      <Header>
        <div className={styles.header}>
          <DirectoryLinks projectUrl={projectUrl} companyId={companyId} projectId={projectId} tagId={tagId} subTagId={subTagId} />
          <div className={styles.views}>
            <div className={classNames(styles.view, isList && styles.active)} onClick={() => {setView("list")}}>
              <ListViewIcon className={styles.viewIcon} />
            </div>
            <div className={classNames(styles.view, !isList && styles.active)} onClick={() => {setView("grid")}}>
              <GridViewIcon className={styles.viewIcon} />
            </div>
          </div>

        </div>
      </Header>
    </Table>
    {tagId ? (
      <Request url={`${rootProjectUrl}/project_assets.json?tag_ids=${tagId}${subTagId ? `&sub_tag_ids=${subTagId}` : ""}`}>
        {({project_assets},makeRequest,updateData) => <ProjectDocumentsList isDefault={isDefault} {...makeItemProps(tagId,subTagId,app_info?.tags,project_assets)} companyId={companyId} projectId={projectId} makeRequest={makeRequest} updateData={updateData} isList={isList} />}
      </Request>
    ) : (
      <Request url={rootProjectUrl}>
        {({tags},makeRequest,updateData) => <ProjectDocumentsList isDefault={isDefault} directories={formatTagArray(tags)} companyId={companyId} projectId={projectId} makeRequest={makeRequest} updateData={updateData} isList={isList} />}
      </Request>
    )}
  </>)
}

export const ProjectDocumentsPage = ({isAuthorized,projectUrl,...props}) => (
  <Page
    title="Directory"
    back={{to: projectUrl,text: "Back To Project"}}
    right={isAuthorized && <Button as={Link} to="new">Add File</Button>}
  >
    <ProjectDocumentsPageComponent
      projectUrl={projectUrl}
      {...props}
    />
  </Page>
)

const ProjectDocumentsPageContainer = withParams(withIsAuthorized(({isAuthorized,params}) => (
  <ProjectDocumentsPage {...params} projectUrl={`/companies/${params.companyId}/projects/${params.projectId}`} isAuthorized={isAuthorized} />
),"Project Assets","write"))

export default withPageTitle(ProjectDocumentsPageContainer, "Project Photo Directories");
