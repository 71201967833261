import {Link} from 'react-router-dom';
import Page from '@components/Layouts/CommonPage';
import {Switch} from '@components/Form/Fields/Switch';
import Value from '@components/Value';
import Button from '@components/Button';
import SplitRow from '@components/SplitRow';
import Section from '@components/Section';
import {Table,Header,Row} from '@components/Table';
import ProjectChangeOrderSection from '@components/ProjectChangeOrder/Section';
import ChangeOrderSummarySection from '@components/ProjectChangeOrder/Summary';
import ProjectBudgetItemSection from '@components/ProjectBudgetItem/Section';
import Request from '@containers/Request';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {canUser,withUser} from '@containers/Auth';
import {getFormattedDate,statusFormat,cashFormat} from '@utils';


const ProjectPrimeContractPage = withUser(withParams(({user,params:{projectId,projectPrimeContractId}}) => (
  <Request url={`/projects/${projectId}/project_prime_contracts/${projectPrimeContractId}.json`}>
    {(response) => (
      <Page
        page={{
          title: "Project Prime Contract",
          back: "Back To Project Prime Contracts",
          right: /*canUser(user,"Project Prime Contracts","write") && */(<Button as={Link} to="edit">Update</Button>)
        }}
        title="Project Prime Contract Information"
      >
        <SplitRow>
          <Value value={response.contract_num} label="Contract #" />
          <Value value={response.title} label="Title" />
        </SplitRow>
        <SplitRow>
          <Value value={response.status} label="Status" format={statusFormat} />
          {/*<Value value={response.name} label="Executed" />*/}
        </SplitRow>
        <SplitRow>
          <Value value={response.owner_company_id} label="Owner/Client" />
          <Value value={response.contractor_company_id} label="Contractor" />
        </SplitRow>
        <Value value={response.architect_contact_id} label="Architect/Engineer" />
        <Value value={response.private_contract ? "Yes" : "No"} label="Private" />
        <Value value={response.default_retainage} label="Default Retainage" format={(r) => r && `${r}%`} />
        <Value value={response.description} label="Description" />
        <Section title="Contract Summary Report" collapsible={false}>
          <ChangeOrderSummarySection projectId={projectId} projectPrimeContractId={projectPrimeContractId} value={response.value} changeOrders={response.project_change_orders} />
        </Section>
        <Section title="Contract Dates" collapsible>
          <SplitRow>
            <Value value={response.created_at} format={getFormattedDate} label="Date Created" />
            <Value value={response.start_date} format={getFormattedDate} label="Start Date" />
          </SplitRow>
          <SplitRow>
            <Value value={response.estimated_completion_date} label="Estimated Completion Date" format={getFormattedDate} />
            <Value value={response.actual_completion_date} label="Actual Completion Date" format={getFormattedDate} />
          </SplitRow>
          <SplitRow>
            <Value value={response.signed_contract_received_date} label="Signed Contract Received Date" format={getFormattedDate} />
            <Value value={response.contract_terminated_date} label="Contract Termination Date" format={getFormattedDate} />
          </SplitRow>
        </Section>
        {(response.inclusions || response.exclusions) && (
          <Section title="Additional Information" collapsible>
            <Value value={response.inclusions} label="Inclusions" />
            <Value value={response.exclusions} label="Exclusions" />
          </Section>
        )}

        <Section collapsible title="Schedule of Values">
          <Table>
            <Header>
              <div>#</div>
              <div>Amount</div>
              <div>Billed to Date</div>
              <div>Amount Remaining</div>
            </Header>
            <Row><div>1</div><div>{cashFormat(response.value)}</div><div>$0.00</div><div>{cashFormat(response.value)}</div></Row>
          </Table>
        </Section>
        <ProjectChangeOrderSection projectId={projectId} projectPrimeContractId={projectPrimeContractId} />
        <ProjectChangeOrderSection projectId={projectId} projectPrimeContractId={projectPrimeContractId} potentials />
      </Page>
    )}
  </Request>
)))

export default withPageTitle(ProjectPrimeContractPage, "Project Prime Contract Details");
