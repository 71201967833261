import {StatefulTable} from '@components/Table';
import {makeDateString} from '@utils';

const ProjectPrimeContractList = ({projectPrimeContracts,hideButton,...props}) => (
  <StatefulTable
    {...props}
    cols={["#","Title","Status","Start","End"]}
    rows={projectPrimeContracts}
    renderRow={(p) => (<>
      <div>{p.contract_num}</div>
      <div>{p.title}</div>
      <div>{p.status}</div>
      <div>{makeDateString(p.start_date)}</div>
      <div>{makeDateString(p.estimated_completion_date)}</div>
    </>)}
    placeholder={{to: "new", text: "There are no project prime contracts", hideButton}}
  />
)

export default ProjectPrimeContractList;
