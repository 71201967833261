import Table from '@components/Table';

const ProjectTypeList = ({projectTypes}) => (
  <Table
    cols={["Name"]}
    rows={projectTypes}
    renderRow={(p) => (<>
      <div>{p.name}</div>
    </>)}
    placeholder={{to: "new", text: "There are no project types"}}
  />
)

export default ProjectTypeList;
