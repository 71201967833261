import ProjectFormPage from '@components/Project/Form';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {canUser,withUser} from '@containers/Auth';
import {createProject} from '@utils/api/project';

const ProjectCreatePage = withUser(withParams(({user,params:{companyId,id}}) => (
  <ProjectFormPage
    form={{
      url: "/projects.json",
      defaultValues: companyId ? ({company_id: companyId,addresses: [{}]}) : ({addresses: [{}]}),
      onSubmit: createProject,
      toast: "Project added"
    }}
    page={{
      title: "Create Project",
      back: "Back To Projects",
      submit: "Create New Project"
    }}
  />
)))

export default withPageTitle(ProjectCreatePage, "Create Project");
