import { Calendar, momentLocalizer  } from 'react-big-calendar'
import {useNavigate,Link} from 'react-router-dom';
import Request from '@containers/Request';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import styles from './index.module.css';

const makeToStr = (to,id) => typeof to === "string" ? `${to}${id}` : `${id}`
const makeAgendaEvent = (to) => {
  const WrapperComponent = to ? Link : "div"
  return (props) => (
    <WrapperComponent to={to ? makeToStr(to,props.event?.id) : undefined}>{props.title}</WrapperComponent>
  )
}
const localizer = momentLocalizer(moment)
export const CalendarComponent = ({projectEvents,views = ["month", "week", "day", "agenda"],to,...props}) => {
  const navigate = useNavigate()
  return(
    <div className={styles.container}>
      <Calendar
        {...props}
        popup
        localizer={localizer}
        views={views}
        onSelectEvent={to ? ({id}) => {navigate(makeToStr(to,id))} : undefined}
        events={projectEvents.map(({start_date,end_date,...projectEvent}) => ({start_date: new Date(start_date),end_date: new Date(end_date),...projectEvent}))}
        components={{
          agenda: {
            event: makeAgendaEvent(to)
          }
        }}
        titleAccessor="name"
        startAccessor="start_date"
        endAccessor="end_date"
      />
    </div>
  )
}

const CalendarContainer = ({projectId,...props}) => (
  <Request url={`/projects/${projectId}/project_events.json`}>
    {({project_events}) => <CalendarComponent {...props} projectEvents={project_events} />}
  </Request>
)

export default CalendarContainer;
