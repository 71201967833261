import UnitOfMeasureFormPage from '@components/Tool/UnitOfMeasure/Form';
import withPageTitle from '@containers/withPageTitle';
import {createUnitOfMeasure} from '@utils/api/unit_of_measure';

const UnitOfMeasureCreatePage = () => (
  <UnitOfMeasureFormPage
    form={{
      url: "/unit_of_measures.json",
      onSubmit: createUnitOfMeasure,
      toast: "Unit of measure added"
    }}
    page={{
      title: "Create Unit of Measure",
      back: "Back To Units Of Measure",
      submit: "Create New Unit of Measure"
    }}
  />
)

export default withPageTitle(UnitOfMeasureCreatePage, "Create Unit of Measure");
