import UnitOfMeasureFormPage from '@components/Tool/UnitOfMeasure/Form';
import Request from '@containers/Request';
import DeleteButton from '@containers/DeleteButton';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {updateUnitOfMeasure} from '@utils/api/unit_of_measure';

const UnitOfMeasureUpdatePage = withParams(({params:{id}}) => {
  const url = `/unit_of_measures/${id}.json`
  return(
    <Request url={url}>
      {(defaultValues) => (
        <UnitOfMeasureFormPage
          form={{
            url,
            defaultValues,
            onSubmit: updateUnitOfMeasure,
            toast: "Project code updated"
          }}
          page={{
            title: "Update Unit of Measure",
            back: "Back To Unit of Measure",
            submit: "Update Unit of Measure",
            right: <DeleteButton url={url} />
          }}
        />
      )}
    </Request>
  )
})

export default withPageTitle(UnitOfMeasureUpdatePage, "Update Unit of Measure");
