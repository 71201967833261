import {useAuthState,canUser} from '@containers/Auth';

export default function withIsAuthorized(WrappedComponent,resource,access){
  return (props) => {
    const user = useAuthState();
    return (
      <WrappedComponent {...props} user={user} isAuthorized={canUser(user,resource,access)} />
    )
  }
}
