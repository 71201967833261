import PermissionSetPage from '@components/Tool/PermissionSet/Form';
import withPageTitle from '@containers/withPageTitle';
import {createPermissionSet} from '@utils/api/permission_set';

const PermissionSetCreatePage = () => (
  <PermissionSetPage
    form={{
      url: "/permission_sets.json",
      onSubmit: createPermissionSet,
      defaultValues: ({resource_type: "Companies"}),
      toast: "Permission set added"
    }}
    page={{
      title: "Create Permission Set",
      back: "Back To Permission Sets",
      submit: "Create New Permission Set"
    }}
  />
)

export default withPageTitle(PermissionSetCreatePage, "Create Permission Set");
