import Table from '@components/Table';

const PermissionSetList = ({permissions}) => (
  <Table
    cols={["Name"]}
    rows={permissions}
    renderRow={(p) => (<>
      <div>{p.name}</div>
    </>)}
    placeholder={{to: "new", text: "There are no permission sets"}}
  />
)

export default PermissionSetList;
