import Table from '@components/Table';
import {getFormattedDate} from '@utils';
import styles from './index.module.css';

const ProjectEmailList = ({emails}) => (
  <Table
    cols={["Title","From","To","Date",""]}
    rows={emails}
    renderRow={(p) => (<>
      <div>{p.title}</div>
      <div>{p.from}</div>
      <div>{p.to}</div>
      <div>{getFormattedDate(p.sent_at)}</div>
      <div><a href={p.email_url} download target="_blank" className={styles.link}>Download</a></div>
    </>)}
    placeholder={{to: "new", text: "There are no project emails",hideButton:true}}
    noLink
  />
)

export default ProjectEmailList;
