import {useEffect} from 'react';
import { BrowserRouter as Router, Routes,Route,Navigate,useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {useAuthDispatch} from '@containers/Auth';
import Layout from '@components/Layouts/App';
import PublicLayout from '@components/Layouts/Public';
import ErrorBoundary from '@components/ErrorBoundary';
import Login from '@components/User/Login';
import PasswordForgotPage from '@components/User/ForgotPassword';
import PasswordUpdatePage from '@components/User/UpdatePassword';
import InvitationConfirmationPage from '@components/User/InvitationConfirmation';
import ProjectCreatePage from '@components/Project/Create';
import ProjectUpdatePage from '@components/Project/Update';
import ProjectViewPage from '@components/Project/View';
import ProjectListPage from '@components/Project/ListPage';
import ProjectLayout from '@components/Project/Layout';
import ProjectContactsPage from '@components/ProjectContact/ListPage';
import ProjectContactCreatePage from '@components/ProjectContact/Create';
import ProjectContactUpdatePage from '@components/ProjectContact/Update';
import ProjectContactViewPage from '@components/ProjectContact/View';
import ProjectContactCreateExistingPage from '@components/ProjectContact/AddExistingContactPage';
import PermissionSetCreatePage from '@components/Tool/PermissionSet/Create';
import PermissionSetUpdatePage from '@components/Tool/PermissionSet/Update';
import PermissionSetViewPage from '@components/Tool/PermissionSet/View';
import PermissionSetListPage from '@components/Tool/PermissionSet/ListPage';
import ProjectStageCreatePage from '@components/Tool/Stage/Create';
import ProjectStageUpdatePage from '@components/Tool/Stage/Update';
import ProjectStageViewPage from '@components/Tool/Stage/View';
import ProjectStageListPage from '@components/Tool/Stage/ListPage';
import CostCodeCreatePage from '@components/Tool/CostCode/Create';
import CostCodeUpdatePage from '@components/Tool/CostCode/Update';
import CostCodeViewPage from '@components/Tool/CostCode/View';
import CostCodeListPage from '@components/Tool/CostCode/ListPage';
import UnitOfMeasureCreatePage from '@components/Tool/UnitOfMeasure/Create';
import UnitOfMeasureUpdatePage from '@components/Tool/UnitOfMeasure/Update';
import UnitOfMeasureViewPage from '@components/Tool/UnitOfMeasure/View';
import UnitOfMeasureListPage from '@components/Tool/UnitOfMeasure/ListPage';
import ProjectTemplateAssetCreatePage from '@components/Tool/ProjectTemplate/ProjectAsset/Create';
import ProjectTemplateAssetUpdatePage from '@components/Tool/ProjectTemplate/ProjectAsset/Update';
import ProjectTemplateDirectoryListPage from '@components/Tool/ProjectTemplate/ProjectAsset/Directory';
import ProjectTemplateContactCreateExistingPage from '@components/Tool/ProjectTemplate/ProjectContact/AddExistingContactPage';
import ProjectTemplateContactUpdatePage from '@components/Tool/ProjectTemplate/ProjectContact/Update';
import ProjectTemplateContactViewPage from '@components/Tool/ProjectTemplate/ProjectContact/View';
import ProjectTemplateContactCreatePage from '@components/Tool/ProjectTemplate/ProjectContact/Create';
import ProjectTemplateContactListPage from '@components/Tool/ProjectTemplate/ProjectContact/ListPage';
import ProjectTemplateLayout from '@components/Tool/ProjectTemplate/Layout';
import ProjectTemplateUpdatePage from '@components/Tool/ProjectTemplate/Update';
import ProjectTemplateViewPage from '@components/Tool/ProjectTemplate/View';
import TagCreatePage from '@components/Tool/Tag/Create';
import TagUpdatePage from '@components/Tool/Tag/Update';
import TagViewPage from '@components/Tool/Tag/View';
import TagListPage from '@components/Tool/Tag/ListPage';
import TitleCreatePage from '@components/Tool/Title/Create';
import TitleUpdatePage from '@components/Tool/Title/Update';
import TitleViewPage from '@components/Tool/Title/View';
import TitleListPage from '@components/Tool/Title/ListPage';
import ProjectTypeCreatePage from '@components/Tool/Type/Create';
import ProjectTypeUpdatePage from '@components/Tool/Type/Update';
import ProjectTypeViewPage from '@components/Tool/Type/View';
import ProjectTypeListPage from '@components/Tool/Type/ListPage';
import ProjectFormsPage from '@components/ProjectForm/ListPage';
import ProjectFormCreatePage from '@components/ProjectForm/Create';
import ProjectFormUpdatePage from '@components/ProjectForm/Update';
import ProjectFormViewPage from '@components/ProjectForm/View';
import ProjectAssetCreatePage from '@components/ProjectAsset/Create';
import ProjectAssetUpdatePage from '@components/ProjectAsset/Update';
import ProjectAssetViewPage from '@components/ProjectAsset/View';
import ProjectDirectoryListPage from '@components/ProjectAsset/Directory';
import ProjectMeetingsPage from '@components/ProjectMeeting/ListPage';
import ProjectMeetingCreatePage from '@components/ProjectMeeting/Create';
import ProjectMeetingUpdatePage from '@components/ProjectMeeting/Update';
import ProjectMeetingViewPage from '@components/ProjectMeeting/View';
import ProjectPrimeContractsPage from '@components/ProjectPrimeContract/ListPage';
import ProjectPrimeContractCreatePage from '@components/ProjectPrimeContract/Create';
import ProjectPrimeContractUpdatePage from '@components/ProjectPrimeContract/Update';
import ProjectPrimeContractViewPage from '@components/ProjectPrimeContract/View';
import ProjectBudgetItemsPage from '@components/ProjectBudgetItem/ListPage';
import ProjectBudgetItemCreatePage from '@components/ProjectBudgetItem/Create';
import ProjectBudgetItemUpdatePage from '@components/ProjectBudgetItem/Update';
import ProjectBudgetItemViewPage from '@components/ProjectBudgetItem/View';
import ProjectChangeOrdersPage from '@components/ProjectChangeOrder/ListPage';
import ProjectChangeOrderCreatePage from '@components/ProjectChangeOrder/Create';
import ProjectChangeOrderUpdatePage from '@components/ProjectChangeOrder/Update';
import ProjectChangeOrderViewPage from '@components/ProjectChangeOrder/View';
import ProjectEventsPage from '@components/ProjectEvent/ListPage';
import ProjectEventCreatePage from '@components/ProjectEvent/Create';
import ProjectEventUpdatePage from '@components/ProjectEvent/Update';
import ProjectEventViewPage from '@components/ProjectEvent/View';
import CompanyCreatePage from '@components/Company/Create';
import CompanyUpdatePage from '@components/Company/Update';
import PlaceholderPage from '@components/PlaceholderPage';
import CompaniesPage from '@components/Company/List';
import CompanyViewPage from '@components/Company/View';
import CompanyProjectsPage from '@components/Company/Projects';
import CompanyContactsPage from '@components/Company/Contacts';
import ContactCreatePage from '@components/Contact/Create';
import UserUpdatePage from '@components/User/Update';
import ContactUpdatePage from '@components/Contact/Update';
import ContactViewPage from '@components/Contact/View';
import ContactsPage from '@components/Contact/ListPage';
import ProjectEmailsPage from '@components/ProjectEmail/ListPage';
import FormTemplateCreatePage from '@components/FormTemplate/Create';
import FormTemplateUpdatePage from '@components/FormTemplate/Update';
import FormTemplateViewPage from '@components/FormTemplate/View';
import FormTemplatesPage from '@components/FormTemplate/List';
import ProjectEmailInbox from '@components/ProjectEmail/Inbox';
import {LoaderProvider} from '@containers/Loader';
import {ToastProvider} from '@containers/ToastContext';
import {AppInfoProvider} from '@containers/AppInfo';
import {ErrorModalProvider} from '@containers/ErrorModal';
import {ScreenWidthProvider} from '@containers/ScreenWidth';
import {useAuthState, AuthProvider, logoutAction} from '@containers/Auth';

const AutoLogout = () => {
  const dispatch = useAuthDispatch();
  useEffect(() => dispatch(logoutAction()),[])
  return null
}
const ScrollTop = () => {
  const location = useLocation();
  useEffect(() => window.scrollTo(0, 0),[location])
  return null
}
const PublicRedirect = () => <Navigate to="/login" replace />
const Pages = () => {
  const auth = useAuthState();
  return auth ? (
    <AppInfoProvider>
      <Layout>
        <ScrollTop />
        <Routes>
          <Route path="/companies/:companyId/projects/:projectId" element={<ProjectLayout />}>
            <Route path="project_events/:projectEventId/edit" element={<ProjectEventUpdatePage />} />
            <Route path="project_events/new" element={<ProjectEventCreatePage />} />
            <Route path="project_events/:projectEventId" element={<ProjectEventViewPage />} />
            <Route path="project_events" element={<ProjectEventsPage />} />
            <Route path="project_forms/:projectFormId/edit" element={<ProjectFormUpdatePage />} />
            <Route path="project_forms/new" element={<ProjectFormCreatePage />} />
            <Route path="project_forms/:projectFormId" element={<ProjectFormViewPage />} />
            <Route path="project_forms" element={<ProjectFormsPage />} />
            <Route path="directories/:tagId/new" element={<ProjectAssetCreatePage />} />
            <Route path="directories/:tagId/:subTagId/new" element={<ProjectAssetCreatePage />} />
            <Route path="directories/:tagId/:subTagId/:projectAssetId/edit" element={<ProjectAssetUpdatePage />} />
            <Route path="directories/:tagId/:projectAssetId/edit" element={<ProjectAssetUpdatePage />} />
            <Route path="directories/:tagId/:subTagId" element={<ProjectDirectoryListPage />} />
            <Route path="directories/new" element={<ProjectAssetCreatePage />} />
            <Route path="directories/:tagId" element={<ProjectDirectoryListPage />} />
            <Route path="directories" element={<ProjectDirectoryListPage />} />
            <Route path="contacts/:id/edit" element={<ProjectContactUpdatePage />} />
            <Route path="contacts/new/existing" element={<ProjectContactCreateExistingPage />} />
            <Route path="contacts/new" element={<ProjectContactCreatePage />} />
            <Route path="contacts/:contactId/users/:id" element={<UserUpdatePage />} />
            <Route path="contacts/:id" element={<ProjectContactViewPage />} />
            <Route path="contacts" element={<ProjectContactsPage />} />
            <Route path="meetings/:projectMeetingId/edit" element={<ProjectMeetingUpdatePage />} />
            <Route path="meetings/new" element={<ProjectMeetingCreatePage />} />
            <Route path="meetings/:projectMeetingId" element={<ProjectMeetingViewPage />} />
            <Route path="meetings" element={<ProjectMeetingsPage />} />
            <Route path="contracts/:projectPrimeContractId/edit" element={<ProjectPrimeContractUpdatePage />} />
            <Route path="contracts/new" element={<ProjectPrimeContractCreatePage />} />
            <Route path="contracts/:projectPrimeContractId" element={<ProjectPrimeContractViewPage />} />
            <Route path="contracts" element={<ProjectPrimeContractsPage />} />
            <Route path="contracts/:projectPrimeContractId/budget-items/:projectBudgetItemId" element={<ProjectBudgetItemUpdatePage />} />
            <Route path="contracts/:projectPrimeContractId/budget-items/new" element={<ProjectBudgetItemCreatePage />} />
            {/*<Route path="contracts/:projectPrimeContractId/budget-items/:projectBudgetItemId" element={<ProjectBudgetItemViewPage />} />*/}
            <Route path="contracts/:projectPrimeContractId/budget-items" element={<ProjectBudgetItemsPage />} />
            <Route path="contracts/:projectPrimeContractId/change-orders/:projectChangeOrderId/edit" element={<ProjectChangeOrderUpdatePage />} />
            <Route path="contracts/:projectPrimeContractId/change-orders/:projectChangeOrderId" element={<ProjectChangeOrderViewPage />} />
            <Route path="contracts/:projectPrimeContractId/change-orders/new" element={<ProjectChangeOrderCreatePage />} />
            <Route path="contracts/:projectPrimeContractId/change-orders" element={<ProjectChangeOrdersPage />} />
            <Route path="contracts/:projectPrimeContractId/potential-change-orders/:projectChangeOrderId/edit" element={<ProjectChangeOrderUpdatePage isPotential />} />
            <Route path="contracts/:projectPrimeContractId/potential-change-orders/:projectChangeOrderId" element={<ProjectChangeOrderViewPage isPotential />} />
            <Route path="contracts/:projectPrimeContractId/potential-change-orders/new" element={<ProjectChangeOrderCreatePage isPotential />} />
            <Route path="contracts/:projectPrimeContractId/potential-change-orders" element={<ProjectChangeOrdersPage isPotential />} />
            <Route path="emails" element={<ProjectEmailInbox />} />
            <Route path="edit" element={<ProjectUpdatePage />} />

            <Route path="/" element={<ProjectViewPage />} />

          </Route>
          <Route path="/companies/:companyId/projects/new" element={<ProjectCreatePage />} />
          <Route path="/companies/:companyId/projects" element={<CompanyProjectsPage />} />
          <Route path="/companies/:companyId/contacts/:id/edit" element={<ContactUpdatePage />} />
          <Route path="/companies/:companyId/contacts/new" element={<ContactCreatePage />} />
          <Route path="/companies/:companyId/contacts/:contactId/users/:id" element={<UserUpdatePage />} />
          <Route path="/companies/:companyId/contacts/:id" element={<ContactViewPage />} />
          <Route path="/companies/:companyId/contacts" element={<CompanyContactsPage />} />
          <Route path="/companies/:id/edit" element={<CompanyUpdatePage />} />
          <Route path="/companies/new" element={<CompanyCreatePage />} />
          <Route path="/companies/:id" element={<CompanyViewPage />} />
          <Route path="/companies" element={<CompaniesPage />} />
          <Route path="/contacts/:contactId/users/:id" element={<UserUpdatePage />} />
          <Route path="/contacts/:id/edit" element={<ContactUpdatePage />} />
          <Route path="/contacts/new" element={<ContactCreatePage />} />
          <Route path="/contacts/:id" element={<ContactViewPage />} />
          <Route path="/projects/new" element={<ProjectCreatePage />} />
          <Route path="/projects" element={<ProjectListPage />} />
          <Route path="/tools/defaults" element={<ProjectTemplateLayout />}>
            <Route path="directories/:tagId/new" element={<ProjectTemplateAssetCreatePage />} />
            <Route path="directories/:tagId/:subTagId/new" element={<ProjectTemplateAssetCreatePage />} />
            <Route path="directories/:tagId/:subTagId/:projectAssetId/edit" element={<ProjectTemplateAssetUpdatePage />} />
            <Route path="directories/:tagId/:projectAssetId/edit" element={<ProjectTemplateAssetUpdatePage />} />
            <Route path="directories/:tagId/:subTagId" element={<ProjectTemplateDirectoryListPage />} />
            <Route path="directories/new" element={<ProjectTemplateAssetCreatePage />} />
            <Route path="directories/:tagId" element={<ProjectTemplateDirectoryListPage />} />
            <Route path="directories" element={<ProjectTemplateDirectoryListPage />} />
            <Route path="contacts/:id/edit" element={<ProjectTemplateContactUpdatePage />} />
            <Route path="contacts/new/existing" element={<ProjectTemplateContactCreateExistingPage />} />
            <Route path="contacts/new" element={<ProjectTemplateContactCreatePage />} />
            <Route path="contacts/:contactId/users/:id" element={<UserUpdatePage />} />
            <Route path="contacts/:id" element={<ProjectTemplateContactViewPage />} />
            <Route path="contacts" element={<ProjectTemplateContactListPage />} />
            <Route path="edit" element={<ProjectTemplateUpdatePage />} />
            <Route path="/" element={<ProjectTemplateViewPage />} />
          </Route>
          <Route path="/tools/templates/:id/edit" element={<FormTemplateUpdatePage />} />
          <Route path="/tools/templates/new" element={<FormTemplateCreatePage />} />
          <Route path="/tools/templates/:id" element={<FormTemplateViewPage />} />
          <Route path="/tools/templates" element={<FormTemplatesPage />} />
          <Route path="/tools/permissions/:id/edit" element={<PermissionSetUpdatePage />} />
          <Route path="/tools/permissions/new" element={<PermissionSetCreatePage />} />
          <Route path="/tools/permissions/:id" element={<PermissionSetViewPage />} />
          <Route path="/tools/permissions" element={<PermissionSetListPage />} />
          <Route path="/tools/stages/:id/edit" element={<ProjectStageUpdatePage />} />
          <Route path="/tools/stages/new" element={<ProjectStageCreatePage />} />
          <Route path="/tools/stages/:id" element={<ProjectStageViewPage />} />
          <Route path="/tools/stages" element={<ProjectStageListPage />} />
          <Route path="/tools/codes/:id/edit" element={<CostCodeUpdatePage />} />
          <Route path="/tools/codes/new" element={<CostCodeCreatePage />} />
          <Route path="/tools/codes/:id" element={<CostCodeViewPage />} />
          <Route path="/tools/codes" element={<CostCodeListPage />} />
          <Route path="/tools/units/:id/edit" element={<UnitOfMeasureUpdatePage />} />
          <Route path="/tools/units/new" element={<UnitOfMeasureCreatePage />} />
          <Route path="/tools/units/:id" element={<UnitOfMeasureViewPage />} />
          <Route path="/tools/units" element={<UnitOfMeasureListPage />} />
          <Route path="/tools/directories/:id/edit" element={<TagUpdatePage />} />
          <Route path="/tools/directories/new" element={<TagCreatePage />} />
          <Route path="/tools/directories/:id" element={<TagViewPage />} />
          <Route path="/tools/directories" element={<TagListPage />} />
          <Route path="/tools/titles/:id/edit" element={<TitleUpdatePage />} />
          <Route path="/tools/titles/new" element={<TitleCreatePage />} />
          <Route path="/tools/titles/:id" element={<TitleViewPage />} />
          <Route path="/tools/titles" element={<TitleListPage />} />
          <Route path="/tools/types/:id/edit" element={<ProjectTypeUpdatePage />} />
          <Route path="/tools/types/new" element={<ProjectTypeCreatePage />} />
          <Route path="/tools/types/:id" element={<ProjectTypeViewPage />} />
          <Route path="/tools/types" element={<ProjectTypeListPage />} />
          <Route path="/tools" element={<Navigate to="/tools/stages" />} />
          <Route path="/users/invitation/accept" element={<AutoLogout />} />
          <Route path="/users/password/edit" element={<AutoLogout />} />
          <Route path="/contacts" element={<ContactsPage />} />
          <Route path="*" element={<Navigate to="/projects" replace />} />
        </Routes>
      </Layout>
    </AppInfoProvider>
  ) : (
    <PublicLayout>
      <Routes>
        <Route path="/users/invitation/accept" element={<InvitationConfirmationPage />} />
        <Route path="/password/forgot" element={<PasswordForgotPage />} />
        <Route path="/users/password/edit" element={<PasswordUpdatePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<PublicRedirect />} />
      </Routes>
    </PublicLayout>
  )
}

const App = () => (
  <Router>
    <Helmet titleTemplate="%s | Darana Hybrid" defaultTitle="Darana Hybrid">
      <meta name="description" content="Darana Hybrid Project Management Application" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Helmet>
    <ErrorBoundary text="There was a problem please refresh the browser">
      <ScreenWidthProvider>
        <ToastProvider>
          <LoaderProvider>
            <AuthProvider>
              <ErrorModalProvider>
                <Pages />
              </ErrorModalProvider>
            </AuthProvider>
          </LoaderProvider>
        </ToastProvider>
      </ScreenWidthProvider>
    </ErrorBoundary>
  </Router>
)

export default App;
