import {string, object, mixed} from 'yup';

export const projectMeetingSchema = object().shape({
  meeting_num: string().required('Meeting # is required'),
  name: string().required('Name is required'),
  meeting_date: string().required('Date is required'),
  time_zone: mixed().required('Time zone is required'),
  start_time: mixed().required('Start time is required'),
  end_time: mixed().required('End time is required'),
  video_conference_link: string().matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            'Video link is not a valid URL'
        )

});
