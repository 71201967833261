import {Link} from 'react-router-dom';
import Page from '@components/Layouts/Page';
import Button from '@components/Button';
import CostCodeList from '@components/Tool/CostCode/List';
import Request from '@containers/Request';
import withPageTitle from '@containers/withPageTitle';

export const CostCodeListPageComponent = ({url,...props}) => (
  <Page
    {...props}
    right={<Button as={Link} to="new">Add Cost Code</Button>}
  >
    <Request url={url}>
      {({cost_codes}) => <CostCodeList costCodes={cost_codes} />}
    </Request>
  </Page>
)

const CostCodeListPage = () => (
  <CostCodeListPageComponent
    url="/cost_codes.json"
    title="Cost Codes"
  />
)

export default withPageTitle(CostCodeListPage, "Cost Codes");
