import {string, object,array} from 'yup';

const permissionFieldSchema = object().shape({

})

export const permissionSetSchema = object().shape({
  name: string().required('Name is required'),
  permissions_attributes: array().of(permissionFieldSchema).required('Requires at least one resource policy')
})
