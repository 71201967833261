import Page from '@components/Layouts/CommonPage';
import Form from '@components/Form';

const FormPage = ({form,children,...props}) => (
  <Form {...form}>
    <Page {...props}>
      {children}
    </Page>
  </Form>
)

export default FormPage;
