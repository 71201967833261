import {Table,Header} from '@components/Table';
import FileComponent from '@components/ProjectAsset/Directory/File/Item';
import Slideshow from '@components/ProjectAsset/Slideshow';

const ProjectAssetList = ({projectAssets,projectId,makeRequest,updateData,tagId,isDefault}) => (<>
  <Table>
    <Header>
      <div>File</div>
    </Header>
  </Table>
  <Slideshow projectAssets={projectAssets} updateData={updateData}>
    {openSlideshow => projectAssets?.length > 0 && projectAssets.map((file,idx) => (
      <FileComponent onDelete={makeRequest} projectId={projectId} key={file.id} file={file} tagId={tagId} isDefault={isDefault} onClick={() => {openSlideshow(idx)}} />
    ))}
  </Slideshow>
</>)

export default ProjectAssetList;
