import {createContext,useContext,useCallback,useState} from 'react';
import useEventListener from '@containers/useEventListener';

const ScreenWidthStateContext = createContext();

export function ScreenWidthProvider({children}) {
  const [width,setWidth] = useState(window.innerWidth)
  const handleSizeChange = useCallback(() => {
    setWidth(window.innerWidth)
  }, [setWidth])
  useEventListener("resize", handleSizeChange);
  return (
    <ScreenWidthStateContext.Provider value={width}>
      {children}
    </ScreenWidthStateContext.Provider>
  );
};

export function useScreenWidthState() {
  const context = useContext(ScreenWidthStateContext);
  if (context === undefined) {
    throw new Error('useScreenWidthState must be used within a ScreenWidthProvider');
  }
  return context;
}


export function withIsMobile(WrappedComponent,width = 1024){
  return (props) => {
    const screenWidth = useScreenWidthState();
    return (
      <WrappedComponent {...props} isMobile={screenWidth < width} />
    )
  }
}
