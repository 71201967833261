import {Controller} from 'react-hook-form';
import withField from '@components/Form/Fields/withField';
import {Switch} from '@components/Form/Fields/Switch';

const Input = withField(Controller);
const ControlledSwitch = (props) => <Controller {...props}  as={Switch} />
const SwitchComponent = withField(ControlledSwitch)

const ProjectFormValueFieldInput = (field,name) => {
  switch (field.type) {
    case "DateField":
      return <Input required={field.required} min={field.min} max={field.max} type="date" defaultValue={field.value} name={name} label={field.label} as="input" />
    case "IntegerField":
      return <Input required={field.required} min={field.min} max={field.max} type="number" defaultValue={field.value} name={name} label={field.label} as="input" />
    case "BooleanField":
      return <SwitchComponent required={field.required} name={name} defaultValue={field.value} label={field.label} />
    default:
      return <Input required={field.required} name={name} defaultValue={field.value} label={field.label} as="input" />
  }
}

const ProjectFormValueField = ({root,field}) => (<>
  <Controller as="input" name={`${root}form_field_id`} defaultValue={field.form_field_id} className="hidden" />
  {field.id && <Controller as="input" name={`${root}id`} defaultValue={field.id} className="hidden" />}
  {ProjectFormValueFieldInput(field,`${root}value`)}
</>)

export default ProjectFormValueField;
