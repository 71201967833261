import {useFormContext,Controller} from 'react-hook-form';
import withField from '@components/Form/Fields/withField';
import styles from './index.module.css';
import {classNames} from '@utils';
import dropdown from '@assets/images/dropdown.svg'


export const SelectComponent = ({options, placeholder, register,className,...props}) => (
  <select {...props} className={classNames(className, props.disabled && styles.disabled)} ref={register}>
    {placeholder && <option value="" disabled={props.unclearable} selected>{placeholder}</option>}
    {(options && options.length > 0) ? (
      options.map((option,i) =>
        <option key={option.value || i} value={option.value}>{option.label}</option>
      )
    ) : null}
  </select>
)


export const StatefulSelectComponent = ({isSearchable = false,...props}) => (
  <Controller {...props} as={SelectComponent} isSearchable={isSearchable} />
)

const SelectContainer = withField(StatefulSelectComponent)

export default SelectContainer;
