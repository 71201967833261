import {useWatch} from 'react-hook-form';
import Permission from '@components/Tool/PermissionSet/Permission';
import Form from '@components/Form';
import Modal from '@components/Modal';
import Button from '@components/Button';
import Request from '@containers/Request';
import Input from '@components/Form/Fields/Input';
import RequestSelect from '@containers/RequestSelect';
import {permissionSchema} from '@utils/validation/user';
import {updateUser} from '@utils/api/user';
import styles from './index.module.css';

const Permissions = () => {
  const permission_set_id = useWatch({name: "permission_set_id"})
  return <Permission id={permission_set_id} />
}
const PermissionModalForm = ({id,close}) => {
  const url = `/users/${id}.json`;
  return(
    <Modal close={close} className={styles.container} text="Update User Permission Set">
      <Request url={url}>
        {({permissions}) => (
          <Form
            url={url}
            defaultValues={{permission_set_id: permissions.id}}
            onSubmit={updateUser}
            toast="Permissions updated"
            validationSchema={permissionSchema}
            to={false}
            onSuccess={close}
            className={styles.form}
          >
            <RequestSelect name="permission_set_id" label="Permission Set" url="/permission_sets.json" placeholder="Select Permission Set..." />
            <Permissions />
            <Button type="submit" className={styles.button}>Save</Button>
          </Form>
        )}
      </Request>
    </Modal>

  );
}

export default PermissionModalForm;
