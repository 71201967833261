import {ProjectAssetUpdatePage} from '@components/ProjectAsset/Update';
import {withRequestState} from '@containers/RequestContext';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';

const ProjectDefaultAssetUpdatePage = withParams(withRequestState(({request:{state:{data}},params}) => (
  <ProjectAssetUpdatePage {...params} projectId={data.id} companyId={data.company_id} />
)))

export default withPageTitle(ProjectDefaultAssetUpdatePage, "Update Project Default File");
