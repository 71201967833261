import {Link,useLocation} from 'react-router-dom';
import Button from '@components/Button';
import Dropdown from '@components/Dropdown';
import {withIsMobile} from '@containers/ScreenWidth';
import styles from './index.module.css';
import {classNames} from '@utils';
import settingsIcon from '@assets/images/settings-24px.svg';
import backIcon from '@assets/images/arrow_back_ios-24px.svg';

const MobileLinks = ({children}) => (
  <Dropdown
    className={styles.dropdown}
    button={<img alt="Menu" src={settingsIcon} className={classNames(styles.settingsIcon,"selectable")}  />}
  >
    {children}
  </Dropdown>
)

export const BackIcon = ({className,...props}) => (
  <img alt="Back" src={backIcon} {...props} className={classNames(styles.back,className)} />
)
const makeBackLink = (back,backLink) => {
  if(backLink) return backLink
  return (typeof back === "object" && back.to) ? back.to : ".."
}
const BackButton = ({isMobile,back}) => {
  const {state} = useLocation(),
        backState = state?.back,
        to = makeBackLink(back,backState?.to);
  return isMobile ? (
    <Link to={to}><BackIcon /></Link>
  ) : (
    <Button as={Link} to={to}>{backState?.text ? backState.text : (typeof back === "object" && back.text) ? back.text : (back || "Back")}</Button>
  )
}

const Header = withIsMobile(({isMobile,back,right,title,submit,submitText = "Save"}) => (
  <div className={styles.header}>
    <div className={styles.left}>
      {back && <BackButton back={back} isMobile={isMobile} />}
    </div>
    <div className={styles.title}>{title}</div>
    {isMobile ? (<>
      {submit && (
        <Button type="submit" className={styles.mobileSubmitButton}>{submitText}</Button>
      )}
      {right ? <MobileLinks>{right}</MobileLinks> : <div></div>}
    </>) : (
      <div className={styles.right}>
        {submit && <Button type="submit">{submitText}</Button>}
        {right}
      </div>
    )}
  </div>
), 1024);

const Page = ({children,back,right,title,className, submit}) => (
  <div className={styles.container}>
    <Header back={back} right={right} title={title} submit={submit} />
    <div className={classNames(styles.content, className)}>
      {children}
    </div>
  </div>
)

export default Page;
