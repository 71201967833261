import FormTemplateFormPage from '@components/FormTemplate/Form';
import Request from '@containers/Request';
import DeleteButton from '@containers/DeleteButton';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import {updateFormTemplate} from '@utils/api/form_template';

const FormTemplateUpdatePage = withParams(({params:{id}}) => {
  const url = `/form_templates/${id}.json`;
  return(
    <Request url={url}>
      {({form_fields,...response}) => (
        <FormTemplateFormPage
          form={{
            url,
            defaultValues: {field_type: "TextField",form_fields_attributes: form_fields,...response},
            onSubmit: updateFormTemplate
          }}
          page={{
            title: "Update Form Template",
            back: "Back To Form Template",
            submit: "Update Form Template",
            right: <DeleteButton url={url} />
          }}
        />
      )}
    </Request>
  );
})

export default withPageTitle(FormTemplateUpdatePage, "Update Form Template");
